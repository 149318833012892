import React, { useState, useEffect } from "react";
import { Form, Steps, Button, Row, Card, message } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import FirstStep from "./FirstStep";
import JointVenture from "./JointVenture";
import CustomerInfo from "./CustomerInfo";
import Header from "./Header";
import { useHistory } from "react-router-dom";
import { saveForm, getProjectDetailsById, updateForm } from "./AddProjectSlice";

const { Step } = Steps;

function AddProject(props) {
  const history = useHistory();
  const [displayAddProject, setDisplayStatus] = useState(
    !props.isInEditMode ?? true
  );

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [formData, setFormState] = useState({});
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const selectedRecord = useSelector((state) => state.projectDetails.record);

  // console.log("Project Data", Number(selectedRecord.project.projectId));

  const [projectValues, updateValues] = useState({
    project: {
      projectId: null,
      projectName: "",
      shortTitle: "",
      departmentID: null,
      isTender: false,
      tenderID: null,
      projectValue: null,
      budget: null,
      projectTypeID: null,
      isJointVenture: false,
      createdDateTime: null,
      statusID: 1,
    },
    customer: {
      customerID: null,
      customerName: "",
      contactNumber: "",
      alternativeNumber: "",
      email: "",
      address: "",
      state: "",
      zip: "",
    },
    company: {
      jCompanyID: null,
      jCompanyName: "",
      jProjectId: null,
      jContactNumber: "",
      jAlternativeNumber: "",
      jEmail: "",
      jAddress: "",
      jState: "",
      jZip: "",
    },
  });

  useEffect(() => {
    dispatch(getProjectDetailsById(props.id));
  }, [dispatch]);

  useEffect(() => {
    if (
      Object.keys(selectedRecord).length > 0 &&
      selectedRecord.project.projectId === props.id
    ) {
      let customerDetails =
        selectedRecord.customer === null
          ? projectValues.customer
          : selectedRecord.customer;
      let companyDetails =
        selectedRecord.company === null
          ? projectValues.company
          : selectedRecord.company;

      setTenderFiles(selectedRecord.project.tenderFiles);

      updateValues((projectValues) => ({
        ...projectValues,
        project: selectedRecord.project,
        customer: customerDetails,
        company: companyDetails,
      }));

      updateJointVenture(selectedRecord.project.isJointVenture);
      updateTenderState(selectedRecord.project.isTender);
      setDisplayStatus(true);
    }
  }, [selectedRecord]);

  useEffect(function () {
    if (shouldSubmit) {
      if (!props.isInEditMode) {
        dispatch(saveForm(formData))
          .then((res) => {
            message.success("Successfully Added");
            form.resetFields();
            history.push("viewprojects");
          })
          .catch(() => {
            message.error("Error while Saving");
          });
      } else {
        dispatch(updateForm(formData))
          .then((res) => {
            message.success("Successfully Updated Successfully");
            form.resetFields();
            // history.push("viewprojects");
            window.location.reload(false);
          })
          .catch(() => {
            message.error("Error while Saving");
          });
      }
      setShouldSubmit(false);
    }
  });

  const onSubmit = (values) => {
    let jsonKey = currentStepState === 1 ? "customer" : "company";
    // if (currentStepState === 1 && props.isInEditMode) {
    //   values.customerID = projectValues.customer.customerID;
    // }
    setFormState((formData) => ({
      ...formData,
      [jsonKey]: values,
      tenderfiles: tenderfiles,
    }));

    setShouldSubmit(true);
  };

  const [hasJointVenture, updateJointVenture] = useState(false);
  const handleUpdate = (e) => {
    updateJointVenture(e.target.value);
  };

  const [tenderState, updateTenderState] = useState(false);
  const [tenderfiles, setTenderFiles] = useState([]);
  const handleTenderUpdate = (e) => {
    updateTenderState(e.target.value);
  };

  const [currentStepState, setStepState] = useState(0);
  const next = async () => {
    try {
      const values = await form.validateFields();
      let jsonKey = currentStepState === 0 ? "project" : "customer";

      if (currentStepState === 0) {
        if (props.isInEditMode) {
          values.projectId = projectValues.project.projectId;
        }
        values.departmentID = Number(values.departmentID);
        values.projectTypeID = Number(values.projectTypeID);
        values.tenderID = Number(values.tenderID);
        values.projectValue = Number(values.projectValue);
        values.budget = Number(values.budget);
      } else if (currentStepState === 1 && props.isInEditMode) {
        values.customerID = projectValues.customer.customerID;
      }
      console.log(values);
      setFormState((formData) => ({
        ...formData,
        [jsonKey]: values,
      }));
      const updatedState = currentStepState + 1;
      setStepState(updatedState);
    } catch (errorInfo) {
      message.error("Fill in mandatory fields");
    }
  };

  const prev = (e) => {
    const updatedState = currentStepState - 1;
    setStepState(updatedState);
  };

  let steps = [
    {
      title: "First Step",
      description: "Project Details",
      icon: <UserOutlined />,
      content: (
        <FirstStep
          jointVentureState={hasJointVenture}
          tenderState={tenderState}
          maintainTenderState={handleTenderUpdate}
          displayJV={handleUpdate}
          details={projectValues.project}
          tenderfiles={tenderfiles}
          setTenderFiles={setTenderFiles}
        />
      ),
    },
    {
      title: "Customer Info",
      description: "Customer Details",
      icon: <UserOutlined />,
      content: <CustomerInfo form={form} />,
    },
  ];
  if (hasJointVenture) {
    steps.push({
      title: "Joint Venture",
      description: "Joint Venture Details",
      icon: <UserOutlined />,
      content: <JointVenture />,
    });
  }
  console.log("Selected Record:", selectedRecord);
  if (displayAddProject) {
    return (
      <>
        <Header />
        <div>
          <Form
            layout="vertical"
            onFinish={onSubmit}
            form={form}
            initialValues={{
              projectName: projectValues.project.projectName,
              shortTitle: projectValues.project.shortTitle,
              createdDateTime: projectValues.project.createdDateTime,
              departmentID: projectValues.project.departmentID,
              projectTypeID: projectValues.project.projectTypeID,
              projectValue: projectValues.project.projectValue,
              budget: projectValues.project.budget,
              isJointVenture: projectValues.project.isJointVenture,
              isTender: projectValues.project.isTender,
              tenderID: projectValues.project.tenderID,
              customerID: projectValues.customer.customerID,
              customerName: projectValues.customer.customerName,
              contactNumber: projectValues.customer.contactNumber,
              alternativeNumber: projectValues.customer.alternativeNumber,
              email: projectValues.customer.email,
              address: projectValues.customer.address,
              state: projectValues.customer.state,
              zip: projectValues.customer.zip,
              jCompanyID: projectValues.company.jCompanyID,
              jCompanyName: projectValues.company.jCompanyName,
              jProjectId: projectValues.company.jProjectId,
              jContactNumber: projectValues.company.jContactNumber,
              jAlternativeNumber: projectValues.company.jAlternativeNumber,
              jEmail: projectValues.company.jEmail,
              jAddress: projectValues.company.jAddress,
              jState: projectValues.company.jState,
              jZip: projectValues.company.jZip,
              remember: true,
              statusID: projectValues.project.statusID,
            }}
          >
            <div className="site-card-border-less-wrapper">
              <Card
                title={
                  <Steps current={currentStepState}>
                    {steps.map((item) => (
                      <Step
                        key={item.title}
                        title={item.title}
                        description={item.description}
                        icon={item.icon}
                      />
                    ))}
                  </Steps>
                }
                bordered={true}
                style={{ width: "100%" }}
              >
                <div className="steps-content">
                  {steps[currentStepState].content}
                </div>
              </Card>
            </div>
            <div className="steps-action">
              <Card style={{ width: "100%" }} bordered={false}>
                <Row justify="end">
                  {currentStepState > 0 && (
                    <Button style={{ margin: "0 8px" }} onClick={prev}>
                      Previous
                    </Button>
                  )}
                  {currentStepState < steps.length - 1 && (
                    <Button type="primary" onClick={next}>
                      Next
                    </Button>
                  )}
                  {currentStepState === steps.length - 1 && (
                    <Button type="primary" htmlType="submit" onClick={() => {}}>
                      Done
                    </Button>
                  )}
                </Row>
              </Card>
            </div>
          </Form>
        </div>
      </>
    );
  } else {
    return <h1>Fetching In Progress</h1>;
  }
}

export default AddProject;
