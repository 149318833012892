import { createSlice } from "@reduxjs/toolkit";
import * as Settings from "../../../settings/Settings";
import axios from "axios";
import { message } from "antd";

const baseUrl = Settings.ApiEndPoint;

export const AddProjectSlice = createSlice({
  name: "Add Project",
  initialState: {
    departmentRecords: [],
    projectTypeRecords: [],
    projectStatusList: [],
    record: {},
  },
  reducers: {
    getAllDepartmentRecords: (state, action) => {
      state.departmentRecords = [];
      action.payload.forEach((item) => {
        state.departmentRecords.push(item);
      });
    },

    getProjectTypeRecords: (state, action) => {
      state.projectTypeRecords = [];
      action.payload.forEach((item) => {
        state.projectTypeRecords.push(item);
      });
    },

    getProjectStatusList: (state, action) => {
      state.projectStatusList = [];
      action.payload.forEach((item) => {
        state.projectStatusList.push(item);
      });
    },

    getProjectById: (state, action) => {
      state.record = action.payload;
    },
  },
});

export const saveForm = (data) => {
  const values = {
    ...data,
  };

  delete values.tenderfiles;

  return (dispatch) => {
    return axios
      .post(`${baseUrl}api/Project/SaveProject`, values)
      .then((res) => {
        return UploadFiles(data, res);
      });
  };
};

const UploadFiles = (data, res) => {
  const formData = new FormData();
  data.tenderfiles.map((v) => {
    formData.append("files", v);
  });

  if (data.tenderfiles.length > 0) {
    var fileDestination = GetTenderFilePath(res.data);
    // var fileDestination = `Project-${res.data}-TenderAttachments`;
    axios
      .post(
        `${baseUrl}api/Attachment/AddAtachment?fileDesination=${fileDestination}`,
        formData
      )
      .then((res) => {
        message.success("Documents uploaded succesfully", [1.5]);
        return true;
      })
      .catch((Error) => {
        message.error("Something went wrong while downloading", [1.5]);
        return false;
      });
  }
};

export const updateForm = (data) => {
  const values = {
    ...data,
  };

  delete values.tenderfiles;
  return (dispatch) => {
    return axios
      .post(`${baseUrl}api/Project/UpdateProject`, values)
      .then((res) => {
        return UploadFiles(data, res);
      });
  };
};

export const { getAllDepartmentRecords } = AddProjectSlice.actions;

export const getAll = () => (dispatch) => {
  return axios.get(`${baseUrl}api/LookUp/GetDepartments`).then((res) => {
    dispatch(getAllDepartmentRecords(res.data));
  });
};

export const { getProjectTypeRecords } = AddProjectSlice.actions;

export const getProjectTypes = () => (dispatch) => {
  axios.get(`${baseUrl}api/LookUp/GetProjectTypes`).then((res) => {
    dispatch(getProjectTypeRecords(res.data));
  });
};

export const { getProjectStatusList } = AddProjectSlice.actions;

export const getProjectStatus = () => (dispatch) => {
  axios.get(`${baseUrl}api/LookUp/GetStatuses`).then((res) => {
    dispatch(getProjectStatusList(res.data));
  });
};

export const { getProjectById } = AddProjectSlice.actions;

export const getProjectDetailsById = (projectId) => (dispatch) => {
  axios.get(`${baseUrl}api/Project/GetProjectById/${projectId}`).then((res) => {
    dispatch(getProjectById(res.data));
    console.log(res);
  });
};

export const GetAllDepartments = () => (dispatch) => {
  dispatch(getAll());
};

export const DeleteFile = (path, filename) => (dispatch) => {
  return axios
    .get(
      `${baseUrl}api/Attachment/DeleteFile?absolutePath=${path}&fileName=${filename}`
    )
    .then((res) => {
      if (res.data) {
        message.success("File deleted ..!", [1.0]);
        return true;
      } else {
        message.error("File Deletion Failed..!");
        return false;
      }
    });
};
export const DownloadFile = (path, filename) => (dispatch) => {
  return axios
    .get(
      `${baseUrl}api/Attachment/DownloadFile?absolutePath=${path}&fileName=${filename}`
    )
    .then((res) => {
      var the_file = new Blob([window.atob(res.data.fileContent)], {
        type: "image/png",
        encoding: "utf-8",
      });
      var fr = new FileReader();
      fr.onload = function (oFREvent) {
        var v = oFREvent.target.result.split(",")[1]; // encoding is messed up here, so we fix it
        v = atob(v);
        var good_b64 = btoa(decodeURIComponent(escape(v)));
        good_b64 = `${
          ext[res.data.fileExtension && res.data.fileExtension.toLowerCase()]
        }${good_b64}`;
        const link = document.createElement("a");
        link.href = good_b64;
        link.download = res.data.fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return good_b64;
      };
      fr.readAsDataURL(the_file);
      message.success("File Downloaded..!", [1.0]);
    });
};

export const GetTenderFilePath = (id) => {
  return `Project-${id}-TenderAttachments`;
};
export default AddProjectSlice.reducer;
const ext = {
  ".png": "data:image/png;base64,",
  ".jpeg": "data:image/jpeg;base64,",
  ".jpg": "data:image/jpeg;base64,",
  ".pdf": "data:application/pdf;base64,",
  ".xls": "data:application/vnd.ms-excel;base64,",
  ".doc": "data:application/msword;base64,",
  ".docx": "data:application/msword;base64,",
  ".xlsx":
    "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
  ".ppt": "data:application/vnd.ms-powerpoint;base64,",
  ".pptx":
    "data:application/vnd.openxmlformats-officedocument.presentationml.presentation;base64,",
  ".txt": "data:text/plain;base64,",
  ".msg": "data:application/vnd.ms-outlook;base64,",
  ".svg": "data:image/svg+xml;base64,",
  ".zip": "data:application/zip;base64,",
  ".url": "data:application/internet-shortcut;base64,",
};
