import React from "react";
import { Radio } from "antd";

function RadioButton(props) {
  return (
    <div>
      <Radio.Group
        onChange={props.handleChange}
        value={props.initialState}
        {...props}
      >
        <Radio name={1} value={true}>
          Yes
        </Radio>
        <Radio name={2} value={false}>
          No
        </Radio>
      </Radio.Group>
    </div>
  );
}

export default RadioButton;
