import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getApprovedExpenseAsync,
  AddPaymentReference,
  AddVendorInvoice,
} from "./ViewExpensesSlice";
import { getExpenseByIdAsync } from "../Approvals/ApprovalSlice";
import ExpenseView from "../Components/ExpenseView";
import {
  CheckOutlined,
  CloseOutlined,
  EyeTwoTone,
  UploadOutlined,
  PlusOutlined,
  PlusSquareTwoTone,
  PlusSquareFilled,
  EditOutlined,
} from "@ant-design/icons";
import Moment from "react-moment";
import {
  Table,
  Row,
  Input,
  Col,
  Breadcrumb,
  Button,
  Form,
  Radio,
  Card,
  Modal,
  Typography,
  Upload,
  Space,
} from "antd";
import { useHistory } from "react-router-dom";
const { Title } = Typography;

// const { Column } = Table;
export function ViewExpenses() {
  const [visible, setVisible] = useState(false);
  const [visibleInvoiceModal, setVisibleInvoiceModal] = useState(false);
  const [expenseId, setExpenseId] = useState(null);
  const [files, setFiles] = useState([]);
  const [form] = Form.useForm();
  const [page, setPage] = React.useState(1);
  const dispatch = useDispatch();
  const history = useHistory();
  const approvedExpenseData = useSelector(
    (state) => state.viewExpenses.ApprovedExpenseRecords
  );
  const editExpense = (e) => {
    history.push("addexpenses/" + e);
  };
  useEffect(() => {
    dispatch(getApprovedExpenseAsync());
  }, [dispatch]);
  console.log("Approved expenses", approvedExpenseData);
  const ModalTogger = () => {
    setVisible(false);
    setVisibleInvoiceModal(false);
    form.resetFields();
    setExpenseId(null);
    setFiles([]);
  };
  const onSubmitHandle = async () => {
    form
      .validateFields()
      .then((values) => {
        if (files.length === 0) {
          alert("Please upload attachment");
        } else {
          values.Attachment = files;
          dispatch(AddPaymentReference(expenseId, values)).then(() => {
            ModalTogger();
          });
        }
      })
      .catch((errInfo) => {
        console.log("errInfo", errInfo);
      });
  };
  const onVendorInoiveSubmitHandle = async () => {
    form
      .validateFields()
      .then((values) => {
        if (files.length === 0) {
          alert("Please upload attachment");
        } else {
          values.Attachment = files;
          dispatch(AddVendorInvoice(expenseId, values)).then(() => {
            ModalTogger();
          });
        }
      })
      .catch((errInfo) => {
        console.log("errInfo", errInfo);
      });
  };
  const columns = [
    {
      title: "S.No",
      dataIndex: "id",
      // render: (value, item, index) => index + 1,
      render: (value, item, index) => (page - 1) * 10 + index + 1,
      // sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Date",
      render: (approvedExpenseData) => {
        return (
          <>
            <p>
              {approvedExpenseData.createdDateTime === null ? (
                "null"
              ) : (
                <>
                  <Moment utc local format="DD-MMM-YYYY">
                    {approvedExpenseData.createdDateTime}
                  </Moment>
                  &nbsp;
                  <Moment
                    utc
                    local
                    fromNow
                    ago
                    style={{ border: "2px solid pink", Color: "blue" }}
                  >
                    {approvedExpenseData.createdDateTime}
                  </Moment>
                </>
              )}
            </p>
          </>
        );
      },
      //sorter: (a, b) => a.createdDateTime - b.createdDateTime,
    },

    {
      title: "Project",
      // dataIndex: "amount",
      render: (approvedExpenseData) => {
        return (
          <>
            <p>{approvedExpenseData.project?.projectName}</p>
          </>
        );
      },
      sorter: (a, b) =>
        a.project.projectName.length - b.project.projectName.length,
    },
    {
      title: "PO Number",
      // dataIndex: "amount",
      render: (approvedExpenseData) => {
        return (
          <>
            <p>{approvedExpenseData.purchaseOrder?.poNumber}</p>
          </>
        );
      },
      // sorter: (a, b) =>
      //   a.purchaseOrder.poNumber.slice(-4, -1) -
      //   b.purchaseOrder.poNumber.slice(-4, -1),
    },
    {
      title: "Vendor Name",
      // dataIndex: "amount",
      render: (approvedExpenseData) => {
        return (
          <>
            <p>{approvedExpenseData.vendors?.vendorName}</p>
          </>
        );
      },
      //sorter: (a, b) => a.vendors.vendorName - b.vendors.vendorName,
    },
    {
      title: "Approved/Rejected Date",
      render: (approvedExpenseData) => {
        return (
          <>
            {approvedExpenseData.approvalStatusID === 2 ? (
              <Moment utc local format="DD-MMM-YYYY">
                {approvedExpenseData?.rejectedDate}
              </Moment>
            ) : approvedExpenseData.approvalStatusID === 1 ? (
              <Moment utc local format="DD-MMM-YYYY">
                {approvedExpenseData?.adminApprovedDate}
              </Moment>
            ) : (
              ""
            )}
            {/* {approvedExpenseData.adminApprovedDate == null ? (
              ""
            ) : (
              <Moment utc local format="DD-MMM-YYYY">
                {approvedExpenseData.adminApprovedDate}
              </Moment>
            )} */}
          </>
        );
      },
    },
    {
      title: "Status",
      filters: ApprovalStatus && ApprovalStatus,
      onFilter: (value, record) => record.projectType.indexOf(value) === 0,
      // dataIndex: "amount",
      // render: (approvedExpenseData) => {
      //   return (
      //     <>
      //       <p>{approvedExpenseData.approvalStatus.approvalstatus}</p>
      //     </>
      //   );
      // },
      render: (approvedExpenseData) => {
        return (
          <>
            {approvedExpenseData.approvalStatusID === 1 ? (
              <p style={{ color: "green" }}>Approved</p>
            ) : approvedExpenseData.approvalStatusID === 2 ? (
              <p style={{ color: "red" }}>Rejected</p>
            ) : approvedExpenseData.approvalStatusID === 0 ? (
              <p style={{ color: "blue" }}>Waiting for Admin Approval</p>
            ) : (
              <p style={{ color: "orange" }}>Waiting for Approval</p>
            )}{" "}
          </>
        );
      },
      //sorter: (a, b) => a.vendors.vendorName - b.vendors.vendorName,
    },
    // {
    //   title: "GST",
    //   dataIndex: "gstno",

    //   sorter: (a, b) => a.gstno - b.gstno,
    // },
    {
      title: "Amount",
      // dataIndex: "amount",
      render: (approvedExpenseData) => {
        return (
          <>
            <p>{approvedExpenseData.amount}</p>
          </>
        );
      },
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: "Total Amount",
      // dataIndex: "amount",
      render: (approvedExpenseData) => {
        return (
          <>
            <p>
              {approvedExpenseData.totalAmount == null
                ? approvedExpenseData.amount +
                  (approvedExpenseData.amount * approvedExpenseData.gst) / 100
                : approvedExpenseData.totalAmount}
            </p>
          </>
        );
      },
      sorter: (a, b) => a.totalAmount - b.totalAmount,
    },
    {
      title: "Payment Reference",
      align: "center",
      render: (approvedExpenseData) => {
        return (
          <>
            {approvedExpenseData.paymentStatus === 1 ? (
              <span>Payment Done</span>
            ) : (
              <a
                size="small"
                style={{ color: "blue" }}
                onClick={() => {
                  setExpenseId(approvedExpenseData.id);
                  setVisible(true);
                }}
              >
                <PlusSquareFilled style={{ fontSize: "24px", color: "#08c" }} />
              </a>
            )}
          </>
        );
      },
    },
    {
      title: "Vedor Invoice",
      align: "center",
      render: (approvedExpenseData) => {
        return (
          <>
            {approvedExpenseData.isVendorInvoiceAvailable === true ? (
              <span>Invoice Available</span>
            ) : (
              <a
                size="small"
                style={{ color: "blue" }}
                onClick={() => {
                  setExpenseId(approvedExpenseData.id);
                  setVisibleInvoiceModal(true);
                }}
              >
                <PlusSquareFilled style={{ fontSize: "24px", color: "#08c" }} />
              </a>
            )}
          </>
        );
      },
    },
    {
      title: "CreatedBy",
      render: (approvedExpenseData) => {
        return (
          <>
            <p>{approvedExpenseData.createdUser?.firstName}</p>
          </>
        );
      },
    },
    {
      title: "Created Date",
      render: (approvedExpenseData) => {
        return (
          <>
            {approvedExpenseData.createdDateTime == null ? (
              ""
            ) : (
              <Moment utc local format="DD-MMM-YYYY">
                {approvedExpenseData.createdDateTime}
              </Moment>
            )}
          </>
        );
      },
    },
    {
      title: "UpdatedBy",
      render: (approvedExpenseData) => {
        return (
          <>
            <p>{approvedExpenseData.updatedUser?.firstName}</p>
          </>
        );
      },
    },
    {
      title: "Updated Date",
      render: (approvedExpenseData) => {
        return (
          <>
            {approvedExpenseData.updatedDateTime == null ? (
              ""
            ) : (
              <Moment utc local format="DD-MMM-YYYY">
                {approvedExpenseData.updatedDateTime}
              </Moment>
            )}
          </>
        );
      },
    },
    {
      title: "Action",
      render: (approvedExpenseData, record) => {
        return (
          <>
            {approvedExpenseData.approvalStatusID === 3 && (
              <Space size="small">
                <Button
                  icon={<EditOutlined />}
                  size="small"
                  onClick={() => editExpense(record.id)}
                ></Button>
              </Space>
            )}
          </>
        );
      },
    },
    // {
    //   title: "Actions",
    //   render: (approvedExpenseData) => {
    //     return (
    //       <Button
    //         size="small"
    //         onClick={() =>
    //           dispatch(getExpenseByIdAsync(approvedExpenseData.id))
    //         }
    //       >
    //         <EyeTwoTone />
    //       </Button>
    //     );
    //   },
    // },
  ];

  const handleDelete = (file) => {
    var index = files.findIndex((a) => a.uid === file.uid);
    files.splice(index, 1);
    setFiles(files);
  };

  return (
    <>
      <Row>
        <Title level={4}>View Expenses</Title>
      </Row>

      <Row>
        <Col span={20}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="">Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="#">Expenses</Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item>View Expenses</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <div span={24} style={{ marginTop: 20, width: "100%" }}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={24}>
            <Table
              bordered
              columns={columns}
              dataSource={approvedExpenseData}
              size="small"
              pagination={{
                onChange(current) {
                  setPage(current);
                },
              }}
              expandable={{
                expandedRowRender: (record) => <ExpenseView id={record.id} />,
                rowExpandable: (records) => records.info !== "Not expandable",
                onExpand: (expanded, record) => {
                  dispatch(getExpenseByIdAsync(record.id));
                  console.log("onExpand: ", record, expanded);
                },
              }}
              rowKey={(record) => record.id}
            ></Table>
          </Col>
          {/* <Col span={6}>
            <ExpenseView />
          </Col> */}
        </Row>
      </div>

      <Row type="flex" align="middle">
        <Modal
          // width={"95%"}

          visible={visible}
          footer={null}
          onCancel={() => {
            ModalTogger();
          }}
        >
          <Form form={form} layout="vertical">
            <Row>
              <Col xs={24}>
                <Form.Item
                  label="Reference No"
                  name="PaymentReference"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Other Peson Details!",
                    },
                  ]}
                >
                  <Input placeholder="Payment Reference" />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  label="Attachment"
                  name="Attachment"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Tender Details!",
                    },
                  ]}
                >
                  <Upload
                    beforeUpload={(file) => setFiles([...files, file])}
                    onRemove={handleDelete}
                    accept="image/*,application/pdf,application/pdf,application/msword,
                    application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                    application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                    text/plain,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,
                    application/csv,application/vnd.msexcel,application/excel,.xls,.xlsx,.msg,
                    .svg,.zip,.png"
                    name="logo"
                    listType="picture"
                  >
                    <Button icon={<UploadOutlined />}>Click to upload</Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row className="gx-mt-3">
              <Col xs={24}>
                <Button
                  size="large"
                  onClick={onSubmitHandle}
                  type="primary"
                  className="float-right"
                  value="Submit"
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal>
        <Modal
          visible={visibleInvoiceModal}
          footer={null}
          onCancel={() => {
            ModalTogger();
          }}
        >
          <Form form={form} layout="vertical">
            <Row>
              <Col xs={24}></Col>
              <Col xs={24}>
                <Form.Item
                  label="Attachment"
                  name="Attachment"
                  rules={[
                    {
                      required: true,
                      message: "Please Select the file",
                    },
                  ]}
                >
                  <Upload
                    beforeUpload={(file) => setFiles([...files, file])}
                    onRemove={handleDelete}
                    accept="image/*,application/pdf,application/pdf,application/msword,
                    application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                    application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                    text/plain,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,
                    application/csv,application/vnd.msexcel,application/excel,.xls,.xlsx,.msg,
                    .svg,.zip,.png"
                    name="logo"
                    listType="picture"
                  >
                    <Button icon={<UploadOutlined />}>Click to upload</Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row className="gx-mt-3">
              <Col xs={24}>
                <Button
                  size="large"
                  onClick={onVendorInoiveSubmitHandle}
                  type="primary"
                  className="float-right"
                  value="Submit"
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Row>
    </>
  );
}

var ApprovalStatus = [
  {
    text: "Approved",
    value: "Approved",
  },
];
