import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row, Table, Button, Space, Popconfirm } from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  BoldOutlined,
  BoxPlotOutlined,
} from "@ant-design/icons";
import { getAll, deleteVendorRecord } from "../viewvendors/viewSlice";
import { Link, useHistory } from "react-router-dom";
import AddVendor from "../addvendor/AddVendor";
import { getVendorDetailsById } from "../addvendor/vendorSlice";
import ReactDOM from "react-dom";
import {
  isSuperAdmin,
  isAdmin,
} from "../../../Login../../../src/features/Authentication/Login/LoginSlice";

export function ViewVendors() {
  const dispatch = useDispatch();
  const history = useHistory();
  const vendorsRecords = useSelector((state) => state.vendorList);
  const [records, modifyRecords] = useState(vendorsRecords?.vendorsRecords);
  const [vendor, setVendor] = useState({
    isEditing: false,
    id: -1,
  });

  useEffect(() => {
    dispatch(getAll());
  }, [dispatch]);

  useEffect(() => {
    modifyRecords(vendorsRecords?.vendorsRecords);
  }, [vendorsRecords]);
  const { Column } = Table;

  const [page, setPage] = React.useState(1);
  const deleteVendor = (e) => {
    dispatch(deleteVendorRecord(e.id));
  };
  const editVendor = (e) => {
    setVendor({
      ...vendor,
      isEditing: true,
      id: e,
    });
    //  dispatch(getVendorDetailsById(e));
    history.push("addvendor/" + e);
  };
  if (!vendor.isEditing) {
    return (
      <>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <h2 className="title gx-mb-4">
              View Vendor
              <Button
                type="primary"
                icon={<PlusOutlined />}
                style={{ float: "right" }}
                onClick={() => AddVendor}
              >
                &nbsp;
                <Link to="/vendor/addvendor" style={{ color: "white" }}>
                  Add Vendor
                </Link>
              </Button>
            </h2>
            <div class="mt-30">
              <Table
                size="middle"
                pagination={{
                  onChange(current) {
                    setPage(current);
                  },
                }}
                bordered
                //please check if rowkey is required
                rowkey={(record) => record.id}
                expandable={{
                  expandedRowRender: (record) => (
                    <p style={{ margin: 0 }}>
                      PAN: {record.panNumber}
                      <br></br>
                      IFSC Code: {record.ifscCode}
                      <br></br>
                      Address: {record.address}
                      <br></br>
                      <br></br>
                      <b>Secondary Account details</b>
                      <br></br>
                      Account Holder Name: {record.accountHolderName}
                      <br></br>
                      Bank Name: {record.bankName2}
                      <br></br>
                      Account Number: {record.accountNumber2}
                      <br></br>
                      IFSC Code: {record.ifscCode2}
                      <br></br>
                      <br></br>
                      <b>Address</b>
                      <br></br>
                      {record.address}
                    </p>
                  ),
                  rowExpandable: (records) => records.info !== "Not expandable",
                  onExpand: (expanded, record) =>
                    console.log("onExpand: ", record, expanded),
                }}
                dataSource={records}
                rowKey={(record) => record.id}
              >
                <Column
                  title="S.No"
                  dataIndex="index"
                  key="index"
                  // render={(value, item, index) => index + 1}
                  render={(value, item, index) => (page - 1) * 10 + (index + 1)}
                />
                <Column title="GSTNo" dataIndex="gstno" key="gstno" />
                <Column
                  title="Vendor Name"
                  dataIndex="vendorName"
                  key="vendorName"
                />
                <Column title="Bank Name" dataIndex="bankName" key="bankName" />
                <Column
                  title="Account Number"
                  dataIndex="accountNumber"
                  key="accountNumber"
                />
                <Column title="Email" dataIndex="email" key="email" />
                <Column
                  title="Phone Number"
                  dataIndex="contact"
                  key="contact"
                />
                <Column
                  title="Action"
                  key="action"
                  render={(text, record) => (
                    <Space size="small">
                      <Button
                        icon={<EditOutlined />}
                        size="small"
                        onClick={() => editVendor(record.id)}
                      ></Button>
                      {record.purchaseOrders.length <= 0 ? (
                        <Popconfirm
                          title="Sure to delete?"
                          onConfirm={() => deleteVendor(record)}
                        >
                          <Button
                            danger
                            icon={<DeleteOutlined />}
                            size="small"
                            title="Delete Vendor"
                          ></Button>
                        </Popconfirm>
                      ) : (
                        <Button
                          danger
                          icon={<DeleteOutlined />}
                          size="small"
                          title="Delete is not allowed, as we have POs associated to vendor"
                          disabled={true}
                        ></Button>
                      )}
                    </Space>
                  )}
                />
              </Table>
            </div>
          </Col>
        </Row>
      </>
    );
  } else {
    return (
      <div>
        <AddVendor isInEditMode={vendor.isEditing} id={vendor.id} />
      </div>
    );
  }
}
