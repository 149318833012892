import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Input, InputNumber, Col, Row, Card } from "antd";
import { Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import {
  DownloadFile,
  GetTenderFilePath,
  DeleteFile,
} from "../addProject/AddProjectSlice";
const { Dragger } = Upload;

const uploadProps = {
  name: "file",
  multiple: true,
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successful.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

function TenderInfo(props) {
  console.log("props.tenderfiles", props.tenderfiles);
  const dispatch = useDispatch();
  const handleUploadDone = (file) => {
    return file.onSuccess();
  };
  const handleDelete = (file) => {
    if (file.id) {
      var path = GetTenderFilePath(file.id);
      dispatch(DeleteFile(path, file.name)).then((res) => {
        LocalDeleteFile(file);
      });
    } else {
      LocalDeleteFile(file);
    }
  };

  const LocalDeleteFile = (file) => {
    var files = [...props.tenderfiles];
    var index = files.findIndex((a) => a.uid === file.uid);

    files.splice(index, 1);
    if (files.length === 0) {
      props.setTenderFiles([]);
    } else {
      props.setTenderFiles(files);
    }
  };

  console.log("tender files", props.tenderfiles);
  const handleDownload = (file) => {
    var path = GetTenderFilePath(file.id);
    dispatch(DownloadFile(path, file.name));
  };

  const handleUpload = (file) => {
    props.setTenderFiles([...props.tenderfiles, file]);
  };
  return (
    <div>
      <Card style={{ width: "100%" }}>
        <Row gutter={[16, 16]}>
          <Col span={24} xs={24} md={24} lg={24}>
            <Form.Item
              label="Tender Id"
              name="tenderID"
              rules={[{ required: true, message: "Please input tender id" }]}
              onChange={props.handleChange}
            >
              <InputNumber name="tenderID" placeholder="Tender Id" />
            </Form.Item>
          </Col>
        </Row>
        <Col span={5} xs={24} md={24} lg={24}>
          <Form.Item
            label="Tender Document"
            name="TenderDocument"
            rules={[
              { required: false, message: "Please Upload tender document" },
            ]}
            onChange={props.handleChange}
          >
            <Dragger
              onRemove={handleDelete}
              //fileList={files}
              fileList={props.tenderfiles}
              onPreview={handleDownload}
              beforeUpload={handleUpload}
              // customRequest={handleUploadDone}
              accept="image/*,application/pdf,application/pdf,application/msword,
                      application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                      application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                      text/plain,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,
                      application/csv,application/vnd.msexcel,application/excel,.xls,.xlsx,.msg,
                      .svg,.zip"
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
            </Dragger>
          </Form.Item>
        </Col>
      </Card>
    </div>
  );
}

export default TenderInfo;
