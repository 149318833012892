import React from "react";
import ExpenseTable from "./ViewExpenseTable";
import PurchaseOrderTable from "./ViewPurchaseOrderTable";
import InvoiceTable from "./ViewInvoiceTable";
import DocumentTree from "./DocumentTree";
// import { getFullProjectAsync } from "./ViewProjectSlice";
// import { useSelector, useDispatch } from "react-redux";
import { Tabs } from "antd";
const { TabPane } = Tabs;

const ProjectViewTab = (props) => {
  return (
    <div>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Invoices" key="1">
          <InvoiceTable />
        </TabPane>
        <TabPane tab="PO" key="2" forceRender>
          <PurchaseOrderTable />
        </TabPane>
        <TabPane tab="Expenses" key="3">
          <ExpenseTable />
        </TabPane>
        {/* <TabPane tab="Documents" key="4">
          <DocumentTree />
        </TabPane> */}
        <TabPane tab="Summary" key="5">
          Summary
        </TabPane>
      </Tabs>
    </div>
  );
};

export default ProjectViewTab;
