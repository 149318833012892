import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DownloadAttachment } from "../addExpense/AddExpenseSlice";
import { Table, Button, Tag, Card, Row, Col } from "antd";
import Moment from "react-moment";
export default function ExpenseView(props) {
  const dispatch = useDispatch();
  const expenseShortData = useSelector(
    (state) => state.approvals.ExpenseShortRecords
  );
  const HandleDownload = (expenseId, filename, filetype) => {
    dispatch(DownloadAttachment(expenseId, filetype, filename));
  };
  const [expensedata, setexpensedata] = useState(null);
  useEffect(() => {
    let record = expenseShortData.find((a) => a.expenseId === props.id);
    setexpensedata(record);
  }, [expenseShortData]);
  return (
    <>
      <Card title="General information" type="inner">
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" xs={24} md={12} lg={8}>
            <strong>Project Name : </strong>
            <span>{expensedata?.shortName.toUpperCase()}</span>
          </Col>

          <Col className="gutter-row" xs={24} md={12} lg={8}>
            <strong>Other Expenses : </strong>
            <span>{expensedata?.otherExpenses}</span>
          </Col>
          <Col className="gutter-row" xs={24} md={12} lg={8}>
            <strong>Approval Status : </strong>
            <span>
              {expensedata && expensedata.approvalStatus && (
                <>
                  {expensedata.approvalStatus === "Approved" ? (
                    <span style={{ color: "green" }}>Approved</span>
                  ) : expensedata.approvalStatus === "Rejected" ? (
                    <span style={{ color: "red" }}>Rejected</span>
                  ) : expensedata.approvalStatus ===
                    "Waiting for Admin Approval" ? (
                    <span style={{ color: "blue" }}>
                      Waiting for Admin Approval
                    </span>
                  ) : (
                    <span style={{ color: "orange" }}>
                      Waiting for Approval
                    </span>
                  )}
                </>
              )}
            </span>
          </Col>
          <Col className="gutter-row" xs={24} md={12} lg={8}>
            <strong>Reason for Rejection : </strong>
            <span>{expensedata?.rejectedReason?.toUpperCase()}</span>
          </Col>
          <Col className="gutter-row" xs={24} md={12} lg={8}>
            <strong>Reason for Expenses : </strong>
            <span>{expensedata?.expensesReason.toUpperCase()}</span>
          </Col>
          <Col className="gutter-row" xs={24} md={12} lg={8}>
            <strong>Whom : </strong>
            <span>{expensedata?.vendor.toUpperCase()}</span>
          </Col>
          <Col className="gutter-row" xs={24} md={12} lg={8}>
            <strong>Other Person : </strong>
            <span>{expensedata?.otherPerson}</span>
          </Col>
          <Col className="gutter-row" xs={24} md={12} lg={8}>
            <strong>Amount : </strong>
            <span>{expensedata?.totalAmount}</span>
          </Col>
        </Row>
      </Card>
      <Card title="Created/Updated details" type="inner">
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" xs={24} md={12} lg={12}>
            <strong>Created By : </strong>
            <span>{expensedata?.createdByUser}</span>
          </Col>
          <Col className="gutter-row" xs={24} md={12} lg={12}>
            <strong>Created Date : </strong>
            <span>
              <Moment utc local format="DD-MMM-YYYY">
                {expensedata?.createdDateTime}
              </Moment>
            </span>
          </Col>
          <Col className="gutter-row" xs={24} md={12} lg={12}>
            <strong>Updated By : </strong>
            <span>{expensedata?.updatedByUser}</span>
          </Col>
          <Col className="gutter-row" xs={24} md={12} lg={12}>
            <strong>Updated Date : </strong>
            <span>
              {expensedata?.updatedDateTime && (
                <Moment utc local format="DD-MMM-YYYY">
                  {expensedata?.updatedDateTime}
                </Moment>
              )}
            </span>
          </Col>
        </Row>
      </Card>
      <Card title="Documentation" type="inner">
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" xs={24} md={12} lg={8}>
            <strong>Vendor Invoice : </strong>
            <span>
              {expensedata?.files &&
                expensedata.files.map((file) => (
                  <li>
                    <a
                      onClick={() =>
                        HandleDownload(expensedata.expenseId, file, "Expense")
                      }
                    >
                      {file}
                    </a>
                  </li>
                ))}
            </span>
          </Col>
          <Col className="gutter-row" xs={24} md={12} lg={8}>
            <strong>Payment Reference : </strong>
            <span>
              {expensedata?.paymentrefFiles &&
                expensedata.paymentrefFiles.map((file) => (
                  <li>
                    <a
                      onClick={() =>
                        HandleDownload(
                          expensedata.expenseId,
                          file,
                          "paymentreference"
                        )
                      }
                    >
                      {file}
                    </a>
                  </li>
                ))}
            </span>
          </Col>
        </Row>
      </Card>
      {/* <table style={{ paddingLeft: "100px", paddingRight: "100px" }}>
        <tr>
          <td>
            <strong>Project Name</strong>{" "}
          </td>
          <td>{expensedata?.shortName.toUpperCase()}</td>
        </tr>
        <tr>
          <td>
            <strong>Tender</strong>
          </td>
          <td>{expensedata?.tenderId}</td>
        </tr>
        <tr>
          <td>
            <strong>Other Expenses</strong>
          </td>
          <td>{expensedata?.otherExpenses}</td>
        </tr>
        <tr>
          <td>
            <strong>Approval Status</strong>
          </td>
          <td>
            {expensedata && expensedata.approvalStatus && (
              <>
                {expensedata.approvalStatus === "Approved" ? (
                  <p style={{ color: "green" }}>Approved</p>
                ) : expensedata.approvalStatus === "Rejected" ? (
                  <p style={{ color: "red" }}>Rejected</p>
                ) : expensedata.approvalStatus ===
                  "Waiting for Admin Approval" ? (
                  <p style={{ color: "blue" }}>Waiting for Admin Approval</p>
                ) : (
                  <p style={{ color: "orange" }}>Waiting for Approval</p>
                )}
              </>
            )}
          </td>
        </tr>
        <tr>
          <td>
            <strong>Reason for Expenses</strong>
          </td>
          <td>{expensedata?.expensesReason.toUpperCase()}</td>
        </tr>
        <tr>
          <td>
            <strong>Whom</strong>
          </td>
          <td>{expensedata?.vendor.toUpperCase()}</td>
        </tr>
        <tr>
          <td>
            <strong>Others</strong>
          </td>
          <td></td>
        </tr>
        <tr>
          <td>
            <strong>Amount</strong>
          </td>
          <td>{expensedata?.totalAmount}</td>
        </tr>
        <tr>
          <td>
            <strong>Vendor Invoice</strong>
          </td>
          <td>
            {expensedata?.files &&
              expensedata.files.map((file) => (
                <li>
                  <a
                    onClick={() =>
                      HandleDownload(expensedata.expenseId, file, "Expense")
                    }
                  >
                    {file}
                  </a>
                </li>
              ))}
          </td>
        </tr>
        <tr>
          <td>
            <strong>Payment Reference</strong>
          </td>
          <td>
            {expensedata?.paymentrefFiles &&
              expensedata.paymentrefFiles.map((file) => (
                <li>
                  <a
                    onClick={() =>
                      HandleDownload(
                        expensedata.expenseId,
                        file,
                        "paymentreference"
                      )
                    }
                  >
                    {file}
                  </a>
                </li>
              ))}
          </td>
        </tr>
      </table> */}
    </>
  );
}
