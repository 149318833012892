import { createSlice } from "@reduxjs/toolkit";
import * as Settings from "../../../settings/Settings";
import axios from "axios";
import { message } from "antd";
const apiurl = Settings.ApiEndPoint;
export const viewProjectSlice = createSlice({
  name: "ViewProject",
  initialState: {
    projectFullRecord: {},
  },
  reducers: {
    getProjectFullRecords: (state, action) => {
      state.projectFullRecord = {};
      state.projectFullRecord = action.payload;
    },
    getProjectRecords: (state, action) => {
      state.projectRecord = [];
      action.payload.forEach((item) => {
        state.projectRecord.push(item);
      });
    },
  },
});

export const { getProjectFullRecords, getProjectRecords } =
  viewProjectSlice.actions;

export const getFullProjectAsync = (id) => (dispatch) => {
  axios
    .get(`${apiurl}api/Project/GetFullProjectById/${id}`)
    .then((res) => {
      dispatch(getProjectFullRecords(res.data));
      //message.success("Data Successful ", [1.5]);
    })
    .catch(() => {
      message.error("Unable to fetch data", [1.5]);
    });
};

export default viewProjectSlice.reducer;
