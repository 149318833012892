import React, { useState, useEffect } from "react";
import {
  Input,
  Col,
  Row,
  Form,
  Button,
  InputNumber,
  Card,
  message,
} from "antd";
import { Link, useParams, useHistory } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  SaveForm,
  updatedVendor,
  getVendorDetailsById,
  IsVendorVaild,
} from "./vendorSlice";

import { ViewVendors } from "../viewvendors/ViewVendors";

const { TextArea } = Input;

export function AddVendor(props) {
  const history = useHistory();
  const [displayVendor, setDisplayStatus] = useState(
    !props.isInEditMode ?? true
  );

  const { id } = useParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const selectedRecord = useSelector((state) => state.vendorDetails.record);
  const [vendorValues, updateValues] = useState({
    id: null,
    vendorName: "",
    gstno: "",
    bankName: "",
    accountNumber: "",
    ifscCode: "",
    email: "",
    contact: "",
    address: "",
    panNumber: "",
    accountHolderName: "",
    bankName2: "",
    accountNumber2: "",
    ifscCode2: "",
  });
  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not validate!",
    },
  };

  console.log("vendorValues", vendorValues);
  useEffect(() => {
    form.resetFields();
    if (id !== undefined && id > 0) {
      dispatch(getVendorDetailsById(id));
    } else {
      updateValues();
    }
    // dispatch(getVendorDetailsById(props.id));
  }, [id]);
  useEffect(() => {
    form.resetFields();
  }, [vendorValues]);
  useEffect(() => {
    console.log("Selected Record:", selectedRecord);
    if (Object.keys(selectedRecord).length > 0) {
      updateValues(selectedRecord, id && id);
      setDisplayStatus(true);
    }
  }, [selectedRecord]);

  const VendodValidation = (e) => {
    var name = e.target.value;
    if (name !== "") {
      dispatch(IsVendorVaild(name)).then((res) => {
        if (res) {
          form.setFields([
            {
              name: "vendorName",
              errors: ["venodr already  exists"],
            },
          ]);
        }
      });
    }
  };
  const onSubmit = (values) => {
    var redirectLink = "../vendor/viewvendor";
    if (values.id > 0) {
      redirectLink = "../viewvendor";
    }
    if (values.id > 0) {
      //values.id = props.id;
      dispatch(updatedVendor(values))
        .then((res) => {
          message.success("Successfully Updated");
          form.resetFields();
          history.push(redirectLink);
        })
        .catch(() => {
          message.error("Error while Updating");
        });
    } else {
      dispatch(SaveForm(values))
        .then((res) => {
          message.success("Successfully Added");
          form.resetFields();
          history.push(redirectLink);
        })
        .catch(() => {
          message.error("Error while Saving");
        });
    }
  };

  if (displayVendor || true) {
    return (
      <div>
        <h2 className="title gx-mb-4">
          Add Vendor
          <Button
            type="primary"
            icon={<EyeOutlined />}
            style={{ float: "right" }}
            onClick={() => ViewVendors}
          >
            &nbsp;
            <Link to="/vendor/viewvendor" style={{ color: "white" }}>
              View Vendors
            </Link>
          </Button>
        </h2>
        <Form
          name="nest-messages"
          layout="vertical"
          onFinish={onSubmit}
          form={form}
          validateMessages={validateMessages}
          initialValues={id !== undefined && id > 0 ? vendorValues : {}}
          // initialValues={{
          //   id: vendorValues.id,
          //   vendorName: vendorValues.vendorName,
          //   gstno: vendorValues.gstno,
          //   bankName: vendorValues.bankName,
          //   accountNumber: vendorValues.accountNumber,
          //   ifscCode: vendorValues.ifscCode,
          //   email: vendorValues.email,
          //   contact: vendorValues.contact,
          //   address: vendorValues.address,
          //   panNumber: vendorValues.panNumber,
          //   accountHolderName: vendorValues.accountHolderName,
          //   bankName2: vendorValues.bankName2,
          //   accountNumber2: vendorValues.accountNumber2,
          //   ifscCode2: vendorValues.ifscCode2,
          // }}
        >
          <Card span={24}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} md={12} lg={8}>
                <Form.Item label="id" name="id" hidden={true}>
                  <InputNumber name="id" placeholder="Number" />
                </Form.Item>
                <Form.Item
                  label="GSTNo"
                  name="gstno"
                  rules={[{ required: false, message: "Please enter GST" }]}
                >
                  <Input placeholder="GST" maxLength="100" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} lg={8}>
                <Form.Item
                  label="Vendor Name"
                  name="vendorName"
                  rules={[
                    { required: true, message: "Please enter vendor name" },
                  ]}
                >
                  <Input
                    onBlur={VendodValidation}
                    placeholder="Vendor Name"
                    maxLength="100"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={12} lg={8}>
                <Form.Item
                  label="Primary Bank Name"
                  name="bankName"
                  rules={[
                    { required: true, message: "Please enter bank name" },
                  ]}
                >
                  <Input placeholder="Bank Name" maxLength="100" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} lg={8}>
                <Form.Item
                  label="Primary Account Number"
                  name="accountNumber"
                  rules={[
                    { required: true, message: "Please enter account number" },
                  ]}
                >
                  <Input placeholder="Account Number" />
                </Form.Item>
              </Col>

              <Col xs={24} md={12} lg={8}>
                <Form.Item
                  label=" Primary IFSC Code"
                  name="ifscCode"
                  rules={[
                    { required: true, message: "Please enter IFSC Code" },
                  ]}
                >
                  <Input placeholder="IFSC Code" maxLength="20" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} lg={8}>
                <Form.Item
                  label="PAN Card"
                  name="panNumber"
                  // rules={[
                  //   { required: true, message: "Please enter PAN number" },
                  // ]}
                >
                  <Input placeholder="PAN Card" maxLength="20" />
                </Form.Item>
              </Col>

              <Col xs={24} md={12} lg={8}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not a valid E-mail",
                    },
                    {
                      required: false,
                      message: "Please input E-mail",
                    },
                    { max: 50 },
                  ]}
                >
                  <Input placeholder="Email" maxLength="40" />
                </Form.Item>
              </Col>

              <Col xs={24} md={12} lg={8}>
                <Form.Item
                  label="Phone Number"
                  name="contact"
                  rules={[
                    { required: true, message: "Please enter phone number" },
                  ]}
                >
                  <InputNumber placeholder="Phone Number" />
                </Form.Item>
              </Col>

              <Col xs={24} md={12} lg={8}>
                <Form.Item
                  label="Address"
                  name="address"
                  rules={[{ required: true, message: "Please enter address" }]}
                >
                  <TextArea
                    placeholder="Address"
                    maxLength="1000"
                    autoSize={{ minRows: 3, maxRows: 5 }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} lg={8}>
                <Form.Item
                  label="Secondary Account Holder Name"
                  name="accountHolderName"
                  rules={[
                    { required: false, message: "Please enter account name" },
                  ]}
                >
                  <Input
                    placeholder="Secondary Account Holder Name"
                    maxLength="100"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} lg={8}>
                <Form.Item
                  label="Secondary Bank Name"
                  name="bankName2"
                  rules={[
                    {
                      required: false,
                      message: "Please enter secondary bank name",
                    },
                  ]}
                >
                  <Input placeholder="Secondary Bank Name" maxLength="100" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} lg={8}>
                <Form.Item
                  label="Secondary Account Number"
                  name="accountNumber2"
                  rules={[
                    { required: false, message: "Please enter account name" },
                  ]}
                >
                  <Input placeholder="Secondary Account Name" />
                </Form.Item>
              </Col>

              <Col xs={24} md={12} lg={8}>
                <Form.Item
                  label="Secondary IFSC Code"
                  name="ifscCode2"
                  rules={[
                    { required: false, message: "Please enter IFSC code" },
                  ]}
                >
                  <Input placeholder="IFSC Code" maxLength="20" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col md={4} lg={2}>
                <Form.Item style={{ marginTop: 20 }}>
                  <Button type="primary" htmlType="submit">
                    Add Vendor
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Form>
      </div>
    );
  } else {
    return <h1>Fetching In Progress</h1>;
  }
}
export default AddVendor;
