import React from "react";
import * as Settings from "../../../settings/Settings";
import { Table } from "antd";
import { useSelector } from "react-redux";
const NoData = Settings.NoData;
const { Column } = Table;

// const invoicesData = [
//   {
//     key: "1",
//     sno: "1",
//     date: "21-08-2020",
//     clientName: "Vikatz Solar",
//     basic: "32000",
//     cgst: "600",
//     sgst: "600",
//     total: "33200",
//   },
//   {
//     key: "2",
//     sno: "2",
//     date: "22-08-2020",
//     clientName: "CoolKaru",
//     basic: "34000",
//     cgst: "700",
//     sgst: "700",
//     total: "35400",
//   },
//   {
//     key: "3",
//     sno: "3",
//     date: "23-08-2020",
//     clientName: "KarunCan",
//     basic: "100000",
//     cgst: "1000",
//     sgst: "1000",
//     total: "102000",
//   },
//   {
//     key: "4",
//     clientName: "Total",
//     basic: "100000",
//     cgst: "1000",
//     sgst: "1000",
//     total: "102000",
//   },
// ];

const ViewInvoiceTable = () => {
  const projecfulltdata = useSelector(
    (state) => state.ProjectFullList.projectFullRecord
  );

  // const NoData = "No Data";
  return (
    <div>
      <Table dataSource={projecfulltdata.invoices} size="small" bordered>
        <Column title="S.No" dataIndex="invoiceId" key="invoiceId" />
        <Column title="Date" dataIndex="startdate" key="startdate" />
        <Column
          title="Client Name"
          dataIndex="vendorName"
          key="vendorName"
          render={(vendorName) => (
            <span>{vendorName ? vendorName : NoData}</span>
          )}
        />
        <Column
          title="Basic"
          dataIndex="basicAmount"
          key="basicAmount"
          render={(basicAmount) => (
            <span>{basicAmount ? basicAmount : NoData}</span>
          )}
        />
        {/* <Column title="SGST" dataIndex="sgst" key="sgst" />
          <Column title="CGST" dataIndex="cgst" key="cgst" /> */}
        <Column
          title="Total"
          dataIndex="totalAmount"
          key="totalAmount"
          render={(totalAmount) => (
            <span>{totalAmount ? totalAmount : NoData}</span>
          )}
        />
      </Table>
    </div>
  );
};

export default ViewInvoiceTable;
