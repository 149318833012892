import { createSlice } from "@reduxjs/toolkit";
import * as Settings from "../../../settings/Settings";
import axios from "axios";
import { notification, message } from "antd";
import jwtDecode from "jwt-decode";
const apiurl = Settings.ApiEndPoint;

export const LoginSlice = createSlice({
  name: "Login",
  initialState: {
    token: JSON.parse(localStorage.getItem("token")),
    // token: null,
    initURL: "",
    requirAccessMessage: "",
    errorLoginMessage: "",
  },
  reducers: {
    SaveToken: (state, action) => {
      state.token = action.payload;
    },
    signOut: (state, action) => {
      state.token = null;
      state.initURL = "";
    },
    setInitUrl: (state, action) => {
      state.initURL = action.payload;
    },
    NoAccessMessage: (state, action) => {
      state.requirAccessMessage = action.payload;
    },
    ErrorMessage: (state, action) => {
      state.errorLoginMessage = action.payload;
    },
  },
});

export const { SaveToken, signOut, setInitUrl, NoAccessMessage, ErrorMessage } =
  LoginSlice.actions;

export const SignIn = (values) => (dispatch) => {
  axios
    .post(`${apiurl}api/Login`, values)
    .then((res) => {
      if (res.data) {
        if (res.data.errorResponse) {
          if (res.data.errorResponse.StatusDesc === "NoAccess") {
            dispatch(NoAccessMessage(res.data.errorResponse.ErrorMessage));
          } else {
            message.error(res.data.errorResponse.statusDesc);
          }
        } else {
          localStorage.setItem("token", JSON.stringify(res.data.access_token));
          const decoded = jwtDecode(res.data.access_token);
          if (decoded) localStorage.setItem("user", JSON.stringify(decoded));
          if (res.data.roles)
            localStorage.setItem("userroles", JSON.stringify(res.data.roles));

          axios.defaults.headers.common[
            "authorization"
          ] = `${res.data.token_type} ${res.data.access_token}`;
          dispatch(SaveToken(res.data.access_token));
        }
      }
    })
    .catch((error) => {
      message.error(error.message);
    });
};

export const SignOut = () => (dispatch) => {
  localStorage.clear();
  dispatch(signOut());
  dispatch(setInitUrl(""));
};

export const SetInitUrl = (url) => (dispatch) => {
  dispatch(setInitUrl(url));
};

export const SetNoAccessMessage = (message) => (dispatch) => {
  dispatch(NoAccessMessage(message));
};
export const SetErrorMessage = (message) => (dispatch) => {
  dispatch(ErrorMessage(message));
};

export function isNormalUser() {
  return userIsInAdGroup("user");
}
export function isAdmin() {
  return userIsInAdGroup("manager");
}
export function isSuperAdmin() {
  return userIsInAdGroup("admin");
}

export function userIsInAdGroup(givenrole) {
  let userRoles = JSON.parse(localStorage.getItem("userroles"));
  let roletoCheck = givenrole;
  let result = false;
  if (userRoles) {
    userRoles.forEach((role) => {
      console.log(
        "Roles",
        roletoCheck.toLowerCase().indexOf(role.toLowerCase())
      );
      console.log(
        "Role check",
        roletoCheck.toLowerCase() === role.toLowerCase()
      );
      if (roletoCheck.toLowerCase() === role.toLowerCase()) {
        result = true;
      }
    });
  }
  return result;
}
export default LoginSlice.reducer;
