import React from "react";
import * as Settings from "../../../settings/Settings";
import { useSelector, useDispatch } from "react-redux";
import { Table, Button, Tag } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { DownloadAttachment } from "../../expenses/addExpense/AddExpenseSlice";
import Moment from "react-moment";
const NoData = Settings.NoData;
const { Column } = Table;

// const expensesData = [
//   {
//     key: "1",
//     sno: "1",
//     date: "19-08-2018",
//     whom: "prudhvi",
//     reason: "Book One book bundle",
//     amount: "35000",
//     cgst: "800",
//     sgst: "800",
//     total: "78200",
//   },
//   {
//     key: "2",
//     sno: "2",
//     date: "19-08-2018",
//     whom: "sagar",
//     reason: "njdjsndsn",
//     amount: "35000",
//     cgst: "800",
//     sgst: "800",
//     total: "78200",
//   },
//   {
//     key: "3",
//     sno: "3",
//     date: "19-08-2018",
//     whom: "Rajesh Mishra",
//     reason: "fdkknnsdns",
//     amount: "35000",
//     cgst: "800",
//     sgst: "800",
//     total: "78200",
//   },
//   {
//     key: "4",
//     reason: "Total",
//     amount: "100000",
//     cgst: "1000",
//     sgst: "1000",
//     total: "102000",
//   },
// ];

const ViewExpenseTable = () => {
  const dispatch = useDispatch();
  const projecfulltdata = useSelector(
    (state) => state.ProjectFullList.projectFullRecord
  );

  const HandleDownload = (expenseId, referecne, file) => {
    dispatch(DownloadAttachment(expenseId, referecne, file));
  };
  console.log("projecfulltdata.expenses", projecfulltdata.expenses);
  const [page, setPage] = React.useState(1);
  return (
    <div>
      <Table
        dataSource={projecfulltdata.expenses}
        size="small"
        bordered
        pagination={{
          onChange(current) {
            setPage(current);
          },
        }}
      >
        <Column
          title="S.No"
          dataIndex="expenseId"
          key="expenseId"
          align="center"
          render={(value, item, index) => (page - 1) * 10 + index + 1}
        />
        <Column
          title="PO Number"
          align="center"
          dataIndex="poNumber"
          key="PoNumber"
        />
        <Column
          title="Date"
          dataIndex="createdDateTime"
          key="date"
          align="center"
          render={(createdDateTime) => (
            <>
              <p>
                {createdDateTime === null ? (
                  "null"
                ) : (
                  <>
                    <Moment utc local format="DD-MMM-YYYY">
                      {createdDateTime}
                    </Moment>{" "}
                    &nbsp;
                    <Moment
                      utc
                      local
                      fromNow
                      ago
                      style={{ border: "2px solid pink", Color: "blue" }}
                    >
                      {createdDateTime}
                    </Moment>
                  </>
                )}
              </p>
            </>
          )}
        />
        <Column
          title="Whom"
          dataIndex="vendor"
          key="vendor"
          align="center"
          render={(vendor) => <span>{vendor ? vendor : NoData}</span>}
        />
        <Column
          title="Reason for Expenses"
          dataIndex="expensesReason"
          align="center"
          key="expensesReason"
        />
        <Column title="Amount" align="center" dataIndex="amount" key="amount" />

        <Column
          title="Reference"
          dataIndex="paymentReference"
          key="Reference"
          align="center"
        />
        <Column
          title="Attachment"
          //dataIndex="files"
          align="center"
          render={(expense) => {
            return expense.files.map((file) => {
              return (
                <Button
                  style={{ color: "blue" }}
                  onClick={() =>
                    HandleDownload(expense.expenseId, "paymentreference", file)
                  }
                >
                  <DownloadOutlined twoToneColor={"blue"} />
                </Button>
              );
            });
          }}
          key="Attachment"
        />

        {/* <Column title="SGST" dataIndex="gst" key="gst" />
        <Column
          title="CGST"
          dataIndex="cgst"
          key="cgst"
          render={(cgst) => <span>{cgst ? cgst : NoData}</span>}
        /> */}
        <Column
          title="Total"
          align="center"
          dataIndex="totalAmount"
          key="totalAmount"
        />
        <Column
          title="ApprovalStatus"
          align="center"
          dataIndex="approvalStatus"
          key="approvalStatus"
          render={(approvalStatus, record) => (
            <>
              {record.approvalStatus === "Approved" ? (
                <p style={{ color: "green" }}>Approved</p>
              ) : record.approvalStatus === "Rejected" ? (
                <p style={{ color: "red" }}>Rejected</p>
              ) : record.approvalStatus === "Waiting for Admin Approval" ? (
                <p style={{ color: "blue" }}>Waiting for Admin Approval</p>
              ) : (
                <p style={{ color: "orange" }}>Waiting for Approval</p>
              )}
              {/* {record.approvalStatus === "Approved" ? (
                <Tag key={approvalStatus} color="#87d068">
                  {approvalStatus}
                </Tag>
              ) : record.approvalStatus === "Rejected" ? (
                <Tag color="#f50" key={approvalStatus}>
                  {approvalStatus}
                </Tag>
              ) : record.approvalStatus === "Waiting for Admin Approval" ? (
                <Tag key={approvalStatus} color="#2db7f5">
                  {approvalStatus}
                </Tag>
              ) : (
                <Tag key={approvalStatus} color="#2db7f5">
                  {approvalStatus}
                </Tag>
              )} */}
            </>
          )}
        />
        <Column
          title="Approved/Rejected Date"
          dataIndex="adminApprovedDate"
          key="adminApprovedDate"
          align="center"
          render={(adminApprovedDate) => (
            <>
              <p>
                {adminApprovedDate === null ? (
                  ""
                ) : (
                  <>
                    <Moment utc local format="DD-MMM-YYYY">
                      {adminApprovedDate}
                    </Moment>
                  </>
                )}
              </p>
            </>
          )}
        />
        <Column
          title="Created By"
          dataIndex="createdByUser"
          key="createdByUser"
          align="center"
        />
        <Column
          title="Created Date"
          dataIndex="createdDateTime"
          key="createdDateTime"
          align="center"
          render={(createdDateTime) => (
            <>
              <p>
                {createdDateTime === null ? (
                  ""
                ) : (
                  <>
                    <Moment utc local format="DD-MMM-YYYY">
                      {createdDateTime}
                    </Moment>
                  </>
                )}
              </p>
            </>
          )}
        />
        <Column
          title="Updated By"
          dataIndex="updatedByUser"
          key="updatedByUser"
          align="center"
        />
        <Column
          title="Updated Date"
          dataIndex="updatedDateTime"
          key="updatedDateTime"
          align="center"
          render={(updatedDateTime) => (
            <>
              <p>
                {updatedDateTime === null ? (
                  ""
                ) : (
                  <>
                    <Moment utc local format="DD-MMM-YYYY">
                      {updatedDateTime}
                    </Moment>
                  </>
                )}
              </p>
            </>
          )}
        />
      </Table>
    </div>
  );
};

export default ViewExpenseTable;
