import { createSlice } from "@reduxjs/toolkit";
import * as Settings from "../../../settings/Settings";
import axios from "axios";
const apiurl = Settings.ApiEndPoint;
export const viewSlice = createSlice({
  name: "vendorList",
  initialState: {
    isRecordDeleted: false,
    vendorsRecords: [],
  },
  reducers: {
    getAllVendorsRecords: (state, action) => {
      state.vendorsRecords = [];
      action.payload.forEach((item) => {
        state.vendorsRecords.push(item);
      });
    },
    deleteRecord: (state, action) => {},
  },
});

export const { getAllVendorsRecords, deleteRecord } = viewSlice.actions;

export const getAll = () => (dispatch) => {
  axios.get(`${apiurl}api/Vendors`).then((res) => {
    console.log(res);
    dispatch(getAllVendorsRecords(res.data));
  });
};

export const deleteVendorRecord = (id) => (dispatch) => {
  axios.post(`${apiurl}api/Vendors/RemoveVendorById/${id}`).then((res) => {
    dispatch(getAll());
  });
};

export default viewSlice.reducer;
