import { createSlice } from "@reduxjs/toolkit";
import * as Settings from "../../../settings/Settings";
import axios from "axios";
import { notification, message } from "antd";

const apiurl = Settings.ApiEndPoint;

export const addExpenseSlice = createSlice({
  name: "expenses",
  initialState: {
    vendorRecords: [],
    projectShortRecords: [],
    InvoiceAttachment: [],
  },
  reducers: {
    getVendorShortRecords: (state, action) => {
      state.vendorRecords = [];
      action.payload.forEach((item) => {
        state.vendorRecords.push(item);
      });
    },

    getProjectShortRecords: (state, action) => {
      state.projectShortRecords = [];
      action.payload.forEach((item) => {
        state.projectShortRecords.push(item);
      });
    },

    SetInvoiceAttachment: (state, action) => {
      state.InvoiceAttachment = [];
      // state.InvoiceAttachment.push(action.payload);
    },
    getExpenseById: (state, action) => {
      state.selectedExpenseRecord = action.payload;
    },
  },
});

export const {
  getVendorShortRecords,
  getProjectShortRecords,
  SetInvoiceAttachment,
  getExpenseById,
} = addExpenseSlice.actions;

export const getExpenseByIdAsync = (id) => (dispatch) => {
  axios.get(`${apiurl}api/Expense/` + id).then((res) => {
    console.log(res);
    dispatch(getExpenseById(res.data));
  });
};

export const getVendorShortAsync = () => (dispatch) => {
  axios.get(`${apiurl}api/LookUp/GetVendorsLookup`).then((res) => {
    console.log(res);
    dispatch(getVendorShortRecords(res.data));
  });
};

export const getProjectShortAsync = () => (dispatch) => {
  axios.get(`${apiurl}api/LookUp/GetProjectLookup`).then((res) => {
    console.log(res);
    dispatch(getProjectShortRecords(res.data));
  });
};

export const saveExpenseForm = (data, form) => (dispatch) => {
  var endpoint = "AddExpense";
  if (data.id > 0) {
    endpoint = "UpdateExpense";
  }
  const values = {
    ...data,
  };
  delete values.invoiceAttachment;
  delete values.filesList;
  return axios
    .post(`${apiurl}api/Expense/` + endpoint, values)
    .then((res) => {
      const formData = new FormData();

      data.filesList.map((v) => {
        formData.append("files", v);
      });

      notification.success({
        placement: "topRight",
        message: "saved succesfully",
      });
      form.resetFields();
      if (data.invoiceAttachment != null) {
        axios
          .post(
            `${apiurl}api/Attachment/Expense/Create/${res.data}/${"Expense"}`,
            formData
          )
          .then((res) => {
            message.success("Documents uploaded succesfully", [1.5]);
            return true;
          })
          .catch((Error) => {
            message.error("Something went wrong while downloading", [1.5]);
            return false;
          });
      }
    })
    .catch(() => message.error("something went wrong", [1.5]));
};

export const AddInvoiceAttachment = (file) => (dispatch) => {
  dispatch(SetInvoiceAttachment(file));
};
export const DownloadAttachment =
  (epenseId, formmName, filename) => (dispatch) => {
    return axios
      .get(
        `${apiurl}api/Attachment/Expense/Download/${epenseId}/${formmName}/${filename}`
      )
      .then((res) => {
        var the_file = new Blob([window.atob(res.data.fileContent)], {
          type: "image/png",
          encoding: "utf-8",
        });
        var fr = new FileReader();
        fr.onload = function (oFREvent) {
          var v = oFREvent.target.result.split(",")[1]; // encoding is messed up here, so we fix it
          v = atob(v);
          var good_b64 = btoa(decodeURIComponent(escape(v)));
          good_b64 = `${
            ext[res.data.fileExtension && res.data.fileExtension.toLowerCase()]
          }${good_b64}`;
          const link = document.createElement("a");
          link.href = good_b64;
          link.download = res.data.fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          return good_b64;
        };
        fr.readAsDataURL(the_file);
      });
  };
export default addExpenseSlice.reducer;
const ext = {
  ".png": "data:image/png;base64,",
  ".jpeg": "data:image/jpeg;base64,",
  ".jpg": "data:image/jpeg;base64,",
  ".pdf": "data:application/pdf;base64,",
  ".xls": "data:application/vnd.ms-excel;base64,",
  ".doc": "data:application/msword;base64,",
  ".docx": "data:application/msword;base64,",
  ".xlsx":
    "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
  ".ppt": "data:application/vnd.ms-powerpoint;base64,",
  ".pptx":
    "data:application/vnd.openxmlformats-officedocument.presentationml.presentation;base64,",
  ".txt": "data:text/plain;base64,",
  ".msg": "data:application/vnd.ms-outlook;base64,",
  ".svg": "data:image/svg+xml;base64,",
  ".zip": "data:application/zip;base64,",
  ".url": "data:application/internet-shortcut;base64,",
};
