import React from "react";
import * as Settings from "../../../settings/Settings";
import { Table } from "antd";
import { useSelector } from "react-redux";
import Moment from "react-moment";
const NoData = Settings.NoData;
const { Column } = Table;

// const poData = [
//   {
//     key: "1",
//     sno: "1",
//     date: "19-08-2020",
//     clientName: "ABCDFGRE",
//     basic: "35000",
//     cgst: "800",
//     sgst: "800",
//     total: "78200",
//   },
//   {
//     key: "2",
//     sno: "2",
//     date: "15-08-2020",
//     clientName: "FGBHDFG",
//     basic: "100000",
//     cgst: "700",
//     sgst: "700",
//     total: "1000400",
//   },
//   {
//     key: "3",
//     sno: "3",
//     date: "09-08-2020",
//     clientName: "KarunCan",
//     basic: "100000",
//     cgst: "1000",
//     sgst: "1000",
//     total: "102000",
//   },
//   {
//     key: "4",
//     clientName: "Total",
//     basic: "100000",
//     cgst: "1000",
//     sgst: "1000",
//     total: "102000",
//   },
// ];

const ViewPurchaseOrderTable = () => {
  const projecfulltdata = useSelector(
    (state) => state.ProjectFullList.projectFullRecord
  );
  const [page, setPage] = React.useState(1);
  // const NoData = "No Data";
  return (
    <div>
      <Table
        dataSource={projecfulltdata.purchaseOrders}
        size="small"
        bordered
        pagination={{
          onChange(current) {
            setPage(current);
          },
        }}
      >
        <Column
          title="S.No"
          dataIndex="id"
          key="id"
          render={(value, item, index) => (page - 1) * 10 + index + 1}
        />
        <Column title="PO Number" dataIndex="poNumber" key="poNumber" />
        <Column
          title="Project Name"
          dataIndex="projectName"
          key="projectName"
        />
        <Column
          title="PO Created Date"
          dataIndex="pocreatedDate"
          key="pocreatedDate"
          render={(createdDateTime) => (
            <>
              <p>
                {createdDateTime === null ? (
                  ""
                ) : (
                  <>
                    <Moment utc local format="DD-MMM-YYYY">
                      {createdDateTime}
                    </Moment>
                  </>
                )}
              </p>
            </>
          )}
        />
        <Column
          title="Vendor Name"
          dataIndex="vendorName"
          key="vendorName"
          // render={(clientName) => (
          //   <span>{clientName ? clientName : NoData}</span>
          // )}
        />
        {/* <Column
          title="Basic"
          dataIndex="basicAmount"
          key="basicAmount"
          render={(basicAmount) => (
            <span>{basicAmount != null ? basicAmount : NoData}</span>
          )}
        />
        <Column
          title="SGST"
          dataIndex="sgst"
          key="sgst"
          render={(sgst) => <span>{sgst ? sgst : NoData}</span>}
        />
        <Column
          title="CGST"
          dataIndex="cgst"
          key="cgst"
          render={(sgst) => <span>{sgst ? sgst : NoData}</span>}
        /> */}
        <Column
          title="Total"
          dataIndex="totalAmount"
          key="totalAmount"
          render={(totalAmount) => (
            <span>{totalAmount ? totalAmount : NoData}</span>
          )}
        />
      </Table>
    </div>
  );
};

export default ViewPurchaseOrderTable;
