import React, { useEffect } from "react";
import { Form, Input, Col, Row, Radio, InputNumber, message } from "antd";
import RadioButton from "./RadioButton";
import TenderInfo from "./TenderInfo";
import { Select } from "antd";
import { getAll, getProjectTypes, getProjectStatus } from "./AddProjectSlice";
import { useSelector, useDispatch } from "react-redux";

export function FirstStep(props) {
  const dispatch = useDispatch();
  const departmentData = useSelector(
    (state) => state.departmentList.departmentRecords
  );
  const projectTypeData = useSelector(
    (state) => state.projectTypeList.projectTypeRecords
  );

  const projectStatusList = useSelector(
    (state) => state.projectTypeList.projectStatusList
  );

  useEffect(() => {
    dispatch(getAll());
    dispatch(getProjectTypes());
    dispatch(getProjectStatus());
  }, [dispatch]);

  const { Option } = Select;

  let tenderFilds =
    props.tenderState === true ? (
      <TenderInfo
        tenderfiles={props.tenderfiles}
        setTenderFiles={props.setTenderFiles}
      />
    ) : (
      <div> </div>
    );
  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={5} xs={24} md={8} lg={8}>
          <Form.Item
            label="Project Name"
            name="projectName"
            rules={[
              { required: true, message: "Please input project name" },
              { max: 1000 },
            ]}
          >
            <Input name="projectName" placeholder="Project Name" />
          </Form.Item>
        </Col>
        <Col span={5} xs={24} md={8} lg={8}>
          <Form.Item
            label="Short Name"
            name="shortTitle"
            rules={[
              { required: true, message: "Please input project short name" },
              { max: 100 },
            ]}
          >
            <Input name="shortTitle" placeholder="Short Name" />
          </Form.Item>
        </Col>
        <Col span={10} xs={24} md={8} lg={8}>
          <Form.Item
            label="createdDateTime"
            name="createdDateTime"
            hidden="true"
          >
            <Input name="createdDateTime" placeholder="createdDateTime" />
          </Form.Item>
          <Form.Item
            label="Department"
            name="departmentID"
            rules={[{ required: true, message: "Please select depatment" }]}
          >
            <Select
              showSearch
              name="department"
              style={{ width: "100%" }}
              placeholder="Select Department"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {departmentData.map((d) => (
                <Option key={Number(d.id)} value={Number(d.id)}>
                  {d.departmentName}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={10} xs={24} md={8} lg={8}>
          <Form.Item
            label="Project Type"
            name="projectTypeID"
            rules={[{ required: true, message: "Please select project type" }]}
          >
            <Select
              showSearch
              name="projectType"
              placeholder="Select Project Type"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {projectTypeData.map((d) => (
                <Option key={Number(d.id)} value={Number(d.id)}>
                  {d.projecttype}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5} xs={24} md={8} lg={8}>
          <Form.Item
            label="Project Value"
            name="projectValue"
            rules={[{ required: true, message: "Please input project value" }]}
          >
            <InputNumber
              min={0}
              max={1000000000}
              className="input-number"
              name="projectValue"
              placeholder="Project Value"
            />
          </Form.Item>
        </Col>
        <Col span={5} xs={24} md={8} lg={8}>
          <Form.Item
            label="Budget"
            name="budget"
            rules={[
              {
                required: true,
                min: 1,
                type: "number",
                message: "Please input valid budget value",
              },
            ]}
          >
            <InputNumber
              //min={0}
              max={1000000000}
              name="budget"
              placeholder="Budget"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={10} xs={24} md={8} lg={8}>
          <Form.Item
            label="Project Status"
            name="statusID"
            rules={[
              { required: true, message: "Please select project status" },
            ]}
          >
            <Select
              showSearch
              name="statusID"
              placeholder="Select Project Status"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {projectStatusList.map((d) => (
                <Option key={Number(d.id)} value={Number(d.id)}>
                  {d.status}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="isJointVenture"
        label="Joint Venture"
        rules={[{ required: true, message: "Please select joint venture" }]}
      >
        <Radio.Group onChange={props.displayJV}>
          <Radio name={1} value={true}>
            Yes
          </Radio>
          <Radio name={2} value={false}>
            No
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="isTender"
        label="Tender"
        rules={[{ required: true, message: "Please select tender" }]}
      >
        <Radio.Group onChange={props.maintainTenderState}>
          <Radio name={3} value={true}>
            Yes
          </Radio>
          <Radio name={4} value={false}>
            No
          </Radio>
        </Radio.Group>
      </Form.Item>
      {tenderFilds}
    </div>
  );
}

export default FirstStep;
