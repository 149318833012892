import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  saveExpenseForm,
  getVendorShortAsync,
  getProjectShortAsync,
  AddInvoiceAttachment,
  getExpenseByIdAsync,
} from "./AddExpenseSlice";
import { GetMasterLookup, GetPOLookup } from "../../Lookup/LookupSlice";

import {
  Row,
  Input,
  Col,
  Breadcrumb,
  Button,
  Form,
  Radio,
  Card,
  Switch,
  Upload,
  InputNumber,
  Select,
  message,
} from "antd";
import { Link, useParams } from "react-router-dom";
import { EyeOutlined, InboxOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { useHistory } from "react-router-dom";
const { Option } = Select;
const { Title } = Typography;

const { Dragger } = Upload;

export function AddExpense(props) {
  const history = useHistory();
  const [radiovalue, setradiovalue] = useState({
    value: "Project",
  });
  const [initialValues, setInitialValues] = useState({});
  const { id } = useParams();
  const [form] = Form.useForm();
  const [invoiceswitchvalue, setinvoiceswitchvalue] = useState(false);
  const [isTypeBoth, setIsTypeBoth] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const dispatch = useDispatch();
  const vendorData = useSelector((state) => state.expenses.vendorRecords);
  // const [filteredProjects, setFilteredProjects] = useState([]);
  const [search, setSearch] = useState("");
  const [files, setFiles] = useState([]);
  const [availableBudget, setavailableBudget] = useState(0);
  const projectShortData = useSelector(
    (state) => state.expenses.projectShortRecords
  );
  const selectedExpenseRecord = useSelector((state) => state.expenses);
  const poLookup = useSelector((state) => state.Lookup.poLookup);
  const typeLookup = useSelector((state) => state.Lookup.masterLookup);
  useEffect(() => {
    dispatch(getVendorShortAsync());
    dispatch(GetPOLookup());
    dispatch(GetMasterLookup());
    dispatch(getProjectShortAsync());
  }, [dispatch]);
  useEffect(() => {
    form.resetFields();
    setavailableBudget(0);
    if (id !== undefined && id > 0) {
      dispatch(getExpenseByIdAsync(id));
    } else {
      setInitialValues();
    }
  }, [id]);

  useEffect(() => {
    setInitialValues(selectedExpenseRecord?.selectedExpenseRecord);
  }, [selectedExpenseRecord]);
  useEffect(() => {
    form.resetFields();
    if (initialValues) {
      if (initialValues?.purchaseOrderId && initialValues.purchaseOrderId > 0) {
        onPOChange(initialValues?.purchaseOrderId);
        const val = poLookup.find(
          (a) => a.id === initialValues?.purchaseOrderId
        );
        if (val) {
          setavailableBudget(val?.availableBudget + initialValues?.amount);
        }
        setradiovalue({
          value: "Project",
        });
      } else if (initialValues && initialValues.purchaseOrderId == null) {
        setradiovalue({
          value: "Other Expenses",
        });
      }
    } else {
      setradiovalue({
        value: "Project",
      });
    }
  }, [initialValues]);
  // useEffect(() => {
  //   setFilteredProjects(
  //     projectShortData.filter((project) =>
  //       project.id.toString().toLowerCase().includes(search.toLowerCase())
  //     )
  //   );
  // }, [search, projectShortData]);
  const onFinish = (values) => {
    const data = {
      ...values,
      filesList: files,
      //ProjectId: projectId,
    };
    // if (radiovalue.value === "Other Expenses") {
    //   data.ProjectId = values.ProjectId;
    // }
    dispatch(saveExpenseForm(data, form)).then((res) => {
      //setProjectId(null);
      history.push("viewexpenses");
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // ---------------Radio Functionality-----------
  const onRadioChange = (radioValue) => {
    console.log("radio Checked", radioValue.target.value);

    setradiovalue({
      value: radioValue.target.value,
    });
    form.setFieldsValue({
      purchaseOrderId: null,
      projectId: null,
      typeId: null,
    });
    form.setFieldsValue({
      vendorId: null,
    });
  };
  const handleUploadDone = (file) => {
    return file.onSuccess();
  };
  const handleDelete = (file) => {};

  const handleDownload = (file) => {};

  const handleUpload = (file) => {
    setFiles([...files, file]);

    dispatch(AddInvoiceAttachment(file));
  };
  const onPOChange = (e) => {
    const val = poLookup.find((a) => a.id === e);
    form.setFieldsValue({
      vendorId: val.vendorId,
      typeId: val.typeId,
      projectId: val.projectId,
    });
    //setProjectId(val.projectId);
    setavailableBudget(val.availableBudget);
  };
  const onTypeChange = (e) => {
    if (e === 3) {
      setIsTypeBoth(true);
    } else {
      setIsTypeBoth(false);
    }
  };
  console.log("files", files);
  return (
    <>
      <Row>
        <Title level={4}>Add Expenses</Title>
      </Row>

      <Row>
        <Col span={20}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="">Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="#">Expenses</Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item>Add Expenses</Breadcrumb.Item>
          </Breadcrumb>
        </Col>

        <Col span={4} className="text-right">
          <Button type="primary">
            <EyeOutlined />
            &nbsp;
            <Link to="/expense/viewexpenses" style={{ color: "white" }}>
              View Expenses
            </Link>
          </Button>
        </Col>
      </Row>
      {/* <Input
        type="text"
        placeholder="Search"
        onChange={(e) => setSearch(e.target.value)}
      /> */}
      <Row>
        <Card span={24} style={{ marginTop: 20, width: "100%" }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Radio.Group
              name="radiogroup"
              defaultValue="Project"
              value={radiovalue.value}
              onChange={onRadioChange}
            >
              <Radio value="Project">Project</Radio>
              {/* <Radio value="Tender">Tender</Radio> */}
              <Radio value="Other Expenses">Other Expenses</Radio>
            </Radio.Group>
          </Row>

          <Form
            name="nest-message"
            layout="vertical"
            form={form}
            style={{ marginTop: 20 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={id !== undefined && id > 0 ? initialValues : {}}
          >
            <Col className="gutter-row" xs={24} md={12} lg={8}>
              <Form.Item label="id" name="id" hidden={true}>
                <Input placeholder="id" />
              </Form.Item>
            </Col>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              {/* Row 1 col 1 */}
              <Col className="gutter-row" xs={24} md={12} lg={8}>
                {radiovalue.value === "Project" ? (
                  <Form.Item
                    label="Purchase Order"
                    name="purchaseOrderId"
                    rules={[
                      {
                        required: true,
                        message: "Please select project details",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select a PO"
                      optionFilterProp="children"
                      onChange={onPOChange}
                      filterOption={(input, option) =>
                        option.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toString().toLowerCase()) >= 0 ||
                        option.value
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toString().toLowerCase()) >= 0
                      }
                    >
                      {poLookup.map((shortdata) => (
                        <Option key={shortdata.id} value={shortdata.id}>
                          {" "}
                          {shortdata.name}{" "}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                ) : null}

                {/* {radiovalue.value === "Tender" ? (
                  <Form.Item
                    label="Tender"
                    name="TenderID"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Tender Details!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Tender Id" />
                  </Form.Item>
                ) : null} */}

                {radiovalue.value === "Other Expenses" ? (
                  <Form.Item
                    label="Other Expenses"
                    name="otherExpenses"
                    rules={[
                      {
                        required: true,
                        message: "Please enter other expenses",
                      },
                    ]}
                  >
                    <Input placeholder="Other Expenses" />
                  </Form.Item>
                ) : null}
              </Col>

              {/* Row 1 col 2 */}
              <Col className="gutter-row" xs={24} md={12} lg={8}>
                <Form.Item
                  label="Reason for Expenses"
                  name="expenseReason"
                  rules={[
                    {
                      required: true,
                      message: "Please enter expenses reason",
                    },
                  ]}
                >
                  <Input placeholder="Reason for Expenses" />
                </Form.Item>
              </Col>

              {/* Row 1 col 3 */}

              {/* {radiovalue.value === "Other Expenses" && ( */}
              <Col className="gutter-row" xs={24} md={12} lg={8}>
                <Form.Item
                  label="Project"
                  name="projectId"
                  rules={[
                    {
                      required: true,
                      message: "Please select project",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select a Project"
                    optionFilterProp="children"
                    disabled={radiovalue.value === "Project" ? true : false}
                    filterOption={(input, option) =>
                      option.children
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toString().toLowerCase()) >= 0 ||
                      option.value
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toString().toLowerCase()) >= 0
                    }
                  >
                    {projectShortData.map((shortdata) => (
                      <Option key={shortdata.id} value={shortdata.id}>
                        {" "}
                        {shortdata.name}{" "}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {/* )} */}
            </Row>

            {/* ====================================================================== */}

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              {radiovalue.value === "Other Expenses" && (
                <Col className="gutter-row" xs={24} md={12} lg={8} span={8}>
                  <Form.Item label="Other Person" name="otherPerson">
                    <Input placeholder="Other Person" />
                  </Form.Item>
                </Col>
              )}

              {radiovalue.value === "Project" && (
                <Col className="gutter-row" xs={24} md={12} lg={8}>
                  <Form.Item
                    label="Amount"
                    name="amount"
                    rules={[
                      {
                        required: true,
                        message: "Please enter amount",
                      },
                      {
                        type: "number",
                        max: availableBudget,
                        message: "Amount can't exceed available budget",
                      },
                    ]}
                  >
                    <InputNumber min={1} step={0.1} placeholder="Amount" />
                  </Form.Item>
                  {availableBudget >= 0 && radiovalue.value === "Project" && (
                    <strong>Available budget : {availableBudget}</strong>
                  )}
                </Col>
              )}
              {radiovalue.value === "Other Expenses" && (
                <Col className="gutter-row" xs={24} md={12} lg={8}>
                  <Form.Item
                    label="Amount"
                    name="amount"
                    rules={[
                      {
                        required: true,
                        message: "Please enter amount",
                      },
                    ]}
                  >
                    <InputNumber min={0} step={0.1} placeholder="Amount" />
                  </Form.Item>
                </Col>
              )}
              {/* --------- Row 2 Col 3 -------------- */}
              {/*  */}
              {/* <Col className="gutter-row" xs={24} md={12} lg={8}>
                <Form.Item
                  label="GST"
                  name="GSTNO"
                  rules={[
                    {
                      required: true,
                      message: "Please input 15 digit GST Number",
                    },
                  ]}
                >
                  <InputNumber placeholder="GST" min={0} step={0.1} />
                </Form.Item>
              </Col> */}
              {radiovalue.value === "Project" && (
                <Col className="gutter-row" xs={24} md={12} lg={8}>
                  <Form.Item
                    label="Type"
                    name="typeId"
                    rules={[
                      {
                        required: true,
                        message: "Please enter vendor details",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select type"
                      optionFilterProp="children"
                      onChange={onTypeChange}
                      disabled={true}
                      filterOption={(input, option) =>
                        option.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {typeLookup.map((data) => (
                        <Option key={data.id} value={data.id}>
                          {data.name}{" "}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              {radiovalue.value === "Project" && isTypeBoth && (
                <>
                  {" "}
                  <Col className="gutter-row" xs={24} md={12} lg={8} span={8}>
                    <Form.Item
                      label="Material"
                      name="material"
                      rules={[
                        {
                          required: true,
                          message: "Please enter material details",
                        },
                      ]}
                    >
                      <Input placeholder="Material" />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" xs={24} md={12} lg={8} span={8}>
                    <Form.Item
                      label="Service"
                      name="service"
                      rules={[
                        {
                          required: true,
                          message: "Please enter service details",
                        },
                      ]}
                    >
                      <Input placeholder="Service" />
                    </Form.Item>
                  </Col>{" "}
                </>
              )}
              {radiovalue.value === "Project" && (
                <Col className="gutter-row" xs={24} md={12} lg={8}>
                  <Form.Item
                    label="Whom"
                    name="vendorId"
                    rules={[
                      {
                        required: true,
                        message: "Please enter vendor details",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select a person"
                      optionFilterProp="children"
                      disabled={radiovalue.value !== "Project" ? false : true}
                      filterOption={(input, option) =>
                        option.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {vendorData.map((data) => (
                        <Option key={data.id} value={data.id}>
                          {data.name}{" "}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
            </Row>
            {/* ============================================================ ============*/}

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" xs={24} md={12} lg={8} span={8}>
                <Form.Item
                  label="Vendor Invoice"
                  name="invoice"
                  // rules={[{ required: true, message: 'Please Enter invoice details!' }]}
                >
                  <Switch
                    // defaultChecked
                    onChange={() => setinvoiceswitchvalue(!invoiceswitchvalue)}
                  />
                </Form.Item>
                <Form.Item
                  name="CreatedDateTime"
                  value={Date()}
                  // rules={[{ required: true, message: 'Please Enter invoice details!' }]}
                ></Form.Item>
              </Col>

              {/* <Col className="gutter-row" xs={24} md={12} lg={8} span={8}>
                <Form.Item
                  label="TotalAmount"
                  name="TotalAmount"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Other Expenses!",
                    },
                  ]}
                >
                  <InputNumber min={0} step={0.1} />
                </Form.Item>
              </Col> */}
            </Row>

            {/* ================================================================ */}
            {/* Row 3 */}
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={24}>
                {invoiceswitchvalue ? (
                  <>
                    <Form.Item
                      label="Vendor Invoice"
                      name="invoiceAttachment"
                      // rules={[{ required: true, message: 'Please Enter invoice details!' }]}
                    >
                      <Dragger
                        onRemove={handleDelete}
                        onPreview={handleDownload}
                        beforeUpload={handleUpload}
                        //customRequest={handleUploadDone}
                        accept="image/*,application/pdf,application/pdf,application/msword,
                      application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                      application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                      text/plain,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,
                      application/csv,application/vnd.msexcel,application/excel,.xls,.xlsx,.msg,
                      .svg,.zip"
                      >
                        {" "}
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                          Click or drag file to this area to upload
                        </p>
                        <p className="ant-upload-hint">
                          Support for a single or bulk upload.
                        </p>
                      </Dragger>
                    </Form.Item>
                    {/* <Draggers
                      formName="Expense"
                      identifier="InvoiceAttachment"
                      {...props}
                    /> */}
                  </>
                ) : null}
              </Col>
            </Row>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Form.Item style={{ marginTop: 20 }}>
                <Button type="primary" htmlType="submit">
                  Send for Approval
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </Card>
      </Row>
    </>
  );
}
