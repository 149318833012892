import { createSlice } from "@reduxjs/toolkit";
import * as Settings from "../../../settings/Settings";
import axios from "axios";
import { notification, message } from "antd";

const apiurl = Settings.ApiEndPoint;

export const addCustomerSlice = createSlice({
  name: "addCustomer",
  initialState: {
    customerRecord: {},
  },
  reducers: {
    getCustomerRecord: (state, action) => {
      state.customerRecord = action.payload;
    },
    //   getVendorShortRecords: (state, action) => {
    //     state.vendorRecords = [];
    //     action.payload.forEach((item) => {
    //       state.vendorRecords.push(item);
    //     });
    //   },
  },
});

export const saveCustomerForm = (data, form) => (dispatch) => {
  const values = {
    ...data,
  };
  console.log(values);
  return axios
    .post(`${apiurl}api/Customer/AddCustomer`, values)
    .then((res) => {
      notification.success({
        placement: "topRight",
        message: "Customer saved succesfully..!",
      });
      form.resetFields();
    })
    .catch(() => message.error("something went wrong", [1.5]));
};

export const { getCustomerRecord } = addCustomerSlice.actions;

export const getCustomerById = (id) => (dispatch) => {
  axios.get(`${apiurl}api/Customer/GetCustomerById/` + id).then((res) => {
    console.log(res);
    dispatch(getCustomerRecord(res.data));
  });
};

export default addCustomerSlice.reducer;
