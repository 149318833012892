import React from "react";
import { Typography, Popconfirm, Checkbox, Card, Row, Col, Tabs, Button, Upload, message } from 'antd';
import {
  InboxOutlined,
  FileWordOutlined,
  FilePdfOutlined,
  FileJpgOutlined,
  FileImageOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import './viewproject.css';
const { TabPane } = Tabs;
const { Dragger } = Upload;
const { Paragraph } = Typography;
class Demo extends React.Component {
  constructor(props) {
    super(props);
    this.newTabIndex = 0;
    const panes = [
      { title: 'PO', content: 'Content of Tab Pane 1', key: '1' },
      { title: 'Invoices', content: 'Content of Tab Pane 2', key: '2' },
      { title: 'Expenses', content: 'Content of Tab Pane 2', key: '3' },
    ];
    this.state = {
      activeKey: panes[0].key,
      panes,
    };
  }

  onChange = activeKey => {
    this.setState({ activeKey });
  };

  onEdit = (targetKey, action) => {
    this[action](targetKey);
  };

  add = () => {
    const { panes } = this.state;
    const activeKey = `newTab${this.newTabIndex++}`;
    panes.push({ title: 'New Folder', content: 'New Tab Pane', key: activeKey });
    this.setState({ panes, activeKey });
  };

  remove = targetKey => {
    let { activeKey } = this.state;
    let lastIndex;
    this.state.panes.forEach((pane, i) => {
      if (pane.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const panes = this.state.panes.filter(pane => pane.key !== targetKey);
    if (panes.length && activeKey === targetKey) {
      if (lastIndex >= 0) {
        activeKey = panes[lastIndex].key;
      } else {
        activeKey = panes[0].key;
      }
    }
    this.setState({ panes, activeKey });
  };

  render() {
    return (
      <div>
        <div style={{ marginBottom: 16 }}>
          <Button onClick={this.add}>ADD</Button>
        </div>
        <Tabs
          hideAdd
          onChange={this.onChange}
          activeKey={this.state.activeKey}
          type="editable-card"
          tabPosition="left"
          onEdit={this.onEdit}
        >
          {this.state.panes.map(pane => (
            <TabPane tab={pane.title} key={pane.key} style={{ paddingTop: '20px' }}>
              <Row gutter={16}>
                <Col className="gutter-row" span={6}>
                  <div className="viewproject">
                    <Card style={{ width: '100%' }}>
                      <Checkbox></Checkbox>
                      <FileWordOutlined />
                      <Paragraph ellipsis title="djsndsndmsndjsdnsdnjsndjsdnsjdn.doc">
                        djsndsndmsndjsdnsdnjsndjsdnsjdn.doc
                      </Paragraph>
                      <em>12KB</em>
                      <Popconfirm
                        title="Are you sure delete this file?"
                        okText="Yes"
                        cancelText="No"
                      >
                        <a href="#"><DeleteOutlined /></a>
                      </Popconfirm>

                    </Card>
                  </div>
                </Col>
                <Col className="gutter-row" span={6}>
                  <div className="viewproject">
                    <Card style={{ width: '100%' }}>
                      <Checkbox></Checkbox>
                      <FilePdfOutlined />
                      <Paragraph ellipsis title="Resources.pdf">
                        Resources.pdf
                      </Paragraph>
                      <em>100KB</em>
                      <Popconfirm
                        title="Are you sure delete this file?"
                        okText="Yes"
                        cancelText="No"
                      >
                        <a href="#"><DeleteOutlined /></a>
                      </Popconfirm>
                    </Card>
                  </div>

                </Col>
                <Col className="gutter-row" span={6}>
                  <div className="viewproject">
                    <Card style={{ width: '100%' }}>
                      <Checkbox></Checkbox>
                      <FileJpgOutlined />
                      <Paragraph ellipsis title="preview.jpg">
                        preview.jpg
                      </Paragraph>

                      <em>40KB</em>
                      <Popconfirm
                        title="Are you sure delete this file?"
                        okText="Yes"
                        cancelText="No"
                      >
                        <a href="#"><DeleteOutlined /></a>
                      </Popconfirm>
                    </Card>
                  </div>

                </Col>
                <Col className="gutter-row" span={6}>
                  <div className="viewproject">
                    <Card style={{ width: '100%' }}>
                      <Checkbox></Checkbox>
                      <FileImageOutlined />
                      <Paragraph ellipsis title="preview.png">
                        preview.png
                      </Paragraph>

                      <em>90KB</em>
                      <Popconfirm
                        title="Are you sure delete this file?"
                        okText="Yes"
                        cancelText="No"
                      >
                        <a href="#"><DeleteOutlined /></a>
                      </Popconfirm>
                    </Card>
                  </div>

                </Col>
              </Row>
              
              <Row gutter={16}>
                <Col className="gutter-row" span={6}>
                  <div className="viewproject">
                    <Card style={{ width: '100%' }}>
                      <Checkbox></Checkbox>
                      <FileWordOutlined />
                      <Paragraph ellipsis title="djsndsndmsndjsdnsdnjsndjsdnsjdn.doc">
                        djsndsndmsndjsdnsdnjsndjsdnsjdn.doc
                      </Paragraph>
                      <em>12KB</em>
                      <Popconfirm
                        title="Are you sure delete this file?"
                        okText="Yes"
                        cancelText="No"
                      >
                        <a href="#"><DeleteOutlined /></a>
                      </Popconfirm>

                    </Card>
                  </div>
                </Col>
                <Col className="gutter-row" span={6}>
                  <div className="viewproject">
                    <Card style={{ width: '100%' }}>
                      <Checkbox></Checkbox>
                      <FilePdfOutlined />
                      <Paragraph ellipsis title="Resources.pdf">
                        Resources.pdf
                      </Paragraph>
                      <em>100KB</em>
                      <Popconfirm
                        title="Are you sure delete this file?"
                        okText="Yes"
                        cancelText="No"
                      >
                        <a href="#"><DeleteOutlined /></a>
                      </Popconfirm>
                    </Card>
                  </div>

                </Col>
                <Col className="gutter-row" span={6}>
                  <div className="viewproject">
                    <Card style={{ width: '100%' }}>
                      <Checkbox></Checkbox>
                      <FileJpgOutlined />
                      <Paragraph ellipsis title="preview.jpg">
                        preview.jpg
                      </Paragraph>

                      <em>40KB</em>
                      <Popconfirm
                        title="Are you sure delete this file?"
                        okText="Yes"
                        cancelText="No"
                      >
                        <a href="#"><DeleteOutlined /></a>
                      </Popconfirm>
                    </Card>
                  </div>

                </Col>
                <Col className="gutter-row" span={6}>
                  <div className="viewproject">
                    <Card style={{ width: '100%' }}>
                      <Checkbox></Checkbox>
                      <FileImageOutlined />
                      <Paragraph ellipsis title="preview.png">
                        preview.png
                      </Paragraph>

                      <em>90KB</em>
                      <Popconfirm
                        title="Are you sure delete this file?"
                        okText="Yes"
                        cancelText="No"
                      >
                        <a href="#"><DeleteOutlined /></a>
                      </Popconfirm>
                    </Card>
                  </div>

                </Col>
              </Row>
              
              <Row gutter={16}>
                <Col className="gutter-row" span={24}>
                  <Dragger {...props}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                      Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                      band files
    </p>
                  </Dragger>
                </Col>
              </Row>


            </TabPane>
          ))}
        </Tabs>
      </div>
    );
  }
}

const props = {
  name: 'file',
  multiple: true,
  action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  onChange(info) {
    const { status } = info.file;
    if (status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};


const DocumentTree = () => {
  return (
    <Demo />
  );
};

export default DocumentTree;
