import React from "react";
import { Button, Row, Breadcrumb } from "antd";
import { HomeOutlined, MailOutlined, EyeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

function Header() {
  const viewProject = () => {
    //action to be added
  };

  return (
    <Row>
      <h2 className="title gx-mb-4">
        Add Project
        <Breadcrumb>
          <Breadcrumb.Item href="/DashBoard">
            <HomeOutlined />
            <span>Home</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/project/viewprojects">
            <MailOutlined />
            <span>Projects</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Add Project</Breadcrumb.Item>
        </Breadcrumb>
      </h2>
      <Button
        justify="end"
        type="primary"
        icon={<EyeOutlined />}
        style={{ marginLeft: "auto" }}
        onClick={viewProject}
      >
        &nbsp;
        <Link to="/project/viewprojects" style={{ color: "white" }}>
          View Project
        </Link>
      </Button>
    </Row>
  );
}

export default Header;
