import { createSlice } from "@reduxjs/toolkit";
import * as Settings from "../../../settings/Settings";
import axios from "axios";
import { message } from "antd";

const apiurl = Settings.ApiEndPoint;

export const viewCustomerSlice = createSlice({
  name: "viewCustomer",
  initialState: {
    isRecordDeleted: false,
    customerRecords: [],
  },
  reducers: {
    getCustomerRecords: (state, action) => {
      state.customerRecords = [];
      action.payload.forEach((item) => {
        state.customerRecords.push(item);
      });
    },
  },
});

export const { getCustomerRecords } = viewCustomerSlice.actions;

export const getAllCustomerRecords = () => (dispatch) => {
  axios.get(`${apiurl}api/Customer/GetCustomers`).then((res) => {
    console.log(res);
    dispatch(getCustomerRecords(res.data));
  });
};

export const deleteCustomerRecord = (id) => (dispatch) => {
  axios.post(`${apiurl}api/Customer/RemoveCustomerById/${id}`).then((res) => {
    dispatch(getAllCustomerRecords());
  });
};

export default viewCustomerSlice.reducer;
