import React, { useState } from "react";
import { Table, Input, InputNumber, Form, Button, Space, Row, Select } from "antd";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
const { Option } = Select;

const invoiceBareData = (index) => {
  return {
    sNo: "",
    description: "",
    unitOfMeasure: "",
    quantity: "",
    gst: "",
    basicAmount: "",
    cgst: "",
    sgst: "",
    igst: "",
    totalAmount: "",
    key: index,
  };
};

const AddTable = (props) => {
  const [form] = Form.useForm();

  const [editingKey, setEditingKey] = useState("0");
  const [pageNumber, setPageNumber] = useState(1);
  const [id, setId] = useState(0);
  const [isIntra, setIntra] = useState(props.isIntra);
  const [gst, setGst] = useState(0);
  const [basicAmt, setBasicAmt] = useState(0);

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      sNo: "",
      description: "",
      unitOfMeasure: "",
      quantity: "",
      gst: "",
      basicAmount: "",
      cgst: "",
      sgst: "",
      igst: "",
      totalAmount: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
      inputType === "number" ? (
        <InputNumber
          name={dataIndex}
          onPressEnter={(e) => update(e, record)}
          onBlur={(e) => update(e, record)}
        />
      ) : (
        inputType === "select" ? <Select defaultValue="lucy" style={{ width: 90 }} >
          <Option value="BOX">BOX</Option>
          <Option value="CMS">CM</Option>
          <Option value="DOZ">DZ</Option>
          <Option value="FTS">FT</Option>
          <Option value="GMS">G</Option>
          <Option value="INC">IN</Option>
          <Option value="KGS">KG</Option>
          <Option value="KME">KM</Option>
          <Option value="LBS">LB</Option>
          <Option value="MGS">MG</Option>
          <Option value="MTR">M</Option>
          <Option value="PCS">PCS</Option>
          <Option value="CBM">CUBIC METER</Option>
          <Option value="BAG">BAGS</Option>
          <Option value="MW">MW</Option>
          <Option value="NOS">NOS</Option>
          <Option value="HOURS">HOURS</Option>
          <Option value="MT">MT</Option>
        </Select> :
          <Input name={dataIndex} />
      );
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const update = (e, values) => {
    const { name, value } = e.target;

    if (name === "gst" && value > 0) {
      setGst(Number(value));
    } else if (name === "basicAmount" && value > 0) {
      setBasicAmt(Number(value));
    }

    console.log("e:", name);
    console.log("values:", value);
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      row.key = editingKey;
      row.gst = gst;
      row.sgst = gst / 2;
      row.cgst = gst / 2;
      row.igst = gst;
      row.totalAmount = basicAmt + (basicAmt * gst) / 100;
      const newData = [...props.data];
      const index = newData.findIndex((item) => key === item.key);
      console.log(row);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        props.update(newData);
        //setData(newData);
        setEditingKey("");
        setGst(0);
        setBasicAmt(0);
      } else {
        newData.push(row);
        props.update(newData);
        //  setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const getPageNumber = (pageIndex) => {
    setPageNumber(pageIndex);

    // const newData = [...data];

    // setData(newData);
  };

  const addInvoice = async () => {
    try {
      const rec = await form.validateFields();
      console.log("In add voice");
      const newData = [...props.data];
      let row = invoiceBareData(id + 1);
      setId(id + 1);
      newData.push(row);
      // setData(newData);
      props.update(newData);
      setEditingKey(props.data.length + 1);
      edit(row);
      console.log(newData);
    } catch (errInfo) {
      alert("Save current  invoice before proceeing");

      console.log("Validate Failed:", errInfo);
    }
  };

  const deleteRow = (record) => {
    const newData = [...props.data];
    const index = newData.indexOf(record);
    newData.splice(index, 1);
    // let pgNo = newData.length%10;
    // console.log(pgNo);
    // setPageNumber(pgNo+1)
    //setData(newData);
    props.update(newData);
  };

  const fixedColumns = [
    {
      title: "S.No",
      dataIndex: "index",
      key: "index",
      width: "1%",
      editable: false,
      render: (value, item, index) => (pageNumber - 1) * 10 + index + 1,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "30%",
      editable: true,
    },
    {
      title: "UOM",
      dataIndex: "unitOfMeasure",
      key: "unitOfMeasure",
      width: "10%",
      editable: true,

    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: "10%",
      editable: true,
    },
    {
      title: "GST%",
      dataIndex: "gst",
      key: "gst",
      width: "10%",
      editable: true,
    },
    {
      title: "Basic Amount",
      dataIndex: "basicAmount",
      key: "basicAmount",
      width: "20%",
      editable: true,
    },
  ];

  const intraStateGSTColumns = [
    {
      title: "CGST%",
      dataIndex: "cgst",
      key: "cgst",
      width: "5%",
      editable: false,
      render: (value, record) => (record.key === editingKey ? gst / 2 : value),
    },
    {
      title: "SGST%",
      dataIndex: "sgst",
      key: "sgst",
      width: "5%",
      editable: false,
      render: (value, record) => (record.key === editingKey ? gst / 2 : value),
    },
  ];

  const interStateGSTColumns = [
    {
      title: "IGST%",
      dataIndex: "igst",
      key: "igst",
      width: "5%",
      editable: false,
      render: (value, record) => (record.key === editingKey ? gst : value),
    },
  ];

  const endColumns = [
    {
      title: "Total",
      dataIndex: "totalAmount",
      key: "totalAmount",
      width: "15%",
      editable: false,
      render: (value, record) =>
        record.key === editingKey ? (basicAmt * gst) / 100 + basicAmt : value,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: "10%",
      editable: false,
      render: (text, record) => (
        <Space size="small">
          <Button
            danger
            icon={<DeleteOutlined />}
            size="small"
            onClick={() => deleteRow(record)}
          ></Button>
          <Button
            style={{ color: "green" }}
            size="small"
            onClick={() => save(record.key)}
          >
            S
          </Button>
        </Space>
      ),
    },
  ];
  if (props.poId !== null) {
    delete endColumns[1];
  }
  const columns = isIntra
    ? fixedColumns.concat(intraStateGSTColumns.concat(endColumns))
    : fixedColumns.concat(interStateGSTColumns.concat(endColumns));

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType:
          col.dataIndex === "quantity" ||
            col.dataIndex === "gst" ||
            col.dataIndex === "basicAmount"
            ? "number"
            : col.dataIndex === "unitOfMeasure"
              ? "select"
              : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <>
      <div className="custom-icons-list">
        {props.poId == null && (
          <Row justify="end">
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              style={{ marginLeft: "auto" }}
              onClick={addInvoice}
            >
              Add
            </Button>
          </Row>
        )}

        <Space>&nbsp;</Space>
      </div>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={props.data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: getPageNumber,
          }}
        />
      </Form>
    </>
  );
};

export default AddTable;

// const saveData= (event, record) => {
//     // const { name, value } = event.target;
//     // console.log('Record:', record );
//     // const newData = [...data];
//     // const index = newData.indexOf(record)
//     // let item = newData[index]
//     // record[name] = value;
//     // newData.splice(index, 1, { ...item, ...record });

//     // setData(newData);
//     // console.log('name:', name);
//     // console.log('value:', value );

//     // console.log('Record1:', newData );

//   }
