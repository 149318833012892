import { createSlice } from "@reduxjs/toolkit";
import * as Settings from "../../settings/Settings";
import axios from "axios";
import { message } from "antd";

const apiurl = Settings.ApiEndPoint;

export const LookupSlice = createSlice({
  name: "approvals",
  initialState: {
    masterLookup: [],
    poLookup: [],
  },
  reducers: {
    setMasterLookUp: (state, action) => {
      state.masterLookup = [];
      action.payload.forEach((item) => {
        state.masterLookup.push(item);
      });
    },

    setPOLookup: (state, action) => {
      state.poLookup = [];
      action.payload.forEach((item) => {
        state.poLookup.push(item);
      });
    },
  },
});

export const { setMasterLookUp, setPOLookup } = LookupSlice.actions;

export const GetMasterLookup = () => (dispatch) => {
  axios.get(`${apiurl}api/LookUp/GetMasterLookup`).then((res) => {
    dispatch(setMasterLookUp(res.data));
  });
};

export const GetPOLookup = () => (dispatch) => {
  axios.get(`${apiurl}api/LookUp/GetPOLookup`).then((res) => {
    dispatch(setPOLookup(res.data));
  });
};

export default LookupSlice.reducer;
