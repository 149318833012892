import React from "react";

const DashBoard = (props) => {
  return (
    <>
      <h3>Welcome to DashBoard...!</h3>
    </>
  );
};

export default DashBoard;
