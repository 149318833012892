import { createSlice } from "@reduxjs/toolkit";
import * as Settings from "../../../settings/Settings";
import axios from "axios";

const baseUrl = Settings.ApiEndPoint;

export const ViewProjectSlice = createSlice({
  name: "projects",
  initialState: {
    isRecordDeleted: false,
    projectRecords: [],
  },
  reducers: {
    getAllProjectRecords: (state, action) => {
      state.projectRecords = [];
      action.payload.forEach((item) => {
        state.projectRecords.push(item);
      });
    },

    deleteRecord: (state, action) => {},
  },
});

export const { getAllProjectRecords } = ViewProjectSlice.actions;

export const getAll = () => (dispatch) => {
  return axios.get(`${baseUrl}api/Project/GetAllProjects`).then((res) => {
    dispatch(getAllProjectRecords(res.data));
  });
};

export const { deleteRecord } = ViewProjectSlice.actions;

export const deleteProjectRecord = (projectId) => (dispatch) => {
  axios
    .post(`${baseUrl}api/Project/DeleteProjectById/${projectId}`)
    .then((res) => {
      dispatch(getAll());
    });
};

export default ViewProjectSlice.reducer;
