import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getFullProjectAsync } from "../viewProject/ViewProjectSlice";
import {
  CheckCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  ClockCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import {
  Table,
  Progress,
  Tag,
  Space,
  Button,
  Breadcrumb,
  Row,
  Col,
  Popconfirm,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  HomeOutlined,
  MailOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { getAll, deleteProjectRecord } from "./ViewProjectsSlice";
import {
  GetAllDepartments,
  getProjectTypes,
  getProjectStatus,
} from "../addProject/AddProjectSlice";
import AddProject from "../addProject/AddProject";
import {
  isSuperAdmin,
  isAdmin,
} from "../../../Login../../../src/features/Authentication/Login/LoginSlice";

export function ViewProjects() {
  const history = useHistory();
  const dispatch = useDispatch();
  const projectRecords = useSelector((state) => state.projectList);
  const [records, modifyRecords] = useState(projectRecords?.projectRecords);
  const departmentData = useSelector(
    (state) => state.departmentList.departmentRecords
  );
  const projectTypeData = useSelector(
    (state) => state.projectTypeList.projectTypeRecords
  );
  const projectStatusList = useSelector(
    (state) => state.projectTypeList.projectStatusList
  );

  const [project, setProject] = useState({
    isEditing: false,
    id: -1,
  });
  const [departments, setDepartments] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);
  const [statusList, setStatusList] = useState([]);

  useEffect(() => {
    dispatch(getAll());
    dispatch(GetAllDepartments());
    dispatch(getProjectTypes());
    dispatch(getProjectStatus());
  }, [dispatch]);

  useEffect(() => {
    if (departmentData) {
      var depts = departmentData.map((v) => {
        return {
          text: v.departmentName,
          value: v.departmentName,
        };
      });

      setDepartments(depts);
    } else {
      setDepartments([]);
    }

    if (projectTypeData) {
      var types = projectTypeData.map((v) => {
        return {
          text: v.projecttype,
          value: v.projecttype,
        };
      });
      setProjectTypes(types);
    }
    if (projectStatusList) {
      var status = projectStatusList.map((v) => {
        return {
          text: v.status,
          value: v.status,
        };
      });
      setStatusList(status);
    }
  }, [departmentData, projectTypeData, projectStatusList]);
  console.log("departments", departmentData);

  useEffect(() => {
    modifyRecords(projectRecords?.projectRecords);
  }, [projectRecords]);

  const { Column } = Table;

  const deleteProject = (e) => {
    dispatch(deleteProjectRecord(e.id));
  };

  const editProject = (e) => {
    setProject({
      ...project,
      isEditing: true,
      id: e,
    });
  };
  console.log("records", records);
  const [page, setPage] = React.useState(1);
  if (!project.isEditing) {
    return (
      <>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <h1>View All Projects</h1>
            <h2 className="title gx-mb-4">
              <Breadcrumb>
                <Breadcrumb.Item href="/DashBoard">
                  <HomeOutlined />
                  <span>Home</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href="/project/viewprojects">
                  <MailOutlined />
                  <span>Projects</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>View All Projects</Breadcrumb.Item>
              </Breadcrumb>
            </h2>
            <div class="mt-30">
              <Table
                dataSource={records}
                size="middle"
                bordered
                pagination={{
                  onChange(current) {
                    setPage(current);
                  },
                }}
              >
                <Column
                  title="Id"
                  dataIndex="id"
                  key="id"
                  render={(value, item, index) => (page - 1) * 10 + index + 1}
                  sorter={(a, b) => a.id - b.id}
                />

                <Column
                  title="Project"
                  dataIndex="projectName"
                  key="projectName"
                  sorter={(a, b) => a.projectName.length - b.projectName.length}
                />
                <Column
                  title="Short Title"
                  dataIndex="shortTitle"
                  key="shortTitle"
                />
                <Column
                  title="Department"
                  dataIndex="departmentName"
                  key="departmentName"
                  filters={departments && departments}
                  sorter={(a, b) =>
                    a.departmentName.length - b.departmentName.length
                  }
                  onFilter={(value, record) =>
                    record.departmentName.indexOf(value) === 0
                  }
                />
                <Column
                  title="ProjectType"
                  dataIndex="projectType"
                  key="projectType"
                  sorter={(a, b) => a.projectType.length - b.projectType.length}
                  filters={projectTypes && projectTypes}
                  onFilter={(value, record) =>
                    record.projectType.indexOf(value) === 0
                  }
                />
                <Column
                  title="Expenses"
                  key="expensesPercent"
                  render={(text, record) => (
                    <Progress percent={record.expensesPercent} />
                  )}
                  sorter={(a, b) => a.expensesPercent - b.expensesPercent}
                />
                <Column
                  title="Budget"
                  key="budget"
                  dataIndex="budget"
                  sorter={(a, b) => a.budget - b.budget}
                />
                <Column
                  title="Started"
                  dataIndex="daysStartedAgo"
                  key="daysStartedAgo"
                  render={(daysStartedAgo) => <Space>{daysStartedAgo}</Space>}
                />
                <Column
                  title="Status"
                  dataIndex="projectStatus"
                  key="projectStatus"
                  filters={statusList && statusList}
                  // sorter={(a, b) =>
                  //   a.projectStatus.length - b.projectStatus.length
                  // }
                  // onFilter={(value, record) =>
                  //   record.projectStatus.indexOf(value) === 0
                  // }
                  render={(projectStatus) => (
                    <>
                      {projectStatus === "Ongoing" && (
                        <Tag
                          icon={<SyncOutlined spin />}
                          color="processing"
                          key={projectStatus}
                        >
                          {projectStatus}
                        </Tag>
                      )}
                      {projectStatus === "Completed" && (
                        <Tag
                          icon={<CheckCircleOutlined />}
                          key={projectStatus}
                          color="success"
                        >
                          {projectStatus}
                        </Tag>
                      )}
                    </>
                  )}
                />
                <Column
                  title="Action"
                  key="action"
                  render={(text, record) => (
                    <Space size="small">
                      <Button
                        icon={<EditOutlined />}
                        size="small"
                        onClick={() => editProject(record.id)}
                      ></Button>
                      {(isSuperAdmin() || isAdmin()) && (
                        <>
                          {record?.deleteBtnEnabled === true ? (
                            <Popconfirm
                              title="Sure to delete?"
                              onConfirm={() => deleteProject(record)}
                            >
                              <Button
                                danger
                                icon={<DeleteOutlined />}
                                size="small"
                              ></Button>
                            </Popconfirm>
                          ) : (
                            <Button
                              danger
                              icon={<DeleteOutlined />}
                              size="small"
                              title="Delete is not allowed, as we have POs associated to Project"
                              disabled={true}
                            ></Button>
                          )}
                        </>
                        // <Button
                        //   danger
                        //   icon={<DeleteOutlined />}
                        //   size="small"
                        //   onClick={() => deleteProject(record)}
                        // ></Button>
                      )}
                      <Button
                        icon={<EyeTwoTone />}
                        size="small"
                        onClick={() => {
                          dispatch(getFullProjectAsync(record.id));
                          history.push("/project/viewproject");
                        }}
                      />
                    </Space>
                  )}
                />
              </Table>
            </div>
          </Col>
        </Row>
      </>
    );
  } else {
    return (
      <div>
        <AddProject isInEditMode={project.isEditing} id={project.id} />
      </div>
    );
  }
}
