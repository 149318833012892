import { createSlice } from "@reduxjs/toolkit";
import * as Settings from "../../../settings/Settings";
import axios from "axios";

const baseUrl = Settings.ApiEndPoint;

export const AddInvoiceSlice = createSlice({
  name: "Add Project",
  initialState: {
    projects: [],
    invoiceRecords: [],
  },
  reducers: {
    getProjectRecords: (state, action) => {
      state.projects = [];
      action.payload.forEach((item) => {
        state.projects.push(item);
      });
    },

    getInvoiceRecords: (state, action) => {
      state.invoiceRecords = [];
      action.payload.forEach((item) => {
        state.invoiceRecords.push(item);
      });
    },
  },

});


export const { getProjectRecords } = AddInvoiceSlice.actions;

export const getAll = () => (dispatch) => {
  axios.get(`${baseUrl}api/LookUp/GetProjectLookup`).then((res) => {
    dispatch(getProjectRecords(res.data));
  });
};

export const addInvoice = (data) => {
  return (dispatch) => {
    return axios.post(`${baseUrl}api/Invoice/SaveInvoice`, data);
  };
};

export const { getInvoiceRecords } = AddInvoiceSlice.actions;

export const getAllInvoices = () => (dispatch) => {
  axios.get(`${baseUrl}api/Invoice/GetInvoiceShort`).then((res) => {
    dispatch(getInvoiceRecords(res.data));
    console.log(res.data)
  });
};

export const { deleteRecord } = AddInvoiceSlice.actions;

export const deleteInvoiceRecord = (projectId) => (dispatch) => {
  axios
    .post(`${baseUrl}api/Invoice/DeleteInvoiceById/${projectId}`)
    .then((res) => {
      dispatch(getAllInvoices());
    });
};
export default AddInvoiceSlice.reducer;
