import React, { useState, useEffect } from "react";
import ViewTable from "../../commonComponents/ViewTable";
import {
  Form,
  Button,
  Row,
  Col,
  Card,
  Breadcrumb,
  Select,
  Divider,
  Input,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  getAllInvoices,
  deleteInvoiceRecord,
} from "../addInvoice/AddInvoiceSlice";
import { useSelector, useDispatch } from "react-redux";

export function ViewInvoices() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.addInvoice.invoiceRecords);

  useEffect(() => {
    dispatch(getAllInvoices());
  }, [dispatch]);

  const [form] = Form.useForm();

  const onSubmit = (values) => {
    console.log(values);
  };

  const deleteProject = (e) => {
    dispatch(deleteInvoiceRecord(e.invoiceId));
  };

  return (
    <>
      <Form
        layout="vertical"
        onFinish={onSubmit}
        form={form}
        initialValues={{
          remember: true,
        }}
      >
        <Row>
          <h2 className="title gx-mb-4">
            View Invoice
            <Breadcrumb>
              <Breadcrumb.Item href="/Dashboard">
                <span>HOME</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item href="/invoice/viewinvoices">
                <span>INVOICE</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item>VIEW INVOICE</Breadcrumb.Item>
            </Breadcrumb>
          </h2>
          <Button
            justify="end"
            type="primary"
            icon={<PlusOutlined />}
            style={{ marginLeft: "auto" }}
          >
            Add Invoice
          </Button>
        </Row>
        <Card style={{ width: "100%" }} bordered={true}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={10} xs={24} md={8} lg={8}>
              <Form.Item
                label="Financial Year"
                name="FinancialYear"
                rules={[
                  {
                    required: true,
                    message: "Please select your Project Type",
                  },
                ]}
              >
                <Select
                  showSearch
                  name="FinancialYear"
                  placeholder="Select Financial Year"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {/* {projectTypeData.map((d) => (
              <Option key={d.id}>{d.projecttype}</Option>
            ))} */}
                </Select>
              </Form.Item>
            </Col>
            <Col span={10} xs={24} md={8} lg={8}>
              <Form.Item
                label="Month"
                name="Month"
                rules={[
                  {
                    required: true,
                    message: "Please select your Project Type",
                  },
                ]}
              >
                <Select
                  showSearch
                  name="Month"
                  placeholder="Select Month"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {/* {projectTypeData.map((d) => (
              <Option key={d.id}>{d.projecttype}</Option>
            ))} */}
                </Select>
              </Form.Item>
            </Col>
            <Col span={10} xs={24} md={8} lg={8}>
              <Button
                type="primary"
                style={{ marginLeft: "auto", top: "30px" }}
              >
                Search
              </Button>
            </Col>
          </Row>
          <Divider />
          <div style={{ float: "right" }}>
            <Col span={24} xs={48} md={48} lg={48}>
              <Form.Item>
                <Input name="search" placeholder="Search Here" />
              </Form.Item>
            </Col>
          </div>
          <ViewTable datasource={data} delete={deleteProject} />
        </Card>
      </Form>
    </>
  );
}
