import React from "react";
import { Button, Breadcrumb, Row } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import DataFetching from "./DataFetching";

export function AddInvoice() {
  return (
    <div>
      <Row>
        <h2 className="title gx-mb-4">
          Add Invoice
          <Breadcrumb>
            <Breadcrumb.Item href="/DashBoard">
              <span>HOME</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/invoice/viewinvoices">
              <span>INVOICE</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>ADD INVOICE</Breadcrumb.Item>
          </Breadcrumb>
        </h2>
        <Button
          justify="end"
          type="primary"
          icon={<EyeOutlined />}
          style={{ marginLeft: "auto" }}
        >
          View Invoice
        </Button>
      </Row>
      <DataFetching />
    </div>
  );
}
