import { createSlice } from "@reduxjs/toolkit";
import * as Settings from "../../../settings/Settings";
import axios from "axios";

const apiurl = Settings.ApiEndPoint;

export const vendorSlice = createSlice({
  name: "Vendor",
  initialState: {
    VendorRecords: [],
    record: {},
  },
  reducers: {
    getVendorById: (state, action) => {
      state.record = action.payload;
    },
  },
});
export const { getVendorById } = vendorSlice.actions;

export const SaveForm = (data) => {
  return (dispatch) => {
    return axios.post(`${apiurl}api/Vendors/SaveVendor`, data);
  };
};

export const updatedVendor = (data) => {
  return (dispatch) => {
    return axios.post(`${apiurl}api/Vendors/UpdateVendor`, data);
  };
};

export const getVendorDetailsById = (id) => (dispatch) => {
  return axios.get(`${apiurl}api/Vendors/GetVendorById/${id}`).then((res) => {
    dispatch(getVendorById(res.data));
    console.log(res);
  });
};

export const IsVendorVaild = (name) => (dispatch) => {
  return axios
    .get(`${apiurl}api/Vendors/IsVendorValid/${name}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};
export default vendorSlice.reducer;
