import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  decrement,
  increment,
  incrementByAmount,
  incrementAsync,
  selectCount,
  getAllAsync,
  SaveForm,
  // test
} from "./counterSlice";
import styles from "./Counter.module.css";
import { Table, Form, Input, Button, Steps, message } from "antd";
import FormItem from "antd/lib/form/FormItem";
import TableForm from "../commonComponents/TableForm";
import Login from "../Authentication/Login/Login";
const { Step } = Steps;
const { Column } = Table;
// const steps = [
//   {
//     title: "First",
//     content: "First-content",
//   },
//   {
//     title: "Second",
//     content: "Second-content",
//   },
//   {
//     title: "Last",
//     content: "Last-content",
//   },
// ];
export function Counter() {
  // const count = useSelector(selectCount);
  // const dispatch = useDispatch();
  // const data = useSelector((state) => state.counter.sampleRecords);
  // const [incrementAmount, setIncrementAmount] = useState("2");
  // const [current, setCurrentStep] = useState(0);
  // const [formData, setFormState] = useState({});
  // const next = () => {
  //   const c = current + 1;
  //   setCurrentStep(c);
  // };

  // const prev = () => {
  //   const c = current - 1;
  //   setCurrentStep(c);
  // };

  // useEffect(() => {
  //   dispatch(getAllAsync());
  // }, [dispatch]);

  // const onSubmit = (values) => {
  //
  //   console.log(values);
  //   dispatch(SaveForm(values));
  // };

  // const onSubmitStepsForm = (values) => {
  //
  //   const a = formData;
  // };

  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   setFormState((formData) => ({
  //     ...formData,
  //     [name]: value,
  //   }));
  // this.setState({
  //   [name]: value
  // })
  //};
  return (
    <>
      <TableForm />
      test
      {/* <Form name="basic" initialValues={{ remember: true }} onFinish={onSubmit}>
        <Form.Item
          label="id"
          name="id"
          rules={[{ required: true, message: "Please enter id" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="name"
          name="name"
          rules={[{ required: true, message: "Please enter name" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
      <Table dataSource={data}>
        <Column title="id" dataIndex="id" key="id" />
        <Column title="name" dataIndex="name" key="name" />
      </Table>
      <div>
        <div className={styles.row}>
          <button
            className={styles.button}
            aria-label="Increment value"
            onClick={() => dispatch(increment())}
          >
            +
          </button>
          <span className={styles.value}>{count}</span>
          <button
            className={styles.button}
            aria-label="Decrement value"
            onClick={() => dispatch(decrement())}
          >
            -
          </button>
        </div>
        <div className={styles.row}>
          <input
            className={styles.textbox}
            aria-label="Set increment amount"
            value={incrementAmount}
            onChange={(e) => setIncrementAmount(e.target.value)}
          />
          <button
            className={styles.button}
            onClick={() =>
              dispatch(incrementByAmount(Number(incrementAmount) || 0))
            }
          >
            Add Amount
          </button>
          <button
            className={styles.asyncButton}
            onClick={() =>
              dispatch(incrementAsync(Number(incrementAmount) || 0))
            }
          >
            Add Async
          </button>
        </div>
      </div>
      <Form onFinish={onSubmitStepsForm}> 
        <Steps current={current}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className="steps-content">
          {steps[current].content}
          {current === 0 && (
            <Form.Item
              label="id"
              name="id"
              rules={[{ required: true, message: "Please enter id" }]}
              onChange={handleChange}
            >
              <Input name="id" />
            </Form.Item>
          )}
          {current === 1 && (
            <Form.Item
              label="name"
              name="name"
              rules={[{ required: true, message: "Please enter name" }]}
              onChange={handleChange}
            >
              <Input name="name" />
            </Form.Item>
          )}
          {current === 2 && (
            <Form.Item
              label="email"
              name="email"
              rules={[{ required: true, message: "Please enter email" }]}
              onChange={handleChange}
            >
              <Input name="email" />
            </Form.Item>
          )}
        </div>
        <div className="steps-action">
          {current < steps.length - 1 && (
            <Button type="primary" onClick={() => next()}>
              Next
            </Button>
          )}
          {current === steps.length - 1 && (
            // <Button
            //   type="primary"
            //   onClick={() => message.success("Processing complete!")}
            // >
            //   Done
            // </Button>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          )}
          {current > 0 && (
            <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
              Previous
            </Button>
          )}
        </div>
      </Form>*/}
    </>
  );
}
