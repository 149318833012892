import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Input, Col, Breadcrumb, Button, Form, InputNumber } from "antd";
import { Link, useParams } from "react-router-dom";
import { EyeOutlined, HomeOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { useHistory } from "react-router-dom";
import { saveCustomerForm } from "./AddCustomerSlice";
import { getCustomerById } from "./AddCustomerSlice";
const { Title } = Typography;

export function AddCustomer(props) {
  const customerRecord = useSelector((state) => state.addCustomer);
  //const [record, modifyRecord] = useState();
  const [initialValues, setInitialValues] = useState({});
  const { id } = useParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const history = useHistory();
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const onFinish = (values) => {
    var redirectLink = "../customer/viewcustomer";
    if (values.customerID > 0) {
      redirectLink = "../viewcustomer";
    }
    const data = {
      ...values,
    };
    dispatch(saveCustomerForm(data, form)).then(() => {
      history.push(redirectLink);
    });
  };
  useEffect(() => {
    form.resetFields();
    if (id !== undefined && id > 0) {
      dispatch(getCustomerById(id));
    } else {
      setInitialValues();
    }
  }, [id]);
  useEffect(() => {
    setInitialValues(customerRecord?.customerRecord);
  }, [customerRecord]);
  useEffect(() => {
    form.resetFields();
  }, [initialValues]);
  return (
    <>
      <Row>
        <Title level={4}>Add Customer</Title>
      </Row>

      <Row>
        <Col span={20}>
          <h2 className="title gx-mb-4">
            <Breadcrumb>
              <Breadcrumb.Item href="/DashBoard">
                <HomeOutlined />
                <span>Home</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/customer/viewcustomer">Customers</Link>
              </Breadcrumb.Item>

              <Breadcrumb.Item>Add Customer</Breadcrumb.Item>
            </Breadcrumb>
          </h2>
        </Col>

        <Col span={4} className="text-right">
          <Button type="primary">
            {" "}
            <EyeOutlined />
            &nbsp;
            <Link to="/customer/viewcustomer" style={{ color: "white" }}>
              View Customers
            </Link>
          </Button>
        </Col>
      </Row>
      <div>
        <Form
          name="nest-message"
          layout="vertical"
          form={form}
          style={{ marginTop: 20 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={id !== undefined && id > 0 ? initialValues : {}}
        >
          <Row gutter={[16, 16]}>
            <Form.Item label="customerID" name="customerID" hidden={true}>
              <InputNumber name="customerID" placeholder="Number" />
            </Form.Item>
            <Col span={5} xs={24} md={8} lg={8}>
              <Form.Item
                label="Customer Name"
                name="customerName"
                rules={[
                  { required: true, message: "Please input customer name" },
                  { max: 255 },
                ]}
                onChange={props.handleChange}
              >
                <Input name="customerName" placeholder="Customer Name" />
              </Form.Item>
            </Col>
            <Col span={5} xs={24} md={8} lg={8}>
              <Form.Item
                label="Number"
                name="contactNumber"
                rules={[
                  { required: true, message: "Please input contact number" },
                ]}
                onChange={props.handleChange}
              >
                <InputNumber name="contactNumber" placeholder="Number" />
              </Form.Item>
            </Col>
            <Col span={5} xs={24} md={8} lg={8}>
              <Form.Item
                label="Alternate Number"
                name="alternativeNumber"
                // rules={[
                //   { required: true, message: "Please input Alternate Number" },
                // ]}
                onChange={props.handleChange}
              >
                <InputNumber
                  name="alternativeNumber"
                  placeholder="Alternate Number"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={5} xs={24} md={8} lg={8}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail",
                  },
                  {
                    required: true,
                    message: "Please input your e-mail",
                  },
                  { max: 50 },
                ]}
                onChange={props.handleChange}
              >
                <Input name="email" placeholder="Email" />
              </Form.Item>
            </Col>
            <Col span={5} xs={24} md={8} lg={8}>
              <Form.Item
                label="Address"
                name="address"
                rules={[
                  { required: true, message: "Please input address" },
                  { max: 200 },
                ]}
                onChange={props.handleChange}
              >
                <Input name="address" placeholder="Address" />
              </Form.Item>
            </Col>
            <Col span={5} xs={24} md={8} lg={8}>
              <Form.Item
                label="State"
                name="state"
                rules={[
                  { required: true, message: "Please input state" },
                  { max: 50 },
                ]}
                onChange={props.handleChange}
              >
                <Input name="state" placeholder="State" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={5} xs={24} md={8} lg={8}>
              <Form.Item
                label="Zip"
                name="zip"
                rules={[
                  { required: true, message: "Please input zip" },
                  { max: 20 },
                ]}
                onChange={props.handleChange}
              >
                <Input name="zip" placeholder="Zip" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item style={{ marginTop: 20 }}>
            <Button type="primary" htmlType="submit">
              Save Customer
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
}
