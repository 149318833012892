import { createSlice } from "@reduxjs/toolkit";
import * as Settings from "../../../settings/Settings";
import { ExpenseRecordsReset } from "../Approvals/ApprovalSlice";
import axios from "axios";
import { message } from "antd";

const apiurl = Settings.ApiEndPoint;

export const viewExpenseSlice = createSlice({
  name: "viewExpenses",
  initialState: {
    ApprovedExpenseRecords: [],
  },
  reducers: {
    getApprovedExpenseRecords: (state, action) => {
      state.ApprovedExpenseRecords = [];
      action.payload.forEach((item) => {
        state.ApprovedExpenseRecords.push(item);
      });
    },
  },
});

export const { getApprovedExpenseRecords } = viewExpenseSlice.actions;

export const getApprovedExpenseAsync = () => (dispatch) => {
  axios.get(`${apiurl}api/Expense/GetAllExpenses`).then((res) => {
    console.log(res);
    dispatch(getApprovedExpenseRecords(res.data));
    dispatch(ExpenseRecordsReset());
  });
};
export const AddPaymentReference = (id, values) => (dispatch) => {
  const formData = new FormData();

  values.Attachment.map((v) => {
    formData.append("files", v);
  });

  return axios
    .post(
      `${apiurl}api/Expense/AddPaymentReference/${id}/${values.PaymentReference}`,
      formData
    )
    .then((res) => {
      message.success("Added Successfully", [1.5]);
      dispatch(getApprovedExpenseAsync());
    });
};
export const AddVendorInvoice = (id, values) => (dispatch) => {
  const formData = new FormData();

  values.Attachment.map((v) => {
    formData.append("files", v);
  });

  return axios
    .post(`${apiurl}api/Attachment/Expense/Create/${id}/${"Expense"}`, formData)
    .then((res) => {
      message.success("Documents uploaded succesfully", [1.5]);
      dispatch(getApprovedExpenseAsync());
      return true;
    })
    .catch((Error) => {
      message.error("Something went wrong while downloading", [1.5]);
      return false;
    });
};

export default viewExpenseSlice.reducer;
