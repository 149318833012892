import { createSlice } from "@reduxjs/toolkit";
import * as Settings from "../../../settings/Settings";
import axios from "axios";
import { message } from "antd";

const apiurl = Settings.ApiEndPoint;

export const adminApprovalSlice = createSlice({
  name: "adminApprovals",
  initialState: {
    expenseRecords: [],
  },
  reducers: {
    getExpenseForAdminApprovalRecords: (state, action) => {
      state.expenseRecords = [];
      action.payload.forEach((item) => {
        state.expenseRecords.push(item);
      });
    },
  },
});

export const {
  getExpenseForAdminApprovalRecords,
  getExpenseShortRecords,
  ClearExpenseShortRecords,
} = adminApprovalSlice.actions;

export const getExpenseForAdminApprovalAsync = () => (dispatch) => {
  return axios
    .get(`${apiurl}api/Expense/GetexpensesforAdminApproval`)
    .then((res) => {
      dispatch(getExpenseForAdminApprovalRecords(res.data));
    });
};

export const approveAdminExpenses = (ids) => (dispatch) => {
  return axios
    .post(`${apiurl}api/Expense/AdminApproveExpenses/`, [ids])
    .then(() => {
      message.success("Approved", [1.5]);
      dispatch(getExpenseForAdminApprovalAsync());
    });
};

export default adminApprovalSlice.reducer;
