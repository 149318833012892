import { createSlice } from "@reduxjs/toolkit";
import * as Settings from "../../../settings/Settings";
import axios from "axios";
import { notification, message } from "antd";

const apiurl = Settings.ApiEndPoint;

export const approvalSlice = createSlice({
  name: "approvals",
  initialState: {
    expenseRecords: [],
    ExpenseShortRecords: [],
  },
  reducers: {
    getExpenseForApprovalRecords: (state, action) => {
      state.expenseRecords = [];
      action.payload.forEach((item) => {
        state.expenseRecords.push(item);
      });
    },

    getExpenseShortRecords: (state, action) => {
      //state.ExpenseShortRecords = [];
      state.ExpenseShortRecords.push(action.payload);
    },
    ClearExpenseShortRecords: (state, action) => {
      state.ExpenseShortRecords = [];
    },
  },
});

export const {
  getExpenseForApprovalRecords,
  getExpenseShortRecords,
  ClearExpenseShortRecords,
} = approvalSlice.actions;

export const getExpenseForApprovalAsync = () => (dispatch) => {
  axios.get(`${apiurl}api/Expense/GetexpensesforApproval`).then((res) => {
    dispatch(getExpenseForApprovalRecords(res.data));
    dispatch(ExpenseRecordsReset());
  });
};
export const getExpenseByIdAsync = (id) => (dispatch) => {
  axios.get(`${apiurl}api/Expense/GetExpenseShortDetails/${id}`).then((res) => {
    dispatch(getExpenseShortRecords(res.data));
  });
};

export const approvedExpenses = (ids) => (dispatch) => {
  axios.post(`${apiurl}api/Expense/ApproveExpenses/`, [ids]).then(() => {
    message.success("Approved", [1.5]);
    dispatch(getExpenseForApprovalAsync());
  });
};

export const rejectedExpenses = (ids, reason) => (dispatch) => {
  return axios
    .post(`${apiurl}api/Expense/RejectExpenses?rejectedReason=${reason}`, [ids])
    .then(() => {
      dispatch(getExpenseForApprovalAsync());
      notification.success({
        placement: "topRight",
        message: "Rejected",
      });
      // message.error("Rejected", [1.5]);
    })
    .catch(() => {
      message.error("Internal Error Occured");
    });
};
export const ExpenseRecordsReset = () => (dispatch) => {
  dispatch(ClearExpenseShortRecords());
};

export default approvalSlice.reducer;
