import React from "react";
import "antd/dist/antd.css";
import { Form, Input, Button, Popconfirm, Select } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { PlusCircleFilled, MinusCircleFilled } from "@ant-design/icons";
const { Option } = Select;
export default function TableForm() {
  const onFinish = (values) => {
    alert(JSON.stringify(values));
    console.log("Received values of form:", values);
  };
  return (
    <>
      <Form
        name="dynamic_form_nest_item"
        onFinish={onFinish}
        autoComplete="off"
      >
        <table>
          <tr>
            <th>S.No</th>
            <th>Description</th>
            <th>UOM</th>
            <th>Quantity</th>
            <th>GST%</th>
            <th>Basic Amount</th>
            <th>CGST</th>
            <th>SGST</th>
            <th>Total</th>
            <th>Actions</th>
          </tr>
          <Form.List name="Invoices">
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields.map((field, index) => (
                    <tr>
                      <td>
                        <Form.Item
                          {...field}
                          name={[field.name, "sno"]}
                          fieldKey={[field.fieldKey, "sno"]}
                        >
                          <Input />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          {...field}
                          name={[field.name, "description"]}
                          fieldKey={[field.fieldKey, "description"]}
                          rules={[
                            { required: true, message: "Missing Description" },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </td>
                      <td>
                        {/* <Form.Item
                          {...field}
                          name={[field.name, "uom"]}
                          fieldKey={[field.fieldKey, "uom"]}
                          rules={[{ required: true, message: "Missing uom" }]}
                        >
                          <Select defaultValue="lucy" style={{ width: 120 }} disabled>
                            <Option value="lucy">Lucy</Option>
                          </Select>
                        </Form.Item> */}
                      </td>
                      <td>
                        <Form.Item
                          {...field}
                          name={[field.name, "quantity"]}
                          fieldKey={[field.fieldKey, "quantity"]}
                          rules={[
                            { required: true, message: "Missing quantity" },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          {...field}
                          name={[field.name, "gst"]}
                          fieldKey={[field.fieldKey, "gst"]}
                          rules={[{ required: true, message: "Missing gst" }]}
                        >
                          <Input />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          {...field}
                          name={[field.name, "basicAmount"]}
                          fieldKey={[field.fieldKey, "basicAmount"]}
                          rules={[
                            { required: true, message: "Missing basicAmount" },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          {...field}
                          name={[field.name, "cgst"]}
                          fieldKey={[field.fieldKey, "cgst"]}
                        >
                          <Input />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          {...field}
                          name={[field.name, "sgst"]}
                          fieldKey={[field.fieldKey, "sgst"]}
                        >
                          <Input />
                        </Form.Item>
                      </td>
                      <td>
                        <Form.Item
                          {...field}
                          name={[field.name, "total"]}
                          fieldKey={[field.fieldKey, "total"]}
                        >
                          <Input />
                        </Form.Item>
                      </td>
                      <td>
                        <Popconfirm
                          title="Sure to delete?"
                          onConfirm={() => remove(field.name)}
                        >
                          <MinusCircleFilled
                            style={{
                              float: "right",
                              fontSize: "26px",
                              color: "#08c",
                            }}
                          />
                        </Popconfirm>
                        {/* <MinusCircleOutlined
                          onClick={() => {
                            remove(field.name);
                          }}
                        /> */}
                      </td>
                    </tr>
                  ))}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                      block
                    >
                      <PlusOutlined /> Add field
                    </Button>
                  </Form.Item>
                </>
              );
            }}
          </Form.List>
        </table>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
