import * as Settings from "../../../settings/Settings";
import axios from "axios";
import {
  Form,
  Input,
  DatePicker,
  message,
  Card,
  Row,
  Col,
  Space,
  Select,
  Button,
} from "antd";
import React, { useState, useEffect } from "react";
import moment from "moment";
import useDidMountEffect from "../../useDidMountEffect";
import TableForm from "../../commonComponents/Temp";
import { getAll, addInvoice } from "./AddInvoiceSlice";
import { useSelector, useDispatch } from "react-redux";

//import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
const gstapiurl = Settings.GSTAPI;
const secterKey = Settings.GSTApiSecretKey;

function DataFetching() {
  const dispatch = useDispatch();
  const projectsData = useSelector((state) => state.addInvoice.projects);
  const [data, setData] = useState([]);
  const handleData = (data) => {
    setData(data);
  };

  useEffect(() => {
    dispatch(getAll());
  }, [dispatch]);

  const onSubmit = (values) => {
    let reqDate = moment(values.invoiceDate).format("YYYY-MM-DDTHH:MM:SS");
    values.invoiceDate = "2020-08-27T08:01:47.286Z";
    values.vendorId = 1;
    let postData = {
      invoiceDetails: values,
      invoiceItem: data,
    };
    console.log("Form Values:", postData);

    dispatch(addInvoice(postData))
      .then((res) => {
        message.success("Successfully Added");
        form.resetFields();
        setData([]);
      })
      .catch(() => {
        message.error("Error while Saving");
      });
  };

  const { Option } = Select;
  const [form] = Form.useForm();
  const [gstin, setGstin] = useState("");
  const [supplyType, setSuppyType] = useState("");
  // const [dataSource, setDataSource] = useState([])

  const setValues = () => {
    form.setFieldsValue({
      receiverName: "Cool Karu",
      pos: "Telangana",
    });
  };

  function verifyGST() {
    setValues();

    // var finalApiUrl = gstapiurl+"?gstNo="+gstin+"&key_secret="+secterKey
    // console.log(finalApiUrl)
    // axios.get(finalApiUrl)
    //     .then(res => {
    //         console.log(res)
    //         try {
    //         setReceiverName(res.data.taxpayerInfo.tradeNam)
    //         setGstState(res.data.taxpayerInfo.pradr.addr.stcd)
    //         }
    //         catch(e){
    //             console.log(e)
    //             setGstin("Invalid GST")
    //         }

    //     })
  }

  useDidMountEffect(() => {
    // react please run me if 'key' changes, but not on initial render
    if (gstin === "") console.log("No action needed as GST num is not valid");
    else {
      console.log("GST has changed", gstin);
      verifyGST();
    }
  }, [gstin]);

  const onGSTChange = (e) => {
    //console.log(e.target.value.length);
    if (e.target.value.length !== 15) {
      console.log("Please enter valid GST");
      form.setFieldsValue({
        receiverName: "",
        pos: "",
      });
    } else {
      console.log("Valid GST");
      setGstin(e.target.value);
    }
  };

  function onChange(date, dateString) {
    let tempDate = moment(date);
    console.log(moment(date).format("YYYY-MM-DDTHH:MM:SS"), dateString);
  }

  return (
    <div>
      <div span={24} style={{ marginTop: 20 }}>
        <div>
          <Form
            name="nest-message"
            form={form}
            onFinish={onSubmit}
            layout="vertical"
            style={{ marginTop: 20 }}
          >
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              {/* Row 1 col 1 */}
              <Col className="gutter-row" xs={24} md={12} lg={8}>
                <Form.Item
                  label="Project (Short Name)"
                  name="projectId"
                  rules={[{ required: true, message: "Please select project" }]}
                >
                  <Select
                    showSearch
                    name="projectId"
                    style={{ width: "100%" }}
                    placeholder="Select Project"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {projectsData.map((d) => (
                      <Option key={Number(d.id)} value={Number(d.id)}>
                        {d.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {/* Row 1 col 2 */}
              <Col className="gutter-row" xs={24} md={12} lg={8}>
                <Form.Item
                  label="Receiver GSTIN/UIN"
                  name="gstin"
                  rules={[
                    {
                      required: true,
                      message: "Please enter GSTIN",
                    },
                  ]}
                >
                  <Input
                    placeholder="Receiver GSTIN/UIN"
                    onChange={onGSTChange}
                  />
                </Form.Item>
              </Col>

              {/* Row 1 col 3 */}
              <Col className="gutter-row" xs={24} md={12} lg={8}>
                <Form.Item
                  label="Receiver Name"
                  name="receiverName"
                  rules={[
                    {
                      required: false,
                      message: "Please enter receiver name",
                    },
                  ]}
                >
                  <Input placeholder="Receiver Name" readOnly={true} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              {/* Row 1 col 1 */}
              <Col className="gutter-row" xs={24} md={12} lg={8}>
                <Form.Item
                  label="Invoice No."
                  name="invoiceNo"
                  rules={[
                    {
                      required: true,
                      message: "Please enter invoice no.",
                    },
                  ]}
                >
                  <Input placeholder="Invoice No." />
                </Form.Item>
              </Col>

              {/* Row 1 col 2 */}
              <Col className="gutter-row" xs={24} md={12} lg={8}>
                <Form.Item
                  label="Invoice Date"
                  name="invoiceDate"
                  rules={[
                    {
                      required: true,
                      message: "Please enter invoice date",
                    },
                  ]}
                >
                  <DatePicker
                    onChange={onChange}
                    dateFormat="YYYY/MM/DD"
                    style={{ width: "100%" }}
                    placeholder="select Invoice Date"
                  />
                </Form.Item>
              </Col>

              {/* Row 1 col 3 */}
              <Col className="gutter-row" xs={24} md={12} lg={8} span={8}>
                <Form.Item
                  label="POS"
                  name="pos"
                  rules={[
                    {
                      required: false,
                      message: "Please enter state",
                    },
                  ]}
                >
                  <Input placeholder="State" readOnly={true} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              {/* Row 1 col 1 */}
              <Col className="gutter-row" xs={24} md={12} lg={8}>
                <Form.Item
                  label="Supply Type"
                  name="supplyType"
                  rules={[
                    {
                      required: true,
                      message: "Please enter supply type",
                    },
                  ]}
                >
                  <Input placeholder="Supply Type" />
                </Form.Item>
              </Col>
            </Row>

            <Space>&nbsp;</Space>

            {/* <Table
            columns={columns}
            dataSource={data}
            bordered
            size="small"
            //scroll={{ x: 'calc(700px + 50%)', y: 240 }}
          /> */}
            <TableForm isIntra={true} data={data} update={handleData} />

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col md={4} lg={2}>
                <Form.Item style={{ marginTop: 20 }}>
                  <Button type="primary" htmlType="submit">
                    Add Invoice
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default DataFetching;
