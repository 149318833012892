import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import addExpenseReducer from "../features/expenses/addExpense/AddExpenseSlice";
import vendorsReducer from "../features/vendors/viewvendors/viewSlice";
import customerReducer from "../features/customer/viewCustomer/viewCustomerSlice";
import addCustomer from "../features/customer/addCustomer/AddCustomerSlice";
import vendorDetailsReducer from "../features/vendors/addvendor/vendorSlice";
import addPOsReducer from "../features/purchaseorder/addPO/AddPOsSlice";
import approvalReducer from "../features/expenses/Approvals/ApprovalSlice";
import viewExpensesReducer from "../features/expenses/viewExpenses/ViewExpensesSlice";
import projectReducer from "../features/projects/addProject/AddProjectSlice";
import projectListReducer from "../features/projects/viewProjects/ViewProjectsSlice";
import projectViewReducer from "../features/projects/viewProject/ViewProjectSlice";
import viewPOsReducer from "../features/purchaseorder/viewPO/ViewPOsSlice";
import addInvoiceReducer from "../features/invoices/addInvoice/AddInvoiceSlice";
import Login from "../features/Authentication/Login/LoginSlice";
import adminApprovals from "../features/expenses/adminApprovals/AdminApprovalSlice";
import Lookup from "../features/Lookup/LookupSlice";
export default configureStore({
  reducer: {
    counter: counterReducer,
    departmentList: projectReducer,
    projectTypeList: projectReducer,
    expenses: addExpenseReducer,
    vendorList: vendorsReducer,
    viewCustomer: customerReducer,
    addCustomer: addCustomer,
    vendor: vendorsReducer,
    vendorDetails: vendorDetailsReducer,
    purchase: addPOsReducer,
    projectList: projectListReducer,
    projectDeletionStatus: projectListReducer,
    projectDetails: projectReducer,
    approvals: approvalReducer,
    viewExpenses: viewExpensesReducer,
    ProjectFullList: projectViewReducer,
    PurchaseList: viewPOsReducer,
    addInvoice: addInvoiceReducer,
    Login,
    adminApprovals,
    Lookup,
  },
});
