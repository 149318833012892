import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import logo from "./logo.png";
import vk from "./vk.jpeg";
import ck from "./ck.jpeg";
import { Counter } from "./features/counter/Counter";
import AddProject from "./features/projects/addProject/AddProject";
import { ViewProjects } from "./features/projects/viewProjects/ViewProjects";
import { ViewProject } from "./features/projects/viewProject/ViewProject";
import { AddInvoice } from "./features/invoices/addInvoice/AddInvoice";
import { ViewInvoices } from "./features/invoices/viewInvoices/ViewInvoices";
import { AddExpense } from "./features/expenses/addExpense/AddExpense";
import { ViewExpenses } from "./features/expenses/viewExpenses/ViewExpenses";
import { AddVendor } from "./features/vendors/addvendor/AddVendor";
import { ViewVendors } from "./features/vendors/viewvendors/ViewVendors";
import { AddPOs } from "./features/purchaseorder/addPO/AddPOs";
import { ViewPOs } from "./features/purchaseorder/viewPO/ViewPOs";
import { Approvals } from "./features/expenses/Approvals/Approvals";
import { AdminApprovals } from "./features/expenses/adminApprovals/AdminApprovals";
import { AddCustomer } from "./features/customer/addCustomer/AddCustomer";
import { ViewCustomers } from "./features/customer/viewCustomer/viewCustomer";
import Login from "./features/Authentication/Login/Login";
import DashBoard from "../src/features/DashBoard/DashBoard";
import "antd/dist/antd.css";
import "./App.css";
import * as Settings from "./settings/Settings";
import { Menu, Space, Avatar, Button, Layout, PageHeader } from "antd";
import {
  ProfileOutlined,
  TeamOutlined,
  HomeOutlined,
  DollarOutlined,
  ReadOutlined,
  MailOutlined,
  MenuOutlined,
  BellOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Redirect,
  Route,
  Link,
  useHistory,
  useLocation,
  useRouteMatch,
  Switch,
} from "react-router-dom";
import { SignOut } from "../src/features/Authentication/Login/LoginSlice";
import {
  isSuperAdmin,
  isAdmin,
} from "../src/features/Authentication/Login/LoginSlice";
//import { isAdmin } from "../src/features/Authentication/Login/LoginSlice";
//import { waitForDomChange } from "@testing-library/react";

const { SubMenu } = Menu;

const { Sider, Content, Footer } = Layout;

const logoKey = Settings.logo;

function App(props) {
  const token = useSelector((state) => state.Login.token);
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  const rootSubmenuKeys = ["sub1", "sub2", "sub3", "sub4", "sub5"];

  const [collapsed, setCollapsed] = useState(false);

  const [openKeys, setOpenKeys] = useState(["sub1"]);
  const dispatch = useDispatch();
  function onOpenChange(v) {
    console.log(v);
    console.log(openKeys);
    const latestOpenKey = v.find((key) => openKeys.indexOf(key) === -1);
    console.log(latestOpenKey);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      console.log("if triggered");
      setOpenKeys(v);
    } else {
      console.log("else triggered");
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  }

  let toggle = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Layout>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{ overflow: "auto", height: "100vh" }}
      >
        <div className="logo" src={logo} />
        <Menu
          defaultSelectedKeys={["1"]}
          mode="inline"
          theme="dark"
          inlineCollapsed={collapsed}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
        >
          <Menu.Item key="1" icon={<HomeOutlined />}>
            <span>Dashboard</span>
            <Link to="/DashBoard" />
          </Menu.Item>
          <SubMenu key="sub6" icon={<UserOutlined />} title="Customers">
            <Menu.Item key="14">
              <span>Add Customer</span>
              <Link to="/customer/addcustomer" />
            </Menu.Item>
            <Menu.Item key="15">
              <span>View All Customers</span>
              <Link to="/customer/viewcustomer" />
            </Menu.Item>
          </SubMenu>
          <SubMenu key="sub1" icon={<MailOutlined />} title="Projects">
            <Menu.Item key="2">
              <span>Add Projects</span>
              <Link to="/project/addproject" />
            </Menu.Item>
            <Menu.Item key="3">
              <span>View All Projects</span>
              <Link to="/project/viewprojects" />
            </Menu.Item>
          </SubMenu>
          <SubMenu key="sub4" icon={<TeamOutlined />} title="Vendors">
            <Menu.Item key="9">
              <span>Add Vendor</span>
              <Link to="/vendor/addvendor" />
            </Menu.Item>
            <Menu.Item key="10">
              <span>View Vendor</span>
              <Link to="/vendor/viewvendor" />
            </Menu.Item>
          </SubMenu>
          <SubMenu key="sub5" icon={<ProfileOutlined />} title="Purchase Order">
            <Menu.Item key="11">
              <span>Add PO</span>
              <Link to="/purchaseorder/addpos" />
            </Menu.Item>
            <Menu.Item key="12">
              <span>View PO</span>
              <Link to="/purchaseorder/viewpos" />
            </Menu.Item>
          </SubMenu>
          <SubMenu key="sub2" icon={<DollarOutlined />} title="Expenses">
            <Menu.Item key="4">
              <span>Add Expenses</span>
              <Link to="/expense/addexpenses" />
            </Menu.Item>
            <Menu.Item key="5">
              <span>View Expenses</span>
              <Link to="/expense/viewexpenses" />
            </Menu.Item>
            {(isSuperAdmin() || isAdmin()) && (
              <Menu.Item key="6">
                <span>Approvals</span>
                <Link to="/expense/Approvals" />
              </Menu.Item>
            )}
            {isSuperAdmin() && (
              <Menu.Item key="7">
                <span>Admin Approvals</span>
                <Link to="/expense/adminapprovals" />
              </Menu.Item>
            )}
          </SubMenu>
          <SubMenu key="sub3" icon={<ReadOutlined />} title="Invoice">
            <Menu.Item key="7">
              <span>Add Invoice</span>
              <Link to="/invoice/addinvoice" />
            </Menu.Item>
            <Menu.Item key="8">
              <span>View Invoice</span>
              <Link to="/invoice/viewinvoices" />
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="13" icon={<DollarOutlined />} title="GST">
            <span>GST</span>
            <Link to="/" />
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        {/* <Header className="site-layout-background" style={{ padding: 0 }} >
          <Space>
          &nbsp;
          &nbsp;
          &nbsp;
            {React.createElement(
              collapsed ? MenuOutlined : MenuOutlined,
              {
                className: "trigger",
                onClick: toggle,
              }
            )

            }
            </Space>
          </Header> */}
        <PageHeader
          title={React.createElement(collapsed ? MenuOutlined : MenuOutlined, {
            className: "trigger",
            onClick: toggle,
          })}
          extra={[
            <Space>
              <img
                src={logoKey == "CK" ? ck : vk}
                style={{
                  paddingRight: "500px",
                  height: "70px",
                }}
              ></img>
              <Button shape="circle" icon={<BellOutlined />} />
              &nbsp;
              <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
              &nbsp;
              {token && (
                <a onClick={() => dispatch(SignOut())} href>
                  Logout
                </a>
              )}
            </Space>,
          ]}
          icon={<MenuOutlined />}
          style={{
            padding: "1.75Vmin",
            margin: "0px 2px",
            background: "#FFFFFF",
            paddingLeft: "3Vmin",
          }}
        ></PageHeader>
        <Content
          className="site-layout-background"
          style={{
            margin: "2px 0px",
            padding: 24,
            minHeight: 280,
          }}
        >
          <Switch>
            <Route path={`${props.path}DashBoard`} component={DashBoard} />
            <Route
              path={`${props.path}project/addproject`}
              component={AddProject}
            />
            <Route
              path={`${props.path}project/viewprojects`}
              component={ViewProjects}
            />
            <Route
              path={`${props.path}expense/addexpenses/:id`}
              component={AddExpense}
            />
            <Route
              path={`${props.path}expense/addexpenses`}
              component={AddExpense}
            />
            <Route
              path={`${props.path}expense/viewexpenses`}
              component={ViewExpenses}
            />
            <Route
              path={`${props.path}expense/Approvals`}
              component={Approvals}
            />
            <Route
              path={`${props.path}expense/adminapprovals`}
              component={AdminApprovals}
            />
            <Route
              path={`${props.path}invoice/addinvoice`}
              component={AddInvoice}
            />
            <Route
              path={`${props.path}invoice/viewinvoices`}
              component={ViewInvoices}
            />
            <Route
              path={`${props.path}vendor/addvendor/:id`}
              component={AddVendor}
            />
            <Route
              path={`${props.path}vendor/addvendor`}
              component={AddVendor}
            />
            <Route
              path={`${props.path}vendor/viewvendor`}
              component={ViewVendors}
            />
            <Route
              path={`${props.path}project/viewproject`}
              component={ViewProject}
            />
            <Route
              path={`${props.path}purchaseorder/addpos`}
              component={AddPOs}
            />
            <Route
              path={`${props.path}purchaseorder/viewpos`}
              component={ViewPOs}
            />
            <Route
              path={`${props.path}customer/addcustomer/:id`}
              component={AddCustomer}
            />
            <Route
              path={`${props.path}customer/addcustomer`}
              component={AddCustomer}
            />

            <Route
              path={`${props.path}customer/viewcustomer`}
              component={ViewCustomers}
            />
          </Switch>
        </Content>
        <Footer>COPYRIGHT© 2020 COOLKARU</Footer>
      </Layout>
    </Layout>

    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <Counter />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <span>
    //       <span>Learn </span>
    //       <a
    //         className="App-link"
    //         href="https://reactjs.org/"
    //         target="_blank"
    //         rel="noopener noreferrer"
    //       >
    //         React
    //       </a>
    //       <span>, </span>
    //       <a
    //         className="App-link"
    //         href="https://redux.js.org/"
    //         target="_blank"
    //         rel="noopener noreferrer"
    //       >
    //         Redux
    //       </a>
    //       <span>, </span>
    //       <a
    //         className="App-link"
    //         href="https://redux-toolkit.js.org/"
    //         target="_blank"
    //         rel="noopener noreferrer"
    //       >
    //         Redux Toolkit
    //       </a>
    //       ,<span> and </span>
    //       <a
    //         className="App-link"
    //         href="https://react-redux.js.org/"
    //         target="_blank"
    //         rel="noopener noreferrer"
    //       >
    //         React Redux
    //       </a>
    //     </span>
    //   </header>
    // </div>
  );
}

export default App;
