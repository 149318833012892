import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import logo from "../../../logo.png";
import { SignIn } from "./LoginSlice";
import {
  Row,
  Input,
  Col,
  Breadcrumb,
  Button,
  Form,
  Radio,
  Card,
  Switch,
  Upload,
  InputNumber,
  Select,
} from "antd";

const Login = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { token, initURL, requirAccessMessage } = useSelector(
    (state) => state.Login
  );
  const history = useHistory();
  const [messagetoshow, setmessage] = useState([]);
  useEffect(() => {
    if (requirAccessMessage) {
      var a = requirAccessMessage.split("|");
      setmessage(a);
    }
  }, [requirAccessMessage]);
  useEffect(() => {
    if (token !== null) {
      history.push("/");
    }
  }, [token, history]);

  const onFinish = (values) => {
    dispatch(SignIn(values));
  };
  const onFinishFailed = (errorInfo) => {};
  return (
    <>
      <div class="ht-100v text-center">
        <Row>
          <Col
            xs={24}
            md={12}
            lg={12}
            className="col-lg-6 login-bg hidden-sm p-0"
          >
            <div className="login-bg-overlay"></div>
            <div className="ht-100v d-flex">
              <div className="align-self-center" style={{ zIndex: 9999 }}>
                <img src={logo} class="img-fluid" alt="" />
                <h3 className="tx-20 tx-semibold tx-gray-100 pd-t-50">
                  JOIN OUR COMMUNITY
                </h3>
                <p className="pd-y-15 pd-x-10 pd-md-x-100 tx-gray-200">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore.
                </p>
              </div>
            </div>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <div className="bg-light">
              <div className="ht-100v d-flex align-items-center justify-content-center">
                <div className="">
                  <h3 class="tx-dark mg-b-5 tx-left">Sign In</h3>
                  <p class="tx-gray-500 tx-15 mg-b-40">
                    Welcome back! Please signin to continue.
                  </p>
                  <Row>
                    <Col xs={24} md={24} lg={24}>
                      <Form
                        name="nest-message"
                        layout="vertical"
                        form={form}
                        style={{ marginTop: 20 }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                      >
                        <Form.Item
                          label="Email"
                          name="UserName"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Email",
                            },
                          ]}
                        >
                          <Input placeholder="Email" />
                        </Form.Item>
                        <Form.Item
                          label="Password"
                          name="Password"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Password",
                            },
                          ]}
                        >
                          <Input type="password" placeholder="Password" />
                        </Form.Item>
                        <Form.Item style={{ marginTop: 20 }}>
                          <Button
                            type="primary"
                            htmlType="submit"
                            className="btn-block"
                          >
                            Sign In
                          </Button>
                        </Form.Item>
                      </Form>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div>
        <div
          style={{
            fontSize: "16px",
            color: " #fa4616",
            paddingLeft: "18px",
            paddingTop: "7px",
          }}
        >
          <p>{messagetoshow[0]}</p>
          <p>{messagetoshow[1]}</p>
        </div>
      </div>
    </>
  );
};
export default Login;
