import React from "react";

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Breadcrumb, Row, Col, Progress, PageHeader, Button } from "antd";
import { HomeOutlined, MailOutlined } from "@ant-design/icons";

import ProjectViewTab from "./ProjectViewTab";

export function ViewProject() {
  const hisorty = useHistory();

  const projecfulltdata = useSelector(
    (state) => state.ProjectFullList.projectFullRecord
  );

  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <h1>View Project Details</h1>
          <h2 className="title gx-mb-4">
            <Breadcrumb>
              <Breadcrumb.Item href="/DashBoard">
                <HomeOutlined />
                <span>Home</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item href="/project/viewprojects">
                <MailOutlined />
                <span>Projects</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item>View All Projects</Breadcrumb.Item>
            </Breadcrumb>
          </h2>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={12} lg={18} xl={18}>
          <PageHeader
            style={{ paddingRight: 0, paddingLeft: 0 }}
            className="site-page-header"
            title={projecfulltdata.project?.projectName || "Project Name"}
            subTitle={projecfulltdata.project?.daysStartedAgo || "Days To Show"}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <h4>Spent: {projecfulltdata.project?.expenseAmount || "No Data"}</h4>
          <Progress percent={projecfulltdata.project?.percentage} />
          <p>
            <span>{projecfulltdata.project?.expenseAmount || "No Data"}</span>/
            <span>{projecfulltdata.project?.budget}</span>
            {console.log("Proect Name", projecfulltdata.projectName)}
          </p>
        </Col>
      </Row>
      {/* <ViewTable /> */}
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <ProjectViewTab />
        </Col>
      </Row>
      <Row>
        <Col style={{ marginTop: "50px" }}>
          <Button
            type="primary"
            onClick={() => hisorty.push("/project/viewProjects")}
          >
            Back to Projects
          </Button>
        </Col>
      </Row>
    </>
  );
}
