import { createSlice } from "@reduxjs/toolkit";
import * as Settings from "../../../settings/Settings";
import axios from "axios";
const apiurl = Settings.ApiEndPoint;
export const ViewPOsSlice = createSlice({
  name: "PurchaseList",
  initialState: {
    isRecordDeleted: false,
    PurchaseOrderRecords: [],
  },
  reducers: {
    getAllPoRecords: (state, action) => {
      state.PurchaseOrderRecords = [];
      action.payload.forEach((item) => {
        state.PurchaseOrderRecords.push(item);
      });
    },
    deleteRecord: (state, action) => {},
  },
});

export const { getAllPoRecords } = ViewPOsSlice.actions;

export const getAllPO = () => (dispatch) => {
  axios.get(`${apiurl}api/PurchaseOrder/GetPurchaseOdersShort`).then((res) => {
    dispatch(getAllPoRecords(res.data));
  });
};
export const { deleteRecord } = ViewPOsSlice.actions;
export const deletePurchaseRecord = (poId) => (dispatch) => {
  axios
    .post(`${apiurl}api/PurchaseOrder/RemovePurchaseOrderById/${poId}`)
    .then((res) => {
      dispatch(getAllPO());
    });
};

export default ViewPOsSlice.reducer;
