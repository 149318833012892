import React, { useState } from "react";
import { Modal, Button, Form, Input } from "antd";
import { useDispatch } from "react-redux";
import { rejectedExpenses } from "../expenses/Approvals/ApprovalSlice";

const { TextArea } = Input;
const RejectionModal = (props) => {
  const [rejectedData, setRejectedData] = useState({
    reason: "",
  });

  const handleOk = (e) => {
    props.onSubmit(props.id, rejectedData.reason);
    setRejectedData({
      reason: "",
    });
  };

  const handleCancel = (e) => {
    props.setVisible(false);
  };

  const handleChange = (e) => {
    setRejectedData({
      reason: e.target.value,
    });
  };
  return (
    <>
      <Modal
        title="Rejected Reason"
        visible={props.visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          disabled: rejectedData.reason === "" ? true : false,
        }}
      >
        <TextArea
          rows={4}
          value={rejectedData.reason}
          onChange={handleChange}
        />
      </Modal>
    </>
  );
};

export default RejectionModal;
