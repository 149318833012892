import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row, Table, Button, Space, Popconfirm } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Moment from "react-moment";
import {
  deleteCustomerRecord,
  getAllCustomerRecords,
} from "../viewCustomer/viewCustomerSlice";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  isSuperAdmin,
  isAdmin,
} from "../../../Login../../../src/features/Authentication/Login/LoginSlice";

export function ViewCustomers() {
  const dispatch = useDispatch();
  const customerRecords = useSelector((state) => state.viewCustomer);
  const [records, modifyRecords] = useState(customerRecords?.customerRecords);
  const [page, setPage] = useState(1);
  const { Column } = Table;
  const history = useHistory();
  useEffect(() => {
    dispatch(getAllCustomerRecords());
  }, [dispatch]);

  useEffect(() => {
    modifyRecords(customerRecords?.customerRecords);
  }, [customerRecords]);
  const editCustomer = (e) => {
    history.push("addcustomer/" + e);
  };
  const deleteCustomer = (e) => {
    dispatch(deleteCustomerRecord(e.customerID));
  };
  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <h2 className="title gx-mb-4">
            View Customer
            <Button
              type="primary"
              icon={<PlusOutlined />}
              style={{ float: "right" }}
              // onClick={() => AddCustomer}
            >
              &nbsp;
              <Link to="/customer/addcustomer" style={{ color: "white" }}>
                Add Customer
              </Link>
            </Button>
          </h2>
          <div class="mt-30">
            <Table
              size="middle"
              pagination={{
                onChange(current) {
                  setPage(current);
                },
              }}
              bordered
              //please check if rowkey is required
              rowkey={(record) => record.id}
              dataSource={records}
            >
              <Column
                title="S.No"
                dataIndex="customerID"
                key="customerID"
                // render={(value, item, index) => index + 1}
                render={(value, item, index) => (page - 1) * 10 + (index + 1)}
              />
              <Column
                title="Customer Name"
                dataIndex="customerName"
                key="customerName"
                sorter={(a, b) =>
                  a.contactNumber.length - b.contactNumber.length
                }
              />
              <Column
                title="Contact Number"
                dataIndex="contactNumber"
                key="contactNumber"
                sorter={(a, b) => a.contactNumber - b.contactNumber}
              />
              <Column title="Email" dataIndex="email" key="email" />
              <Column title="State" dataIndex="state" key="state" />
              <Column
                title="Created By"
                dataIndex="createdByUser"
                key="createdByUser"
              />
              <Column
                title="created Date"
                dataIndex="createdDateTime"
                key="createdDateTime"
                align="center"
                render={(createdDateTime) => (
                  <>
                    <p>
                      {createdDateTime === null ? (
                        ""
                      ) : (
                        <>
                          <Moment utc local format="DD-MMM-YYYY">
                            {createdDateTime}
                          </Moment>
                        </>
                      )}
                    </p>
                  </>
                )}
              />
              <Column
                title="Updated By"
                dataIndex="updatedByUser"
                key="pdatedByUser"
              />
              <Column
                title="Updated Date"
                dataIndex="updatedDateTime"
                key="updatedDateTime"
                align="center"
                render={(updatedDateTime) => (
                  <>
                    <p>
                      {updatedDateTime === null ? (
                        ""
                      ) : (
                        <>
                          <Moment utc local format="DD-MMM-YYYY">
                            {updatedDateTime}
                          </Moment>
                        </>
                      )}
                    </p>
                  </>
                )}
              />
              <Column
                title="Action"
                key="action"
                render={(text, record) => (
                  <Space size="small">
                    <Button
                      icon={<EditOutlined />}
                      size="small"
                      onClick={() => editCustomer(record.customerID)}
                    ></Button>
                    {/* {record.projectFullRecord.length <= 0 ? ( */}
                    <Popconfirm
                      title="Sure to delete?"
                      onConfirm={() => deleteCustomer(record)}
                    >
                      {(isSuperAdmin() || isAdmin()) && (
                        <Button
                          danger
                          icon={<DeleteOutlined />}
                          size="small"
                        ></Button>
                      )}
                    </Popconfirm>
                    {/* ) : (
                       <Button 
                    //     danger
                    //     icon={<DeleteOutlined />}
                    //     size="small"
                    //     title="Delete is not allowed, as we have projects associated to customer"
                    //     disabled={true}
                    //   ></Button>
                    // )}*/}
                  </Space>
                )}
              />
            </Table>
          </div>
        </Col>
      </Row>
    </>
  );
}
