import React from "react";
import { Form, Input, Col, Row, InputNumber } from "antd";

export function JointVenture(props) {
  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={5} xs={24} md={8} lg={8}>
          <Form.Item
            label="Company Name"
            name="jCompanyName"
            rules={[
              { required: true, message: "Please input Company Name!" },
              { max: 255 },
            ]}
            onChange={props.handleChange}
          >
            <Input name="jCompanyName" placeholder="Company Name" />
          </Form.Item>
        </Col>
        <Col span={5} xs={24} md={8} lg={8}>
          <Form.Item
            label="Number"
            name="jContactNumber"
            rules={[{ required: true, message: "Please input Contact Number" }]}
            onChange={props.handleChange}
          >
            <InputNumber name="contactNumber" placeholder="Contact Number" />
          </Form.Item>
        </Col>
        <Col span={5} xs={24} md={8} lg={8}>
          <Form.Item
            label="Email"
            name="jEmail"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
              { max: 50 },
            ]}
            onChange={props.handleChange}
          >
            <Input name="jEmail" placeholder="Email" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={5} xs={24} md={8} lg={8}>
          <Form.Item
            label="Address"
            name="jAddress"
            rules={[
              { required: true, message: "Please input Address!" },
              { max: 200 },
            ]}
            onChange={props.handleChange}
          >
            <Input name="jAddress" placeholder="Address" />
          </Form.Item>
        </Col>
        <Col span={5} xs={24} md={8} lg={8}>
          <Form.Item
            label="State"
            name="jState"
            rules={[
              { required: true, message: "Please input State" },
              { max: 50 },
            ]}
            onChange={props.handleChange}
          >
            <Input name="jState" placeholder="State" />
          </Form.Item>
        </Col>
        <Col span={5} xs={24} md={8} lg={8}>
          <Form.Item
            label="Zip"
            name="jZip"
            rules={[
              { required: true, message: "Please input Zip" },
              { max: 20 },
            ]}
            onChange={props.handleChange}
          >
            <Input name="jZip" placeholder="Zip" />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}
export default JointVenture;
