import { createSlice } from "@reduxjs/toolkit";
import * as Settings from "../../../settings/Settings";
import axios from "axios";
import { notification, message } from "antd";

const apiurl = Settings.ApiEndPoint;

export const addPOsSlice = createSlice({
  name: "purchase",
  initialState: {
    vendorRecords: [],
    projectShortRecords: [],
    vendorAutoRecord: {},
    record: {},
  },
  reducers: {
    getVendorRecords: (state, action) => {
      state.vendorRecords = [];
      action.payload.forEach((item) => {
        state.vendorRecords.push(item);
      });
    },
    getProjectRecords: (state, action) => {
      state.projectShortRecords = [];
      action.payload.forEach((item) => {
        state.projectShortRecords.push(item);
      });
    },
    getPurchaseById: (state, action) => {
      state.record = action.payload;
    },
    getVendorById: (state, action) => {
      state.vendorAutoRecord = action.payload;
    },
    resetPO: (state, action) => {
      state.record = {};
    },
  },
});

export const {
  getVendorRecords,
  getProjectRecords,
  getPurchaseById,
  getVendorById,
  resetPO,
} = addPOsSlice.actions;

export const getVendorAsync = () => (dispatch) => {
  axios.get(`${apiurl}api/LookUp/GetVendorsLookup`).then((res) => {
    console.log(res);
    dispatch(getVendorRecords(res.data));
  });
};
export const getProjectAsync = () => (dispatch) => {
  axios.get(`${apiurl}api/LookUp/GetProjectLookup`).then((res) => {
    console.log(res);
    dispatch(getProjectRecords(res.data));
  });
};

export const SaveForm = (data) => {
  return (dispatch) => {
    return axios.post(`${apiurl}api/PurchaseOrder/SavePurchaseOrder`, data);
  };
};
export const updateForm = (data) => {
  return (dispatch) => {
    return axios.post(`${apiurl}api/PurchaseOrder/UpdatePurchaseOrder`, data);
  };
};
export const getPurchaseDetailsById = (poId) => (dispatch) => {
  axios
    .get(`${apiurl}api/PurchaseOrder/GetPurchaseOrderById/${poId}`)
    .then((res) => {
      dispatch(getPurchaseById(res.data));
      console.log(res);
    });
};
export const getVendorDetailsById = (id) => (dispatch) => {
  axios.get(`${apiurl}api/Vendors/GetVendorById/${id}`).then((res) => {
    dispatch(getVendorById(res.data));
    console.log(res);
  });
};
export const ResetPO = () => (dispatch) => {
  dispatch(resetPO());
};
export default addPOsSlice.reducer;
