import React, { useState, useEffect } from "react";
import {
  Input,
  Col,
  Row,
  Form,
  Button,
  Select,
  Card,
  Table,
  Popconfirm,
  message,
} from "antd";
import {
  EyeOutlined,
  PlusOutlined,
  MinusCircleFilled,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { GetMasterLookup, GetPOLookup } from "../../Lookup/LookupSlice";

import { useSelector, useDispatch } from "react-redux";
import {
  getVendorAsync,
  getProjectAsync,
  updateForm,
  SaveForm,
  getPurchaseDetailsById,
  getVendorDetailsById,
} from "./AddPOsSlice";
import { ResetPO } from "../addPO/AddPOsSlice";
import { useHistory } from "react-router-dom";
import TableForm from "../../commonComponents/EditableTableNew";
import { ViewPOs } from "../viewPO/ViewPOs";
//import TableForm from "../../commonComponents/TableForm";
const { Option } = Select;
const { TextArea } = Input;
const { Column } = Table;
export function AddPOs(props) {
  const history = useHistory();
  const [displayAddPurchase, setDisplayStatus] = useState(
    !props.isInEditMode ?? true
  );
  const [poId, setPOId] = useState(null);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [formData, setFormState] = useState({});
  const [isBudgetExceeded, setIsBudgetExceeded] = useState(false);
  const [availableBudget, setavailableBudget] = useState(-1);
  const [poAmount, setpoAmount] = useState(0);
  const vendorData = useSelector((state) => state.purchase.vendorRecords);
  const selectedRecord = useSelector((state) => state.purchase.record);
  const projectShortData = useSelector(
    (state) => state.purchase.projectShortRecords
  );
  const vendorAutoData = useSelector(
    (state) => state.purchase.vendorAutoRecord
  );
  const typeLookup = useSelector((state) => state.Lookup.masterLookup);

  const [isTypeBoth, setIsTypeBoth] = useState(false);
  const [data, setData] = useState([]);

  const handleData = (data) => {
    setData(data);
  };

  useEffect(() => {
    var poAmount = 0;
    if (data) {
      data.forEach((item) => {
        poAmount = poAmount + Number(item.totalAmount);
      });
    }
    setpoAmount(poAmount);
    if (poAmount !== 0 && poAmount >= availableBudget) {
      setIsBudgetExceeded(true);
    } else {
      setIsBudgetExceeded(false);
    }
  }, [data]);
  const onVendorChange = (id) => {
    dispatch(getVendorDetailsById(id));
  };
  form.setFieldsValue({
    gstno: vendorAutoData?.gstno,
    address: vendorAutoData?.address,
    panNumber: vendorAutoData?.panNumber,
  });

  const initialState = {
    purchaseVm: {
      projectId: null,
      vendorID: null,
      address: "",
      gstno: "",
      typeId: null,
      material: "",
      service: "",
      id: null,
    },
    itemsVm: {
      purchaseOrderId: null,
      description: "",
      unitOfMeasure: "",
      quantity: "",
      gst: "",
      cgst: "",
      sgst: "",
      igst: "",
      basicAmount: "",
      totalAmount: "",
    },
  };

  const [purchaseValues, updateValues] = useState(initialState);
  useEffect(() => {
    if (Object.keys(selectedRecord).length > 0) {
      updateValues(selectedRecord);
      setData(selectedRecord.itemsVm);
      setDisplayStatus(true);
      if (selectedRecord?.purchaseVm?.typeId === 3) {
        setIsTypeBoth(true);
      }
      if (selectedRecord?.purchaseVm?.id > 0) {
        var poAmount = 0;
        if (selectedRecord.itemsVm) {
          selectedRecord.itemsVm.forEach((item) => {
            poAmount = poAmount + Number(item.totalAmount);
          });
        }
        var selectedProj = projectShortData.find(
          (a) => a.id === selectedRecord?.purchaseVm.id
        );
        setavailableBudget(selectedProj?.availablBudget + poAmount);
      }
    }
    if (window.location.pathname === "/purchaseorder/addpos") {
      updateValues(initialState);
      setData([]);
    }
  }, [selectedRecord]);
  console.log(window.location.pathname);

  const onFinish = (values) => {
    let postData = {
      purchaseVm: values,
      itemsVm: data,
    };
    console.log("Form Values:", postData);
    if (values.id <= 0 || values.id == null) {
      postData.purchaseVm.id = 0;
      dispatch(SaveForm(postData))
        .then((res) => {
          message.success("Successfully Added");
          form.resetFields();
          setData([]);
          history.push("viewpos");
        })
        .catch(() => {
          message.error("Error while saving");
        });
    } else {
      dispatch(updateForm(postData))
        .then((res) => {
          message.success("Successfully updated");
          //form.resetFields();
          //setData([]);
          //history.push("viewpos");
        })
        .catch(() => {
          message.error("Error while saving");
        });
    }
  };
  useEffect(() => {
    dispatch(getVendorAsync());
    dispatch(getProjectAsync());
    dispatch(GetMasterLookup());
    setavailableBudget(-1);
    setpoAmount(0);
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPurchaseDetailsById(props.poId));
  }, [dispatch]);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const onTypeChange = (e) => {
    if (e === 3) {
      setIsTypeBoth(true);
    } else {
      setIsTypeBoth(false);
    }
  };
  const onProjectChange = (e) => {
    const val = projectShortData.find((a) => a.id === e);
    setavailableBudget(val.availablBudget);
  };
  if (displayAddPurchase) {
    return (
      <>
        <h2 className="title gx-mb-4">
          Purchase Order : {selectedRecord?.purchaseVm?.poNumber}
          <Button
            type="primary"
            icon={<EyeOutlined />}
            style={{ float: "right" }}
            onClick={() => ViewPOs}
          >
            &nbsp;
            <Link to="/purchaseorder/viewpos" style={{ color: "white" }}>
              View PO
            </Link>
          </Button>
        </h2>
        <div>
          <Form
            name="nest-message"
            layout="vertical"
            style={{ marginTop: 20 }}
            onFinish={onFinish}
            form={form}
            onFinishFailed={onFinishFailed}
            initialValues={{
              id: purchaseValues.purchaseVm.id,
              projectId: purchaseValues.purchaseVm.projectId,
              VendorID: purchaseValues.purchaseVm.vendorID,
              address: purchaseValues.purchaseVm.address,
              gstno: purchaseValues.purchaseVm.gstno,
              purchaseOderId: purchaseValues.itemsVm.purchaseOderId,
              description: purchaseValues.itemsVm.description,
              unitOfMeasure: purchaseValues.itemsVm.unitOfMeasure,
              quantity: purchaseValues.itemsVm.quantity,
              gst: purchaseValues.itemsVm.gst,
              cgst: purchaseValues.itemsVm.cgst,
              sgst: purchaseValues.itemsVm.sgst,
              igst: purchaseValues.itemsVm.igst,
              basicAmount: purchaseValues.itemsVm.basicAmount,
              totalAmount: purchaseValues.itemsVm.totalAmount,
              TypeId: purchaseValues.purchaseVm.typeId,
              Material: purchaseValues.purchaseVm.material,
              Service: purchaseValues.purchaseVm.service,
            }}
          >
            <Card span={24}>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col className="gutter-row" xs={24} md={12} lg={8}>
                  <Form.Item label="id" name="id" hidden={true}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Project (Short Name)"
                    name="projectId"
                    rules={[
                      {
                        required: true,
                        message: "Please select project",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select a Project"
                      optionFilterProp="children"
                      onChange={onProjectChange}
                      filterOption={(input, option) =>
                        option.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toString().toLowerCase()) >= 0 ||
                        option.value
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toString().toLowerCase()) >= 0
                      }
                    >
                      {projectShortData.map((shortdata) => (
                        <Option key={shortdata.id} value={shortdata.id}>
                          {" "}
                          {/* {shortdata.displayId} - */}
                          {shortdata.name}{" "}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                  <Form.Item
                    label="Vendor Name"
                    name="VendorID"
                    rules={[
                      {
                        required: true,
                        message: "Please select vendor",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      onChange={onVendorChange}
                      placeholder="Select"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {vendorData.map((data) => (
                        <Option key={data.id} value={data.id}>
                          {data.name}{" "}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} md={12} lg={8}>
                  <Form.Item
                    label="Type"
                    name="TypeId"
                    rules={[
                      {
                        required: true,
                        message: "Please select type",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select Type"
                      optionFilterProp="children"
                      onChange={onTypeChange}
                      filterOption={(input, option) =>
                        option.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {typeLookup.map((data) => (
                        <Option key={data.id} value={data.id}>
                          {data.name}{" "}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {isTypeBoth && (
                  <>
                    {" "}
                    <Col className="gutter-row" xs={24} md={12} lg={8} span={8}>
                      <Form.Item
                        label="Material"
                        name="Material"
                        rules={[
                          {
                            required: false,
                            message: "Please enter material details",
                          },
                        ]}
                      >
                        <Input placeholder="Material" />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} md={12} lg={8} span={8}>
                      <Form.Item
                        label="Service"
                        name="Service"
                        rules={[
                          {
                            required: false,
                            message: "Please enter service details",
                          },
                        ]}
                      >
                        <Input placeholder="Service" />
                      </Form.Item>
                    </Col>{" "}
                  </>
                )}
                <Col xs={24} md={12} lg={8}>
                  <Form.Item label="GSTNo" name="gstno">
                    <Input placeholder="GST" maxLength="100" disabled="True" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                  <Form.Item label="Address" name="address">
                    <TextArea
                      maxLength="1000"
                      autoSize={{ minRows: 3, maxRows: 5 }}
                      disabled="True"
                      placeholder="Address"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={8}>
                  <Form.Item label="PAN Number" name="panNumber">
                    <Input
                      maxLength="1000"
                      autoSize={{ minRows: 3, maxRows: 5 }}
                      disabled="True"
                      placeholder="PAN Number"
                    />
                  </Form.Item>
                </Col>
              </Row>
              {availableBudget >= 0 && (
                <strong>Available budget : {availableBudget}/-</strong>
              )}
              <TableForm
                isIntra={true}
                poId={purchaseValues.purchaseVm?.id}
                data={data}
                update={handleData}
                isBudgetExceeded={isBudgetExceeded}
              />
              <strong style={{ float: "right" }}>Total: {poAmount}/-</strong>
              <br></br>
              {isBudgetExceeded && (
                <div class="error">
                  PO Amount (<b>{poAmount}/-</b>) should be less than or equal
                  to Available budger (<b>{availableBudget}/-</b>)
                </div>
              )}
              <br></br>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={isBudgetExceeded}
                >
                  Submit
                </Button>
              </Form.Item>
            </Card>
          </Form>
        </div>
      </>
    );
  } else {
    return <h1>Fetching In Progress</h1>;
  }
}
export default AddPOs;
