import React, { useEffect } from "react";
import { Form, Input, Col, Row, InputNumber, Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getAllCustomerRecords } from "../../customer/viewCustomer/viewCustomerSlice";
const { Option } = Select;
export function CustomerInfo(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllCustomerRecords());
  }, [dispatch]);
  const customerLookup = useSelector(
    (state) => state.viewCustomer.customerRecords
  );
  const onCustomerChange = (e) => {
    const val = customerLookup.find((a) => a.customerID === e);
    props.form.setFieldsValue({
      customerID: val.customerID,
      contactNumber: val.contactNumber,
      alternativeNumber: val.alternativeNumber,
      email: val.email,
      address: val.address,
      state: val.state,
      zip: val.zip,
      createdDateTime: val.createdDateTime,
      updatedDateTime: val.updatedDateTime,
      //updatedBy: val.updatedBy,
      //createdBy: val.createdBy,
    });
  };
  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={5} xs={24} md={8} lg={8}>
          <Form.Item
            label="Customer Name"
            name="customerID"
            rules={[{ required: true, message: "Please select Customer" }]}
            // onChange={props.handleChange}
          >
            <Select
              showSearch
              placeholder="Select customer"
              optionFilterProp="children"
              onChange={onCustomerChange}
              filterOption={(input, option) =>
                option.children
                  .toString()
                  .toLowerCase()
                  .indexOf(input.toString().toLowerCase()) >= 0 ||
                option.value
                  .toString()
                  .toLowerCase()
                  .indexOf(input.toString().toLowerCase()) >= 0
              }
            >
              {customerLookup.map((shortdata) => (
                <Option key={shortdata.customerID} value={shortdata.customerID}>
                  {shortdata.customerName}
                </Option>
              ))}
            </Select>
            {/* <Input name="customerName" placeholder="Customer Name" /> */}
          </Form.Item>
        </Col>
        <Col span={5} xs={24} md={8} lg={8}>
          <Form.Item
            label="Number"
            name="contactNumber"
            rules={[{ required: true, message: "Please input Contact Number" }]}
            onChange={props.handleChange}
          >
            <InputNumber
              name="contactNumber"
              placeholder="Number"
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={5} xs={24} md={8} lg={8}>
          <Form.Item
            label="Alternate Number"
            name="alternativeNumber"
            // rules={[
            //   { required: true, message: "Please input Alternate Number" },
            // ]}
            onChange={props.handleChange}
          >
            <InputNumber
              name="alternativeNumber"
              placeholder="Alternate Number"
              disabled={true}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={5} xs={24} md={8} lg={8}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
              { max: 50 },
            ]}
            onChange={props.handleChange}
          >
            <Input name="email" placeholder="Email" disabled={true} />
          </Form.Item>
        </Col>
        <Col span={5} xs={24} md={8} lg={8}>
          <Form.Item
            label="Address"
            name="address"
            rules={[
              { required: true, message: "Please input Address!" },
              { max: 200 },
            ]}
            onChange={props.handleChange}
          >
            <Input name="address" placeholder="Address" disabled={true} />
          </Form.Item>
        </Col>
        <Col span={5} xs={24} md={8} lg={8}>
          <Form.Item
            label="State"
            name="state"
            rules={[
              { required: true, message: "Please input State" },
              { max: 50 },
            ]}
            onChange={props.handleChange}
          >
            <Input name="state" placeholder="State" disabled={true} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={5} xs={24} md={8} lg={8}>
          <Form.Item
            label="Zip"
            name="zip"
            rules={[
              { required: true, message: "Please input Zip" },
              { max: 20 },
            ]}
            onChange={props.handleChange}
          >
            <Input name="zip" placeholder="Zip" disabled={true} />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}

export default CustomerInfo;
