import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row, Table, Button, Space, Popconfirm } from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { getAllPO, deletePurchaseRecord } from "../viewPO/ViewPOsSlice";
import AddPOs from "../addPO/AddPOs";
import Moment from "react-moment";
import {
  isSuperAdmin,
  isAdmin,
} from "../../../Login../../../src/features/Authentication/Login/LoginSlice";
const { Column } = Table;

export function ViewPOs() {
  const dispatch = useDispatch();
  const PurchaseOrderRecords = useSelector((state) => state.PurchaseList);
  const [record, modifyRecords] = useState(
    PurchaseOrderRecords?.PurchaseOrderRecords.item
  );
  const [purchase, setPurchase] = useState({
    isEditing: false,
    poId: -1,
  });
  const [page, setPage] = React.useState(1);

  useEffect(() => {
    dispatch(getAllPO());
    console.log("Dispatch Call");
  }, [dispatch]);

  useEffect(() => {
    modifyRecords(PurchaseOrderRecords?.PurchaseOrderRecords);
  }, [PurchaseOrderRecords]);

  const deletePurchase = (e) => {
    dispatch(deletePurchaseRecord(e.poId));
  };

  const editPurchase = (e) => {
    setPurchase({
      ...purchase,
      isEditing: true,
      poId: e,
    });
  };
  //const moment = moment();
  //console.log(m.format("dd-mm-yy"));
  if (!purchase.isEditing) {
    return (
      <>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <h2 className="title gx-mb-4">
              View Purchase Order
              <Button
                type="primary"
                icon={<PlusOutlined />}
                style={{ float: "right" }}
              >
                &nbsp;
                <Link to="/purchaseorder/addpos" style={{ color: "white" }}>
                  Generate PO
                </Link>
              </Button>
            </h2>
            <Table
              dataSource={record}
              size="middle"
              pagination={{
                onChange(current) {
                  setPage(current);
                },
              }}
              bordered
            >
              <Column
                title="S.No"
                dataIndex="index"
                key="index"
                render={(value, item, index) => (page - 1) * 10 + (index + 1)}
              />
              <Column
                title="Project Name"
                dataIndex="projectName"
                key="projectName"
              />

              <Column
                title="Vendor Name"
                dataIndex="vendorName"
                key="vendorName"
              />
              <Column title="PO ID" dataIndex="poNumber" key="poNumber" />
              <Column
                title="Total Amount"
                dataIndex="totalAmount"
                key="totalAmount"
              />
              <Column
                title="Create Date"
                dataIndex="createdDateTime"
                key="createdDateTime"
                render={(createdDateTime) => (
                  <>
                    <p>
                      {createdDateTime === null ? (
                        ""
                      ) : (
                        <>
                          <Moment utc local format="DD-MMM-YYYY">
                            {createdDateTime}
                          </Moment>
                        </>
                      )}
                    </p>
                  </>
                )}
              />
              <Column
                title="Created By"
                dataIndex="createdByUser"
                key="createdByUser"
              />
              {/* <Column
                title="Updated Date"
                dataIndex="updatedByUser"
                key="updatedByUser"
                render={(updatedDateTime) => (
                  <>
                    <p>
                      {updatedDateTime === null ? (
                        ""
                      ) : (
                        <>
                          <Moment utc local format="DD-MMM-YYYY">
                            {updatedDateTime}
                          </Moment>
                        </>
                      )}
                    </p>
                  </>
                )}
              />
              <Column
                title="Updated By"
                dataIndex="updatedBy"
                key="updatedBy"
              /> */}
              <Column
                title="Actions"
                dataIndex="actions"
                key="actions"
                render={(text, record) =>
                  (isSuperAdmin() || isAdmin()) && (
                    <Space size="small">
                      {record?.editBtnEnabled === true ? (
                        <Button
                          style={{ color: "green" }}
                          icon={<EditOutlined />}
                          size="small"
                          onClick={() => editPurchase(record.poId)}
                        ></Button>
                      ) : (
                        <Button
                          icon={<EditOutlined />}
                          size="small"
                          title="Edit is not allowed, as there are expenses associated to PO"
                          disabled={true}
                        ></Button>
                      )}
                      {record?.deleteBtnEnabled === true ? (
                        <Popconfirm
                          title="Sure to delete?"
                          onConfirm={() => deletePurchase(record)}
                        >
                          <Button
                            danger
                            icon={<DeleteOutlined />}
                            size="small"
                          ></Button>
                        </Popconfirm>
                      ) : (
                        <Button
                          danger
                          icon={<DeleteOutlined />}
                          size="small"
                          title="Delete is not allowed, as we have Expenses associated to PO"
                          disabled={true}
                        ></Button>
                      )}
                    </Space>
                  )
                }
              />
            </Table>
          </Col>
        </Row>
      </>
    );
  } else {
    return (
      <div>
        <AddPOs isInEditMode={purchase.isEditing} poId={purchase.poId} />
      </div>
    );
  }
}
