import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import logo from "./logo.png";
import { Counter } from "./features/counter/Counter";
import AddProject from "./features/projects/addProject/AddProject";
import { ViewProjects } from "./features/projects/viewProjects/ViewProjects";
import { ViewProject } from "./features/projects/viewProject/ViewProject";
import { AddInvoice } from "./features/invoices/addInvoice/AddInvoice";
import { ViewInvoices } from "./features/invoices/viewInvoices/ViewInvoices";
import { AddExpense } from "./features/expenses/addExpense/AddExpense";
import { ViewExpenses } from "./features/expenses/viewExpenses/ViewExpenses";
import { AddVendor } from "./features/vendors/addvendor/AddVendor";
import { ViewVendors } from "./features/vendors/viewvendors/ViewVendors";
import { AddPOs } from "./features/purchaseorder/addPO/AddPOs";
import { ViewPOs } from "./features/purchaseorder/viewPO/ViewPOs";
import { Approvals } from "./features/expenses/Approvals/Approvals";
import Login from "./features/Authentication/Login/Login";
import "antd/dist/antd.css";
import "./App.css";
import { Menu, Space, Avatar, Button, Layout, PageHeader } from "antd";
import {
  ProfileOutlined,
  TeamOutlined,
  HomeOutlined,
  DollarOutlined,
  ReadOutlined,
  MailOutlined,
  MenuOutlined,
  BellOutlined,
} from "@ant-design/icons";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Link,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import axios from "axios";
import {
  SignOut,
  SetInitUrl,
} from "../src/features/Authentication/Login/LoginSlice";
import App from "./App";

const NextApp = (props) => {
  axios.defaults.headers.common["authorization"] = `bearer ${JSON.parse(
    localStorage.getItem("token")
  )}`;
  const dispatch = useDispatch();
  const { token, initURL } = useSelector((state) => state.Login);
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    if (initURL === "") {
      dispatch(SetInitUrl(location.pathname));
    }
  });
  useEffect(() => {
    if (location.pathname === "/") {
      if (token === null) {
        history.push("/login");
      } else if (initURL === "" || initURL === "/" || initURL === "/login") {
        history.push("/project/viewprojects");
      } else {
        history.push(initURL);
      }
    }
  }, [token, initURL, location, history]);

  return (
    <>
      <Switch>
        <Route exact path="/login" component={Login} />
        <RestrictedRoute
          path={`${match.url}`}
          token={token}
          location={location}
          component={App}
        />
      </Switch>
    </>
  );
};

const RestrictedRoute = ({
  component: Component,
  location,
  token,
  path,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          <Component path={path} {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
export default NextApp;
