import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { useSelector, useDispatch } from "react-redux";
import {
  getExpenseByIdAsync,
  rejectedExpenses,
} from "../Approvals/ApprovalSlice";
import {
  approveAdminExpenses,
  getExpenseForAdminApprovalAsync,
} from "../adminApprovals/AdminApprovalSlice";
import ExpenseView from "../Components/ExpenseView";
import RejectionModel from "../../commonComponents/RejectionModal";
import { CheckOutlined, CloseOutlined, EyeTwoTone } from "@ant-design/icons";
import { Table, Row, Col, Breadcrumb, Button, Card, Typography } from "antd";

const { Title } = Typography;
// const { Column } = Table;

export function AdminApprovals(props) {
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const [page, setPage] = React.useState(1);
  const [expenseId, setExpenseId] = useState(null);
  const [visible, setVisible] = useState(false);
  const expenseShortData = useSelector(
    (state) => state.approvals.ExpenseShortRecords
  );
  const expenseData = useSelector(
    (state) => state.adminApprovals?.expenseRecords
  );
  useEffect(() => {
    setloading(true);
    dispatch(getExpenseForAdminApprovalAsync());
    setloading(false);
  }, [dispatch]);

  console.log("shortName", expenseShortData);
  const onRejectionSubmit = (ids, reason) => {
    return dispatch(rejectedExpenses(ids, reason)).then((res) => {
      dispatch(getExpenseForAdminApprovalAsync());
      setExpenseId(null);
      setVisible(false);
    });
  };
  const columns = [
    {
      title: "S.No",
      dataIndex: "id",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Date",
      render: (expenseData) => {
        return (
          <>
            <p>
              {expenseData.createdDateTime === null ? (
                ""
              ) : (
                <>
                  <Moment utc local format="DD-MMM-YYYY">
                    {expenseData.createdDateTime}
                  </Moment>
                  &nbsp;
                  <Moment
                    utc
                    local
                    fromNow
                    ago
                    style={{ border: "2px solid pink", Color: "blue" }}
                  >
                    {expenseData.createdDateTime}
                  </Moment>
                </>
              )}
            </p>
          </>
        );
      },
      sorter: (a, b) => a.date - b.date,
    },
    {
      title: "Project",
      render: (approvedExpenseData) => {
        return (
          <>
            <p>{approvedExpenseData.project?.projectName}</p>
          </>
        );
      },
    },
    {
      title: "PO Number",
      render: (approvedExpenseData) => {
        return (
          <>
            <p>{approvedExpenseData.purchaseOrder?.poNumber}</p>
          </>
        );
      },
    },
    {
      title: "Vendor Name",
      render: (approvedExpenseData) => {
        return (
          <>
            <p>{approvedExpenseData.vendors?.vendorName}</p>
          </>
        );
      },
    },
    {
      title: "Approval",
      key: "approval",
      // sorter: (a, b) => a.approval - b.approval,
      render: (expenseData) => {
        const ApprovalExpenses = (id) => {
          dispatch(approveAdminExpenses(id)).then(() => {
            dispatch(getExpenseForAdminApprovalAsync());
          });
        };

        return (
          <>
            <Button
              disabled={expenseData.approvalStatusID === 0 ? false : true}
              onClick={() => ApprovalExpenses(expenseData.id)}
              size="small"
              style={{
                // backgroundColor: "#3CB371",
                color: "#3CB371",
                borderRadius: "5px",
              }}
            >
              <CheckOutlined />
            </Button>
            <Button
              disabled={expenseData.approvalStatusID === 0 ? false : true}
              onClick={() => {
                setExpenseId(expenseData.id);
                setVisible(true);
              }}
              size="small"
              style={{
                // backgroundColor: "#ff3333",
                color: "#ff3333",
                borderRadius: "5px",
              }}
            >
              <CloseOutlined />
            </Button>
          </>
        );
      },
    },
    // {
    //   title: "Status",
    //   key: "status",

    //   render: (expenseData) => {
    //     return (
    //       <>
    //         {expenseData.approvalStatusID === 1 ? (
    //           <p style={{ color: "green" }}>Approved</p>
    //         ) : expenseData.approvalStatusID === 2 ? (
    //           <p style={{ color: "red" }}>Rejected</p>
    //         ) : (
    //           "Pending"
    //         )}{" "}
    //       </>
    //     );
    //   },
    // },

    {
      title: "Amount",
      // dataIndex: "amount",
      render: (expenseData) => {
        return (
          <>
            <p>{expenseData.amount}</p>
          </>
        );
      },
      sorter: (a, b) => a.amount - b.amount,
    },

    {
      title: "GST",
      dataIndex: "gstno",
      // render: (expenseData) => {
      //   return (
      //     <>
      //       <p>{expenseData.gst}</p>
      //       <p> {expenseShortData.expenseReason}</p>
      //     </>
      //   );
      // },
      sorter: (a, b) => a.gstno - b.gstno,
    },
    {
      title: "Total Amount",
      render: (expenseData) => {
        return (
          <>
            <p>
              {expenseData.totalAmount == null
                ? expenseData.amount +
                (expenseData.amount * expenseData.gst) / 100
                : expenseData.totalAmount}
            </p>
          </>
        );
      },
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: "CreatedBy",
      render: (expenseData) => {
        return (
          <>
            <p>{expenseData.createdUser?.firstName}</p>
          </>
        );
      },
    },
    {
      title: "Created Date",
      render: (expenseData) => {
        return (
          <>
            {expenseData.createdDateTime == null ? (
              ""
            ) : (
              <Moment utc local format="DD-MMM-YYYY">
                {expenseData.createdDateTime}
              </Moment>
            )}
          </>
        );
      },
    },
    {
      title: "UpdatedBy",
      render: (expenseData) => {
        return (
          <>
            <p>{expenseData.updatedUser?.firstName}</p>
          </>
        );
      },
    },
    {
      title: "Updated Date",
      render: (expenseData) => {
        return (
          <>
            {expenseData.updatedDateTime == null ? (
              ""
            ) : (
              <Moment utc local format="DD-MMM-YYYY">
                {expenseData.updatedDateTime}
              </Moment>
            )}
          </>
        );
      },
    },
    // {
    //   title: "Actions",
    //   render: (expenseData) => {
    //     const GetExpenseRecord = (id) => {
    //       dispatch(getExpenseByIdAsync(id));
    //     };
    //     return (
    //       <Button size="small" onClick={() => GetExpenseRecord(expenseData.id)}>
    //         <EyeTwoTone />
    //       </Button>
    //     );
    //   },
    // },
  ];

  if (loading) {
    return <h1 style={{ alignSelf: "center" }}>Loading...</h1>;
  }

  return (
    <>
      <Row>
        <Title level={4}>Approvals</Title>
      </Row>

      <Row>
        <Col span={20}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="">Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="#">Expenses</Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item>Approvals</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <div>
        <RejectionModel
          visible={visible}
          id={expenseId}
          onSubmit={onRejectionSubmit}
          setVisible={setVisible}
        />
      </div>
      <div span={24} style={{ marginTop: 20, width: "100%" }}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={24}>
            <Table
              bordered
              columns={columns}
              dataSource={expenseData}
              size="small"
              loading={loading}
              pagination={{
                onChange(current) {
                  setPage(current);
                },
              }}
              expandable={{
                expandedRowRender: (record) => <ExpenseView id={record.id} />,
                rowExpandable: (records) => records.info !== "Not expandable",
                onExpand: (expanded, record) => {
                  dispatch(getExpenseByIdAsync(record.id));
                  console.log("onExpand: ", record, expanded);
                },
              }}
              rowKey={(record) => record.id}
            ></Table>
          </Col>
          {/* <Col span={6}>
            <ExpenseView />
          </Col> */}
        </Row>
      </div>
    </>
  );
}
