import React from "react";
import {
  Button,
  Table,
  Space,
} from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { isSuperAdmin, isAdmin } from "../../../Login/../src/features/Authentication/Login/LoginSlice";

const { Column } = Table;
const data = [
  {
    id: "1",
    vendorName: "Project1",
    poid: "PO098765432",
    totalamount: "123456",
  },
];
export function ViewTable(props) {

  const deleteProject = (e) => {
    props.delete(e);
  };

  return (
    <>
      <div class="mt-30">
        <Table dataSource={props.datasource} size="middle" bordered>
          <Column title="S.No" dataIndex="id" key="id"
            render= {(value, item, index) => (
              index + 1
            )}

          />
          <Column title="Project Name" dataIndex="projectName" key="projectName" />
          <Column
            title="Vendor Name"
            dataIndex="vendorName"
            key="vendorName"
          />

          <Column title="Start Date" dataIndex="startdate" key="startdate" />
          <Column
            title="Total Amount"
            dataIndex="totalAmount"
            key="totalAmount"
          />
          <Column
            title="Created By"
            dataIndex="createdBy"
            key="createdBy"
          />
          <Column
            title="created Date"
            dataIndex="createdDateTime"
            key="createdDateTime"
          />
          <Column
            title="Updated By"
            dataIndex="updatedBy"
            key="updatedBy"
          />
          <Column
            title="Updated Date"
            dataIndex="updatedDateTime"
            key="updatedDateTime"
          />
          <Column
            title="Actions"
            dataIndex="actions"
            key="actions"
            render={(text, record) => (
              <Space size="small">
                <Button
                  style={{ color: "green" }}
                  icon={<EyeOutlined />}
                  size="small"
                ></Button>
                <Button
                  style={{ color: "green" }}
                  icon={<EditOutlined />}
                  size="small"
                ></Button>
                {(isSuperAdmin() || isAdmin()) && (
                  <Button
                    danger
                    icon={<DeleteOutlined />}
                    size="small"
                    onClick={() => deleteProject(record)}
                  ></Button>
            )}
              </Space>
            )}
          />
        </Table>
      </div>
    </>

  );
}

export default ViewTable
